/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { SideGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import EquipmentEditor from '../Editor/EquipmentEditor.js';
import EquipmentGridColumns from './Columns/EquipmentGridColumns.js';
import EquipmentGridDefaultHiddenColumns from './Columns/EquipmentGridDefaultHiddenColumns.js';

export default function EquipmentSideGridEditor(props) {
	return <SideGridEditor
				reference="EquipmentSideGridEditor"
				model="Equipment"
				usePermissions={true}
				isCollapsible={false}
				Editor={EquipmentEditor}
				columnsConfig={EquipmentGridColumns}
				defaultHiddenColumns={EquipmentGridDefaultHiddenColumns}
				useRemoteDuplicate={true}
				
				
				{...props}
			/>;
}