/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import EqStatusesGridColumns from '../../Grid/Columns/EqStatusesGridColumns.js';

export default function EqStatusesGridPanel(props) {
	return <GridPanel
				reference="EqStatusesGridPanel"
				model="EqStatuses"
				usePermissions={true}
				columnsConfig={EqStatusesGridColumns}

				{...props}
			/>;
}