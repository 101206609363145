/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import ChecklistsGroupsSideGridEditorPanel from './ChecklistsGroupsSideGridEditor.js';


export default function ChecklistsGroupsFilteredSideGridEditorPanel(props) {
	return <ChecklistsGroupsSideGridEditorPanel
				reference="ChecklistsGroupsFilteredSideGridEditorPanel"
				useFilters={true}
				
				{...props}
			/>;
}