/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */
import Editor from '@onehat/ui/src/Components/Editor/Editor.js';
import _ from 'lodash';

export default function PermissionsEditor(props) {

	const
		items = [
		    {
		        "type": "Column",
		        "flex": 1,
		        "defaults": {},
		        "items": [
		            {
		                "type": "FieldSet",
		                "title": "Attachments",
		                "reference": "attachments",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_attachments"
		                    },
		                    {
		                        "name": "permissions__add_attachments"
		                    },
		                    {
		                        "name": "permissions__edit_attachments"
		                    },
		                    {
		                        "name": "permissions__delete_attachments"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "Checklists",
		                "reference": "checklists",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_checklists"
		                    },
		                    {
		                        "name": "permissions__add_checklists"
		                    },
		                    {
		                        "name": "permissions__edit_checklists"
		                    },
		                    {
		                        "name": "permissions__delete_checklists"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "Checklists to Equipment",
		                "reference": "checklists_equipment",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_checklists_equipment"
		                    },
		                    {
		                        "name": "permissions__add_checklists_equipment"
		                    },
		                    {
		                        "name": "permissions__edit_checklists_equipment"
		                    },
		                    {
		                        "name": "permissions__delete_checklists_equipment"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "Checklists to Groups",
		                "reference": "checklists_groups",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_checklists_groups"
		                    },
		                    {
		                        "name": "permissions__add_checklists_groups"
		                    },
		                    {
		                        "name": "permissions__edit_checklists_groups"
		                    },
		                    {
		                        "name": "permissions__delete_checklists_groups"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "Checklist Types",
		                "reference": "checklist_types",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_checklist_types"
		                    },
		                    {
		                        "name": "permissions__add_checklist_types"
		                    },
		                    {
		                        "name": "permissions__edit_checklist_types"
		                    },
		                    {
		                        "name": "permissions__delete_checklist_types"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "Customers",
		                "reference": "customers",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_customers"
		                    },
		                    {
		                        "name": "permissions__add_customers"
		                    },
		                    {
		                        "name": "permissions__edit_customers"
		                    },
		                    {
		                        "name": "permissions__delete_customers"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "Custom Permissions",
		                "reference": "custom_permissions",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_reports"
		                    },
		                    {
		                        "name": "permissions__view_dashboard"
		                    },
		                    {
		                        "name": "permissions__email_inspections"
		                    },
		                    {
		                        "name": "permissions__do_inspections"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "Eq Engine Models",
		                "reference": "eq_engine_models",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_eq_engine_models"
		                    },
		                    {
		                        "name": "permissions__add_eq_engine_models"
		                    },
		                    {
		                        "name": "permissions__edit_eq_engine_models"
		                    },
		                    {
		                        "name": "permissions__delete_eq_engine_models"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "Eq Manufacturers",
		                "reference": "eq_manufacturers",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_eq_manufacturers"
		                    },
		                    {
		                        "name": "permissions__add_eq_manufacturers"
		                    },
		                    {
		                        "name": "permissions__edit_eq_manufacturers"
		                    },
		                    {
		                        "name": "permissions__delete_eq_manufacturers"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "Eq Statuses",
		                "reference": "eq_statuses",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_eq_statuses"
		                    },
		                    {
		                        "name": "permissions__add_eq_statuses"
		                    },
		                    {
		                        "name": "permissions__edit_eq_statuses"
		                    },
		                    {
		                        "name": "permissions__delete_eq_statuses"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "Eq Types",
		                "reference": "eq_types",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_eq_types"
		                    },
		                    {
		                        "name": "permissions__add_eq_types"
		                    },
		                    {
		                        "name": "permissions__edit_eq_types"
		                    },
		                    {
		                        "name": "permissions__delete_eq_types"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "Equipment",
		                "reference": "equipment",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_equipment"
		                    },
		                    {
		                        "name": "permissions__add_equipment"
		                    },
		                    {
		                        "name": "permissions__edit_equipment"
		                    },
		                    {
		                        "name": "permissions__delete_equipment"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "Equipment to Parts",
		                "reference": "equipment_parts",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_equipment_parts"
		                    },
		                    {
		                        "name": "permissions__add_equipment_parts"
		                    },
		                    {
		                        "name": "permissions__edit_equipment_parts"
		                    },
		                    {
		                        "name": "permissions__delete_equipment_parts"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "Equipment to PM Schedules",
		                "reference": "equipment_p_m_schedules",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_equipment_pm_schedules"
		                    },
		                    {
		                        "name": "permissions__add_equipment_pm_schedules"
		                    },
		                    {
		                        "name": "permissions__edit_equipment_pm_schedules"
		                    },
		                    {
		                        "name": "permissions__delete_equipment_pm_schedules"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "Equipment to Work Orders",
		                "reference": "equipment_work_orders",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_equipment_work_orders"
		                    },
		                    {
		                        "name": "permissions__add_equipment_work_orders"
		                    },
		                    {
		                        "name": "permissions__edit_equipment_work_orders"
		                    },
		                    {
		                        "name": "permissions__delete_equipment_work_orders"
		                    }
		                ]
		            }
		        ]
		    },
		    {
		        "type": "Column",
		        "flex": 1,
		        "defaults": {},
		        "items": [
		            {
		                "type": "FieldSet",
		                "title": "Groups",
		                "reference": "groups",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_groups"
		                    },
		                    {
		                        "name": "permissions__add_groups"
		                    },
		                    {
		                        "name": "permissions__edit_groups"
		                    },
		                    {
		                        "name": "permissions__delete_groups"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "Groups to Users Links",
		                "reference": "groups_users_links",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_groups_users"
		                    },
		                    {
		                        "name": "permissions__add_groups_users"
		                    },
		                    {
		                        "name": "permissions__edit_groups_users"
		                    },
		                    {
		                        "name": "permissions__delete_groups_users"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "Inspections",
		                "reference": "inspections",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_inspections"
		                    },
		                    {
		                        "name": "permissions__add_inspections"
		                    },
		                    {
		                        "name": "permissions__edit_inspections"
		                    },
		                    {
		                        "name": "permissions__delete_inspections"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "Labors",
		                "reference": "labors",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_labors"
		                    },
		                    {
		                        "name": "permissions__add_labors"
		                    },
		                    {
		                        "name": "permissions__edit_labors"
		                    },
		                    {
		                        "name": "permissions__delete_labors"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "Labor Types",
		                "reference": "labor_types",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_labor_types"
		                    },
		                    {
		                        "name": "permissions__add_labor_types"
		                    },
		                    {
		                        "name": "permissions__edit_labor_types"
		                    },
		                    {
		                        "name": "permissions__delete_labor_types"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "Locations",
		                "reference": "locations",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_locations"
		                    },
		                    {
		                        "name": "permissions__add_locations"
		                    },
		                    {
		                        "name": "permissions__edit_locations"
		                    },
		                    {
		                        "name": "permissions__delete_locations"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "Meter Readings",
		                "reference": "meter_readings",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_meter_readings"
		                    },
		                    {
		                        "name": "permissions__add_meter_readings"
		                    },
		                    {
		                        "name": "permissions__edit_meter_readings"
		                    },
		                    {
		                        "name": "permissions__delete_meter_readings"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "Meter Sources",
		                "reference": "meter_sources",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_meter_sources"
		                    },
		                    {
		                        "name": "permissions__add_meter_sources"
		                    },
		                    {
		                        "name": "permissions__edit_meter_sources"
		                    },
		                    {
		                        "name": "permissions__delete_meter_sources"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "OtherCharges",
		                "reference": "other_charges",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_other_charges"
		                    },
		                    {
		                        "name": "permissions__add_other_charges"
		                    },
		                    {
		                        "name": "permissions__edit_other_charges"
		                    },
		                    {
		                        "name": "permissions__delete_other_charges"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "Part Manufacturers",
		                "reference": "part_manufacturers",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_part_manufacturers"
		                    },
		                    {
		                        "name": "permissions__add_part_manufacturers"
		                    },
		                    {
		                        "name": "permissions__edit_part_manufacturers"
		                    },
		                    {
		                        "name": "permissions__delete_part_manufacturers"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "Parts",
		                "reference": "parts",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_parts"
		                    },
		                    {
		                        "name": "permissions__add_parts"
		                    },
		                    {
		                        "name": "permissions__edit_parts"
		                    },
		                    {
		                        "name": "permissions__delete_parts"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "Part Sources",
		                "reference": "part_sources",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_part_sources"
		                    },
		                    {
		                        "name": "permissions__add_part_sources"
		                    },
		                    {
		                        "name": "permissions__edit_part_sources"
		                    },
		                    {
		                        "name": "permissions__delete_part_sources"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "Part Types",
		                "reference": "part_types",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_part_types"
		                    },
		                    {
		                        "name": "permissions__add_part_types"
		                    },
		                    {
		                        "name": "permissions__edit_part_types"
		                    },
		                    {
		                        "name": "permissions__delete_part_types"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "Parts to Interchangeables",
		                "reference": "parts_interchangeables",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_parts_parts"
		                    },
		                    {
		                        "name": "permissions__add_parts_parts"
		                    },
		                    {
		                        "name": "permissions__edit_parts_parts"
		                    },
		                    {
		                        "name": "permissions__delete_parts_parts"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "Parts to Part Types",
		                "reference": "parts_part_types",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_parts_part_types"
		                    },
		                    {
		                        "name": "permissions__delete_parts_part_types"
		                    },
		                    {
		                        "name": "permissions__edit_parts_part_types"
		                    },
		                    {
		                        "name": "permissions__add_parts_part_types"
		                    }
		                ]
		            }
		        ]
		    },
		    {
		        "type": "Column",
		        "flex": 1,
		        "defaults": {},
		        "items": [
		            {
		                "type": "FieldSet",
		                "title": "Parts to Work Orders",
		                "reference": "parts_work_orders",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_parts_work_orders"
		                    },
		                    {
		                        "name": "permissions__add_parts_work_orders"
		                    },
		                    {
		                        "name": "permissions__edit_parts_work_orders"
		                    },
		                    {
		                        "name": "permissions__delete_parts_work_orders"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "Permissions",
		                "reference": "permissions",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_permissions"
		                    },
		                    {
		                        "name": "permissions__add_permissions"
		                    },
		                    {
		                        "name": "permissions__edit_permissions"
		                    },
		                    {
		                        "name": "permissions__delete_permissions"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "PM Events",
		                "reference": "p_m_events",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_pm_events"
		                    },
		                    {
		                        "name": "permissions__add_pm_events"
		                    },
		                    {
		                        "name": "permissions__edit_pm_events"
		                    },
		                    {
		                        "name": "permissions__delete_pm_events"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "PM Event Types",
		                "reference": "p_m_event_types",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_pm_event_types"
		                    },
		                    {
		                        "name": "permissions__add_pm_event_types"
		                    },
		                    {
		                        "name": "permissions__edit_pm_event_types"
		                    },
		                    {
		                        "name": "permissions__delete_pm_event_types"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "PM Schedule Modes",
		                "reference": "p_m_schedule_modes",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_pm_schedule_modes"
		                    },
		                    {
		                        "name": "permissions__add_pm_schedule_modes"
		                    },
		                    {
		                        "name": "permissions__edit_pm_schedule_modes"
		                    },
		                    {
		                        "name": "permissions__delete_pm_schedule_modes"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "PM Schedules",
		                "reference": "p_m_schedules",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_pm_schedules"
		                    },
		                    {
		                        "name": "permissions__add_pm_schedules"
		                    },
		                    {
		                        "name": "permissions__edit_pm_schedules"
		                    },
		                    {
		                        "name": "permissions__delete_pm_schedules"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "PM Statuses",
		                "reference": "p_m_statuses",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_pm_statuses"
		                    },
		                    {
		                        "name": "permissions__add_pm_statuses"
		                    },
		                    {
		                        "name": "permissions__edit_pm_statuses"
		                    },
		                    {
		                        "name": "permissions__delete_pm_statuses"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "PM Technicians",
		                "reference": "p_m_technicians",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_pm_technicians"
		                    },
		                    {
		                        "name": "permissions__add_pm_technicians"
		                    },
		                    {
		                        "name": "permissions__edit_pm_technicians"
		                    },
		                    {
		                        "name": "permissions__delete_pm_technicians"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "Questions",
		                "reference": "questions",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_questions"
		                    },
		                    {
		                        "name": "permissions__add_questions"
		                    },
		                    {
		                        "name": "permissions__edit_questions"
		                    },
		                    {
		                        "name": "permissions__delete_questions"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "Question Groups",
		                "reference": "question_groups",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_question_groups"
		                    },
		                    {
		                        "name": "permissions__add_question_groups"
		                    },
		                    {
		                        "name": "permissions__edit_question_groups"
		                    },
		                    {
		                        "name": "permissions__delete_question_groups"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "Responses",
		                "reference": "responses",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_responses"
		                    },
		                    {
		                        "name": "permissions__add_responses"
		                    },
		                    {
		                        "name": "permissions__edit_responses"
		                    },
		                    {
		                        "name": "permissions__delete_responses"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "WO Classes",
		                "reference": "w_o_classes",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_wo_classes"
		                    },
		                    {
		                        "name": "permissions__add_wo_classes"
		                    },
		                    {
		                        "name": "permissions__edit_wo_classes"
		                    },
		                    {
		                        "name": "permissions__delete_wo_classes"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "WO Priorities",
		                "reference": "w_o_priorities",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_wo_priorities"
		                    },
		                    {
		                        "name": "permissions__add_wo_priorities"
		                    },
		                    {
		                        "name": "permissions__edit_wo_priorities"
		                    },
		                    {
		                        "name": "permissions__delete_wo_priorities"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "WorkOrders",
		                "reference": "work_orders",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_work_orders"
		                    },
		                    {
		                        "name": "permissions__add_work_orders"
		                    },
		                    {
		                        "name": "permissions__edit_work_orders"
		                    },
		                    {
		                        "name": "permissions__delete_work_orders"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "Users",
		                "reference": "users",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_users"
		                    },
		                    {
		                        "name": "permissions__add_users"
		                    },
		                    {
		                        "name": "permissions__edit_users"
		                    },
		                    {
		                        "name": "permissions__delete_users"
		                    }
		                ]
		            }
		        ]
		    }
		],
		ancillaryItems = [],
		columnDefaults = { // defaults for each column defined in 'items', for use in Form amd Viewer
			labelWidth: '200px', // MOD
		};
	return <Editor
				reference="PermissionsEditor"
				title="Permissions"
				items={items}
				ancillaryItems={ancillaryItems}
				columnDefaults={columnDefaults}
				{...props}
			/>;
}

