/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import ResponsesGridColumns from '../../Grid/Columns/ResponsesGridColumns.js';

export default function ResponsesGridPanel(props) {
	return <GridPanel
				reference="ResponsesGridPanel"
				model="Responses"
				usePermissions={true}
				columnsConfig={ResponsesGridColumns}

				{...props}
			/>;
}