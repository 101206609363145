export default [
	    // {
	    //     "id": "questions__display_value",
	    //     "header": "Display Value",
	    //     "fieldName": "questions__display_value",
	    //     "isSortable": false,
	    //     "isEditable": false,
	    //     "isReorderable": true,
	    //     "isResizable": true,
	    //     "w": 150
	    // },
	    {
	        "id": "questions__sort_order",
	        "header": "Sort",
	        "fieldName": "questions__sort_order",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "questions__name",
	        "header": "Question",
	        "fieldName": "questions__name",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "questions__description",
	        "header": "Description",
	        "fieldName": "questions__description",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200
	    },
	    {
	        "id": "questions__response_options",
	        "header": "Response Options",
	        "fieldName": "questions__response_options",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200
	    },
	    {
	        "id": "questions__fail_options",
	        "header": "Fail Options",
	        "fieldName": "questions__fail_options",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200
	    },
	    {
	        "id": "questions__is_value_required",
	        "header": "Required",
	        "fieldName": "questions__is_value_required",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "questions__is_comment_required",
	        "header": "Comment Required",
	        "fieldName": "questions__is_comment_required",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "questions__checklist_id",
	        "header": "Checklist",
	        "fieldName": "checklists__name",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100,
	        "editField": "questions__checklist_id"
	    },
	    {
	        "id": "questions__question_group_id",
	        "header": "Group",
	        "fieldName": "question_groups__name",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100,
	        "editField": "questions__question_group_id"
	    },
	    {
	        "id": "questions__calculated_response_options",
	        "header": "Calculated Response Options",
	        "fieldName": "questions__calculated_response_options",
	        "isSortable": false,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "questions__calculated_fail_options",
	        "header": "Calculated Fail Options",
	        "fieldName": "questions__calculated_fail_options",
	        "isSortable": false,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "questions__is_boolean",
	        "header": "Is Boolean",
	        "fieldName": "questions__is_boolean",
	        "isSortable": false,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "questions__boolean_succeed_value",
	        "header": "Boolean Succeed Value",
	        "fieldName": "questions__boolean_succeed_value",
	        "isSortable": false,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "questions__boolean_fail_value",
	        "header": "Boolean Fail Value",
	        "fieldName": "questions__boolean_fail_value",
	        "isSortable": false,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    }
	];