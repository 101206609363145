import * as yup from 'yup'; // https://github.com/jquense/yup#string

const Checklists = {
	
	name: 'Checklists',
	
	model: {

		idProperty: 'checklists__id',
		displayProperty: 'checklists__name',
		sortProperty: 'checklists__name',
		
		sorters: null,

		validator: yup.object({
			checklists__name: yup.string().required(),
			checklists__checklist_type_id: yup.number().integer().nullable(),
			checklists__response_options: yup.string().nullable(),
			checklists__fail_options: yup.string().nullable(),
			checklists__groups: yup.string().nullable(),
			checklists__can_save_for_later: yup.boolean().required(),
			checklists__use_multiple_screens: yup.boolean().required(),
			checklists__email_to: yup.string().nullable()
		}),
		
		properties: [
			{ name: 'checklists__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: '1. Checklist Info', },
			{ name: 'checklists__name', mapping: 'name', title: 'Name', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: '1. Checklist Info', },
			{ name: 'checklists__checklist_type_id', mapping: 'checklist_type_id', title: 'Checklist Type', type: 'int', isFk: true, fkIdField: 'checklist_types__id', fkDisplayField: 'checklist_types__name', filterType: {"type":"ChecklistTypesCombo","loadAfterRender":!1}, editorType: {"type":"ChecklistTypesCombo","loadAfterRender":!1}, fieldGroup: '1. Checklist Info', defaultValue: 1, },
			{ name: 'checklists__response_options', mapping: 'response_options', title: 'Response Options', isFilteringDisabled: true, editorType: {"type":"TextArea"}, fieldGroup: '1. Checklist Info', },
			{ name: 'checklists__fail_options', mapping: 'fail_options', title: 'Fail Options', isFilteringDisabled: true, editorType: {"type":"TextArea"}, fieldGroup: '1. Checklist Info', },
			{ name: 'checklists__groups', mapping: 'groups', title: 'Groups', filterType: {"type":"GroupsTag"}, viewerType: {"type":"GroupsTag"}, editorType: {"type":"GroupsTagEditor","loadAfterRender":!1}, fieldGroup: '2. Settings', },
			{ name: 'checklists__can_save_for_later', mapping: 'can_save_for_later', title: 'Can Save For Later', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '2. Settings', },
			{ name: 'checklists__use_multiple_screens', mapping: 'use_multiple_screens', title: 'Use Multiple Screens', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '2. Settings', },
			{ name: 'checklists__email_to', mapping: 'email_to', title: 'Email To', isFilteringDisabled: true, editorType: {"type":"TextArea"}, fieldGroup: '2. Settings', },
			{ name: 'checklist_types__id', mapping: 'checklist_type.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'checklist_types__name', mapping: 'checklist_type.name', title: 'Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, }
		],

		associations: {
			hasMany: [
				'Inspections',
				'Questions',
				'ChecklistsEquipment',
				'QuestionGroups',
				'ChecklistsGroups'
			],
			belongsTo: [
				'ChecklistTypes'
			],
			belongsToMany: [
				'Equipment',
				'Groups'
			],

		},

		ancillaryFilters: {
		    "equipment": "Equipment"
		},

		defaultFilters: [
			// 'checklists__checklist_type_id',
			// 'checklists__groups',
			// 'checklists__can_save_for_later',
			// 'checklists__use_multiple_screens'
		],

	},

	entity: {
		methods: {

			getInspections: function() {
				const Inspections = this.getAssociatedRepository('Inspections');
				return Inspections.getBy((entity) => {
					return entity.inspections__checklist_id === this.checklists__id;
				});
			},

			getQuestions: function() {
				const Questions = this.getAssociatedRepository('Questions');
				return Questions.getBy((entity) => {
					return entity.questions__checklist_id === this.checklists__id;
				});
			},

			getChecklistsEquipment: function() {
				const ChecklistsEquipment = this.getAssociatedRepository('ChecklistsEquipment');
				return ChecklistsEquipment.getBy((entity) => {
					return entity.checklists_equipment__checklist_id === this.checklists__id;
				});
			},

			getQuestionGroups: function() {
				const QuestionGroups = this.getAssociatedRepository('QuestionGroups');
				return QuestionGroups.getBy((entity) => {
					return entity.question_groups__checklist_id === this.checklists__id;
				});
			},

			getChecklistsGroups: function() {
				const ChecklistsGroups = this.getAssociatedRepository('ChecklistsGroups');
				return ChecklistsGroups.getBy((entity) => {
					return entity.checklists_groups__checklist_id === this.checklists__id;
				});
			},

			getChecklistType: async function() {
				const ChecklistTypes = this.getAssociatedRepository('ChecklistTypes');
				let entity = ChecklistTypes.getById(this.checklists__checklist_type_id);
				if (!entity) {
					entity = await ChecklistTypes.getSingleEntityFromServer(this.checklists__checklist_type_id);
				}
				return entity;
			},

		},
	},


	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": true,
	    "isAutoLoad": false
	},

};

export default Checklists;
