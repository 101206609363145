/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import {
	SELECTION_MODE_SINGLE,
} from '@onehat/ui/src/Constants/Selection.js';
import { WindowedGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import ChecklistTypesEditorWindow from '../Window/ChecklistTypesEditorWindow.js';
import ChecklistTypesGridColumns from './Columns/ChecklistTypesGridColumns.js';

export default function ChecklistTypesGridEditor(props) {
	return <WindowedGridEditor
				reference="ChecklistTypesGridEditor"
				model="ChecklistTypes"
				usePermissions={true}
				selectionMode={SELECTION_MODE_SINGLE}
				Editor={ChecklistTypesEditorWindow}
				columnsConfig={ChecklistTypesGridColumns}
				
				disableAdd={true}
				disableEdit={true}
				disableDelete={true}
				{...props}
			/>;
}