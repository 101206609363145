/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import ChecklistsGroupsGridPanel from './ChecklistsGroupsGrid.js';

export default function ChecklistsGroupsFilteredGridPanel(props) {
	return <ChecklistsGroupsGridPanel
				reference="ChecklistsGroupsFilteredGridPanel"
				useFilters={true}

				{...props}
			/>;
}