/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import {
	EQ_STATUSES__ACTIVE,
	EQ_STATUSES__INACTIVE,
	EQ_STATUSES__SOLD,
} from '../../../../constants/EqStatuses.js';
import { ComboEditor } from '@onehat/ui/src/Components/Form/Field/Combo/Combo.js';
import EquipmentEditorWindow from '../../../Window/EquipmentEditorWindow.js';

function EquipmentComboEditor(props) {
	return <ComboEditor
				reference="EquipmentComboEditor"
				model="Equipment"
				uniqueRepository={true}
				Editor={EquipmentEditorWindow}
				usePermissions={true}
				baseParams={{
					'conditions[eq_status_id IN]': [
						EQ_STATUSES__ACTIVE,
						EQ_STATUSES__INACTIVE,
					],
				}}
				{...props}
			/>;
}

export default EquipmentComboEditor;