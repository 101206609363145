export default [
	    {
	        "id": "checklists_groups__checklist_id",
	        "header": "Checklist",
	        "fieldName": "checklists__name",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100,
	        "editField": "checklists_groups__checklist_id"
	    },
	    {
	        "id": "checklists_groups__group_id",
	        "header": "Group",
	        "fieldName": "groups__name",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100,
	        "editField": "checklists_groups__group_id"
	    }
	];