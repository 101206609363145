import * as yup from 'yup'; // https://github.com/jquense/yup#string
import {
	DATETIME_MOMENT_FORMAT,
} from '../../constants/Dates.js';
import moment from 'moment';

const Inspections = {
	
	name: 'Inspections',
	model: {

		idProperty: 'inspections__id',
		displayProperty: 'inspections__reference',
		sortProperty: 'inspections__date',
		sortDir: 'DESC',
		
		sorters: null,

		validator: yup.object({
			inspections__date: yup.date().required(),
			inspections__checklist_id: yup.number().integer().required(),
			inspections__equipment_id: yup.number().integer().required(),
			inspections__user_id: yup.number().integer().required()
		}),
		
		properties: [
			{ name: 'inspections__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'inspections__date', mapping: 'date', title: 'Date', type: 'datetime', filterType: {"type":"DateRange","mode":"DATETIME"}, editorType: {"type":"Date","mode":"DATETIME"}, fieldGroup: 'General', defaultValue: () => moment().format(DATETIME_MOMENT_FORMAT), },
			{ name: 'inspections__checklist_id', mapping: 'checklist_id', title: 'Checklist', type: 'int', isFk: true, fkIdField: 'checklists__id', fkDisplayField: 'checklists__name', filterType: {"type":"ChecklistsCombo","loadAfterRender":!1}, editorType: {"type":"ChecklistsCombo","loadAfterRender":!1}, fieldGroup: 'General', },
			{ name: 'inspections__is_failure', mapping: 'is_failure', title: 'Failure?', type: 'bool', filterType: {"type":"Toggle"}, isEditingDisabled: true, fieldGroup: 'General', },
			{ name: 'inspections__comments', mapping: 'comments', title: 'Comments', isFilteringDisabled: true, viewerType: {"type":"TextWithLinks","totalLines":6}, isEditingDisabled: true, fieldGroup: 'General', },
			{ name: 'inspections__equipment_id', mapping: 'equipment_id', title: 'Equipment', type: 'int', isFk: true, fkIdField: 'equipment__id', fkDisplayField: 'equipment__nickname', filterType: {"type":"EquipmentCombo","loadAfterRender":!1}, editorType: {"type":"EquipmentComboEditor","loadAfterRender":!1}, fieldGroup: 'General', },
			{ name: 'inspections__user_id', mapping: 'user_id', title: 'User', type: 'int', isFk: true, fkIdField: 'users__id', fkDisplayField: 'users__username', filterType: {"type":"UsersCombo","loadAfterRender":!1}, editorType: {"type":"UsersCombo","loadAfterRender":!1}, fieldGroup: 'General', },
			{ name: 'inspections__reference', mapping: 'reference', title: 'Reference', isFilteringDisabled: true, isEditingDisabled: true, fieldGroup: 'General', },
			{ name: 'inspections__has_attachments', mapping: 'has_attachments', title: 'Has Attachments', type: 'bool', isVirtual: true, isFilteringDisabled: true, isEditingDisabled: true, fieldGroup: 'General', },
			{ name: 'checklists__id', mapping: 'checklist.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'checklists__name', mapping: 'checklist.name', title: 'Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'checklists__checklist_type_id', mapping: 'checklist.checklist_type_id', title: 'Checklist Type', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'checklists__response_options', mapping: 'checklist.response_options', title: 'Response Options', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'checklists__fail_options', mapping: 'checklist.fail_options', title: 'Fail Options', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'checklists__groups', mapping: 'checklist.groups', title: 'Groups', type: 'tag', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'checklists__can_save_for_later', mapping: 'checklist.can_save_for_later', title: 'Can Save For Later', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'checklists__use_multiple_screens', mapping: 'checklist.use_multiple_screens', title: 'Use Multiple Screens', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'checklists__email_to', mapping: 'checklist.email_to', title: 'Email To', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__id', mapping: 'equipment.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__eq_status_id', mapping: 'equipment.eq_status_id', title: 'Status', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__nickname', mapping: 'equipment.nickname', title: 'Nickname', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__eq_manufacturer_id', mapping: 'equipment.eq_manufacturer_id', title: 'Make', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__model', mapping: 'equipment.model', title: 'Model', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__serial', mapping: 'equipment.serial', title: 'Serial', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__eq_type_id', mapping: 'equipment.eq_type_id', title: 'Eq Type', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__year', mapping: 'equipment.year', title: 'Year', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__eq_engine_model_id', mapping: 'equipment.eq_engine_model_id', title: 'Engine Model', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__engine_serial', mapping: 'equipment.engine_serial', title: 'Engine Serial', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__comments', mapping: 'equipment.comments', title: 'Additional Info', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__is_pm_enable', mapping: 'equipment.is_pm_enable', title: 'PM Enable?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__pm_schedules', mapping: 'equipment.pm_schedules', title: 'PM Schedules', type: 'tag', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__expected_hrs_per_mo', mapping: 'equipment.expected_hrs_per_mo', title: 'Expected Meter / Mo', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__in_service_date', mapping: 'equipment.in_service_date', title: 'In Service Date', type: 'date', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__in_service_meter', mapping: 'equipment.in_service_meter', title: 'In Service Meter', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__inactive_date', mapping: 'equipment.inactive_date', title: 'Inactive Date (calc)', type: 'date', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__available_hrs_per_wk', mapping: 'equipment.available_hrs_per_wk', title: 'Available Meter / Wk', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__parts', mapping: 'equipment.parts', title: 'Parts', type: 'tag', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__is_stale_stats', mapping: 'equipment.is_stale_stats', title: 'Stale Stats?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__current_meter', mapping: 'equipment.current_meter', title: 'Current Meter (calc)', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__next_pm_due_data', mapping: 'equipment.next_pm_due_data', title: 'Next PM Due Dates', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__latest_inspection_date', mapping: 'equipment.latest_inspection_date', title: 'Latest Inspection Date', type: 'date', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__latest_inspection_passed', mapping: 'equipment.latest_inspection_passed', title: 'Latest Inspection Passed', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__modified_at', mapping: 'equipment.modified_at', title: 'Last Modified', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__id', mapping: 'user.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__username', mapping: 'user.username', title: 'Username', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__password', mapping: 'user.password', title: 'Password', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__full_name', mapping: 'user.full_name', title: 'Full Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__first_name', mapping: 'user.first_name', title: 'First Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__last_name', mapping: 'user.last_name', title: 'Last Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__email', mapping: 'user.email', title: 'Email', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__job_title', mapping: 'user.job_title', title: 'Job Title', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__groups', mapping: 'user.groups', title: 'Groups', type: 'tag', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__token', mapping: 'user.token', title: 'Token', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__token_generated', mapping: 'user.token_generated', title: 'Token Generated', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__expo_token', mapping: 'user.expo_token', title: 'Expo Token', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__login_count', mapping: 'user.login_count', title: 'Login Count', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__last_login', mapping: 'user.last_login', title: 'Last Login', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__password_reset', mapping: 'user.password_reset', title: 'Password Reset', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, }
		],

		associations: {
			hasMany: [
				'Responses',
				'WorkOrders',
				'Attachments'
			],
			belongsTo: [
				'Checklists',
				'Equipment',
				'Users'
			],

		},

		ancillaryFilters: [],

		defaultFilters: [
			// 'inspections__date',
			// 'inspections__checklist_id',
			// 'inspections__is_failure',
			// 'inspections__equipment_id',
			// 'inspections__user_id'
		],

	},

	entity: {
		methods: {

			getChecklist: async function() {
				const Checklists = this.getAssociatedRepository('Checklists');
				let entity = Checklists.getById(this.inspections__checklist_id);
				if (!entity) {
					entity = await Checklists.getSingleEntityFromServer(this.inspections__checklist_id);
				}
				return entity;
			},

			getEquipment: async function() {
				const Equipment = this.getAssociatedRepository('Equipment');
				let entity = Equipment.getById(this.inspections__equipment_id);
				if (!entity) {
					entity = await Equipment.getSingleEntityFromServer(this.inspections__equipment_id);
				}
				return entity;
			},

			getUser: async function() {
				const Users = this.getAssociatedRepository('Users');
				let entity = Users.getById(this.inspections__user_id);
				if (!entity) {
					entity = await Users.getSingleEntityFromServer(this.inspections__user_id);
				}
				return entity;
			},

			getResponses: function() {
				const Responses = this.getAssociatedRepository('Responses');
				return Responses.getBy((entity) => {
					return entity.responses__inspection_id === this.inspections__id;
				});
			},

			getWorkOrders: function() {
				const WorkOrders = this.getAssociatedRepository('WorkOrders');
				return WorkOrders.getBy((entity) => {
					return entity.work_orders__inspection_id === this.inspections__id;
				});
			},

			getAttachments: function() {
				const Attachments = this.getAssociatedRepository('Attachments');
				return Attachments.getBy((entity) => {
					return entity.attachments__modelid === this.inspections__id;
				});
			},

		},
	},


	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": true,
	    "isAutoLoad": false
	},

};

export default Inspections;
