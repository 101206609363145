import * as yup from 'yup'; // https://github.com/jquense/yup#string

const ChecklistsEquipment = {
	
	name: 'ChecklistsEquipment',
	
	model: {

		idProperty: 'checklists_equipment__id',
		displayProperty: 'checklists_equipment__id',
		sortProperty: 'checklists_equipment__id',
		
		sorters: null,

		validator: yup.object({
			checklists_equipment__checklist_id: yup.number().integer().required(),
			checklists_equipment__equipment_id: yup.number().integer().required()
		}),
		
		properties: [
			{ name: 'checklists_equipment__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'checklists_equipment__checklist_id', mapping: 'checklist_id', title: 'Checklist', type: 'int', isFk: true, fkIdField: 'checklists__id', fkDisplayField: 'checklists__name', filterType: {"type":"ChecklistsCombo","loadAfterRender":!1}, editorType: {"type":"ChecklistsCombo","loadAfterRender":!1}, fieldGroup: 'General', },
			{ name: 'checklists_equipment__equipment_id', mapping: 'equipment_id', title: 'Equipment', type: 'int', isFk: true, fkIdField: 'equipment__id', fkDisplayField: 'equipment__nickname', filterType: {"type":"EquipmentCombo","loadAfterRender":!1}, editorType: {"type":"EquipmentComboEditor","loadAfterRender":!1}, fieldGroup: 'General', },
			{ name: 'checklists__id', mapping: 'checklist.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'checklists__name', mapping: 'checklist.name', title: 'Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'checklists__checklist_type_id', mapping: 'checklist.checklist_type_id', title: 'Checklist Type', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'checklists__response_options', mapping: 'checklist.response_options', title: 'Response Options', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'checklists__fail_options', mapping: 'checklist.fail_options', title: 'Fail Options', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'checklists__groups', mapping: 'checklist.groups', title: 'Groups', type: 'tag', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'checklists__can_save_for_later', mapping: 'checklist.can_save_for_later', title: 'Can Save For Later', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'checklists__use_multiple_screens', mapping: 'checklist.use_multiple_screens', title: 'Use Multiple Screens', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'checklists__email_to', mapping: 'checklist.email_to', title: 'Email To', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__id', mapping: 'equipment.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__eq_status_id', mapping: 'equipment.eq_status_id', title: 'Status', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__nickname', mapping: 'equipment.nickname', title: 'Nickname', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__eq_manufacturer_id', mapping: 'equipment.eq_manufacturer_id', title: 'Make', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__model', mapping: 'equipment.model', title: 'Model', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__serial', mapping: 'equipment.serial', title: 'Serial', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__eq_type_id', mapping: 'equipment.eq_type_id', title: 'Eq Type', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__year', mapping: 'equipment.year', title: 'Year', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__eq_engine_model_id', mapping: 'equipment.eq_engine_model_id', title: 'Engine Model', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__engine_serial', mapping: 'equipment.engine_serial', title: 'Engine Serial', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__comments', mapping: 'equipment.comments', title: 'Additional Info', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__is_pm_enable', mapping: 'equipment.is_pm_enable', title: 'PM Enable?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__pm_schedules', mapping: 'equipment.pm_schedules', title: 'PM Schedules', type: 'tag', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__expected_hrs_per_mo', mapping: 'equipment.expected_hrs_per_mo', title: 'Expected Meter / Mo', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__in_service_date', mapping: 'equipment.in_service_date', title: 'In Service Date', type: 'date', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__in_service_meter', mapping: 'equipment.in_service_meter', title: 'In Service Meter', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__inactive_date', mapping: 'equipment.inactive_date', title: 'Inactive Date (calc)', type: 'date', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__available_hrs_per_wk', mapping: 'equipment.available_hrs_per_wk', title: 'Available Meter / Wk', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__parts', mapping: 'equipment.parts', title: 'Parts', type: 'tag', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__is_stale_stats', mapping: 'equipment.is_stale_stats', title: 'Stale Stats?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__current_meter', mapping: 'equipment.current_meter', title: 'Current Meter (calc)', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__next_pm_due_data', mapping: 'equipment.next_pm_due_data', title: 'Next PM Due Dates', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__latest_inspection_date', mapping: 'equipment.latest_inspection_date', title: 'Latest Inspection Date', type: 'date', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__latest_inspection_passed', mapping: 'equipment.latest_inspection_passed', title: 'Latest Inspection Passed', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__modified_at', mapping: 'equipment.modified_at', title: 'Last Modified', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, }
		],

		associations: {
			belongsTo: [
				'Checklists',
				'Equipment'
			],

		},

		ancillaryFilters: [],

		defaultFilters: [
			// 'checklists_equipment__checklist_id',
			// 'checklists_equipment__equipment_id'
		],

	},

	entity: {
		methods: {

			getChecklist: async function() {
				const Checklists = this.getAssociatedRepository('Checklists');
				let entity = Checklists.getById(this.checklists_equipment__checklist_id);
				if (!entity) {
					entity = await Checklists.getSingleEntityFromServer(this.checklists_equipment__checklist_id);
				}
				return entity;
			},

			getEquipment: async function() {
				const Equipment = this.getAssociatedRepository('Equipment');
				let entity = Equipment.getById(this.checklists_equipment__equipment_id);
				if (!entity) {
					entity = await Equipment.getSingleEntityFromServer(this.checklists_equipment__equipment_id);
				}
				return entity;
			},

		},
	},


	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": false,
	    "isAutoLoad": false
	},

};

export default ChecklistsEquipment;
