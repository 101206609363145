/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { SideGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import MeterReadingsEditor from '../Editor/MeterReadingsEditor.js';
import MeterReadingsGridColumns from './Columns/MeterReadingsGridColumns.js';

export default function MeterReadingsSideGridEditor(props) {
	return <SideGridEditor
				reference="MeterReadingsSideGridEditor"
				model="MeterReadings"
				usePermissions={true}
				isCollapsible={false}
				Editor={MeterReadingsEditor}
				columnsConfig={MeterReadingsGridColumns}
				
				
				{...props}
			/>;
}