/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import {
	EDITOR_TYPE__SIDE,
} from '@onehat/ui/src/Constants/Editor.js';
import QuestionGroupsEditor from '../../Editor/QuestionGroupsEditor.js';
import QuestionGroupsGridColumns from '../../Grid/Columns/QuestionGroupsGridColumns.js';

export default function QuestionGroupsSideGridEditorPanel(props) {
	return <GridPanel
				reference="QuestionGroupsSideGridEditorPanel"
				model="QuestionGroups"
				usePermissions={true}
				isCollapsible={false}
				isEditor={true}
				editorType={EDITOR_TYPE__SIDE}
				Editor={QuestionGroupsEditor}
				columnsConfig={QuestionGroupsGridColumns}
				canRowsReorder={true}
				
				{...props}
			/>;
}