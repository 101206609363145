/*
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */
import { useState, useEffect, } from 'react';
import { Linking } from 'react-native';
import {
	Row,
} from 'native-base';
import Url from 'url-parse';
import {
	useLinkTo,
} from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { useSelector, useDispatch } from 'react-redux';
import {
	VERTICAL,
} from '@onehat/ui/src/Constants/Directions.js';
import {
	VIEW,
} from '@onehat/ui/src/Constants/Commands.js';
import {
	setCurrentScreen,
	selectUser,
} from '../models/Slices/AppSlice';
import {
	setAlertMessage,
	setDebugMessage,
	selectDebugMessage,
	selectAlertMessage,
	selectInfoMessage,
	selectIsWaitModalShown,
	selectWaitMessage,
} from '../models/Slices/DebugSlice';
import withPermissions from '@onehat/ui/src/Components/Hoc/withPermissions';
import Loading from '@onehat/ui/src/Components/Messages/Loading';
import WaitMessage from '@onehat/ui/src/Components/Messages/WaitMessage';
import ErrorMessage from '@onehat/ui/src/Components/Messages/ErrorMessage';

import ChartPie from '@onehat/ui/src/Components/Icons/ChartPie';
import ClipboardCheck from '@onehat/ui/src/Components/Icons/ClipboardCheck';
import ListCheck from '@onehat/ui/src/Components/Icons/ListCheck';
import Gears from '@onehat/ui/src/Components/Icons/Gears';
import Hammer from '@onehat/ui/src/Components/Icons/Hammer';
import House from '@onehat/ui/src/Components/Icons/House';
import Crane from '../components/Icon/Crane';
import OilCan from '../components/Icon/OilCan';
import User from '@onehat/ui/src/Components/Icons/User';
import UserGroup from '@onehat/ui/src/Components/Icons/UserGroup';
import RightFromBracket from '@onehat/ui/src/Components/Icons/RightFromBracket';
import Link from '@onehat/ui/src/Components/Icons/Link';

import TabBar from '@onehat/ui/src/Components/Tab/TabBar.js';

import ChecklistsManager from '../screens/ChecklistsManager';
import EquipmentManager from '../screens/EquipmentManager';
import Home from '../screens/Home';
import InspectionsManager from '../screens/InspectionsManager';
import Logout from '../screens/Logout';
import ReportsManager from '../screens/ReportsManager';
import UsersManager from '../screens/UsersManager';
import GroupsManager from '../screens/GroupsManager';
import Util from '../screens/Util';
import AppGlobals from '../AppGlobals';
import Inflector from 'inflector-js';
import _ from 'lodash';

const
	Stack = createStackNavigator(),
	prefix = '/' + AppGlobals.urlPrefix;

function AppNavigator(props) {
	const {
			// withPermissions
			canUser,
		} = props,
		linkTo = useLinkTo(),
		dispatch = useDispatch(),
		isWaitModalShown = useSelector(selectIsWaitModalShown),
		debugMessage = useSelector(selectDebugMessage),
		alertMessage = useSelector(selectAlertMessage),
		infoMessage = useSelector(selectInfoMessage),
		waitMessage = useSelector(selectWaitMessage),
		user = useSelector(selectUser),
		[currentTabIx, setCurrentTabIx] = useState(0),
		[isReady, setIsReady] = useState(false),
		[initialRouteName, setInitialRouteName] = useState('Home'),
		onChangeCurrentTab = (ix) => {
			if (ix === 8) { // special case
				window.location.href = '/maint/home';
				return;
			}

			// update state
			setCurrentTabIx(ix);

			// switch nav
			const
				currentTab = tabs[ix],
				path = currentTab.path;
			linkTo(path);
			dispatch(setCurrentScreen(currentTab.screenName));
		};


	const tabs = [
		{
			title: 'Home',
			_icon: { as: House },
			path: prefix + Inflector.dasherize(Inflector.underscore('Home')),
			screenName: 'Home',
		},
	];

	if (canUser(VIEW, 'Inspections')) {
		tabs.push({
			title: 'Inspections',
			_icon: { as: ClipboardCheck },
			path: prefix + Inflector.dasherize(Inflector.underscore('Inspections')),
			screenName: 'InspectionsManager',
		});
	}
	if (canUser(VIEW, 'Checklists')) {
		tabs.push({
			title: 'Checklists',
			_icon: { as: ListCheck },
			path: prefix + Inflector.dasherize(Inflector.underscore('Checklists')),
			screenName: 'ChecklistsManager',
		});
	}
	if (canUser(VIEW, 'Equipment')) {
		tabs.push({
			title: 'Equipment',
			_icon: { as: Crane },
			path: prefix + Inflector.dasherize(Inflector.underscore('Equipment')),
			screenName: 'EquipmentManager',
		});
	}
	if (canUser('view_reports')) {
		tabs.push({
			title: 'Reports',
			_icon: { as: ChartPie },
			path: prefix + Inflector.dasherize(Inflector.underscore('Reports')),
			screenName: 'ReportsManager',
		});
	}
	if (canUser(VIEW, 'Users')) {
		tabs.push({
			title: 'Users',
			_icon: { as: User },
			path: prefix + Inflector.dasherize(Inflector.underscore('Users')),
			screenName: 'UsersManager',
		});
	}
	if (canUser(VIEW, 'Groups')) {
		tabs.push({
			title: 'Groups',
			_icon: { as: UserGroup },
			path: prefix + Inflector.dasherize(Inflector.underscore('Groups')),
			screenName: 'GroupsManager',
		});
	}
	tabs.push({
		title: 'Utilities',
		_icon: { as: Hammer },
		path: prefix + Inflector.dasherize(Inflector.underscore('Util')),
		screenName: 'Util',
	});
	tabs.push({
		title: 'To Maint App',
		_icon: { as: Link },
		action: () => {
			window.location.href = '/maint/';
		},
	});
	tabs.push({
		title: 'Logout',
		_icon: { as: RightFromBracket },
		path: prefix + Inflector.dasherize(Inflector.underscore('Logout')),
		screenName: 'Logout',
	});

	useEffect(() => {
		(async () => {
			const
				initialUrl = await Linking.getInitialURL(),
				url = new Url(initialUrl),
				path = url.pathname;

			if (!user) {
				linkTo(prefix + 'login');
				return
			}

			const 
				whichTab = _.find(tabs, (tab) => {
					return tab.path === path;
				}),
				whichTabIx = whichTab ? tabs.indexOf(whichTab) : 0;

			setCurrentTabIx(whichTabIx);
			if (whichTab) {
				setInitialRouteName(whichTab.screenName);
			}
			setIsReady(true);
		})();
	}, []);

	if (!isReady) {
		return <Loading isScreen={true} />;
	}

	const navigatorOptions = {
		initialRouteName,
		screenOptions: ({ route }) => {
			return {
				headerShown: false,
				title: 'Gingerich Admin App: ' + route.name.replace('Manager', ''),
			}
		},
	};

	return <Row flex={1} w="100%">
				{isWaitModalShown && <WaitMessage text={waitMessage} />}
				{!isWaitModalShown && alertMessage && <ErrorMessage text={alertMessage} onOk={() => dispatch(setAlertMessage(null))} />}
				{!isWaitModalShown && debugMessage && <ErrorMessage text={debugMessage} color="green" onOk={() => dispatch(setDebugMessage(null))} />}
				{!isWaitModalShown && infoMessage && <ErrorMessage text={infoMessage} color="#000" onOk={() => dispatch(setInfoMessage(null))} />}

				{!!currentTabIx && currentTabIx !== tabs.length -1 && // hide TabBar if showing Home or Logout
					<TabBar
						tabs={tabs}
						direction={VERTICAL}
						tabWidth={180}
						currentTabIx={currentTabIx}
						onChangeCurrentTab={onChangeCurrentTab}
					/>}
				<Stack.Navigator {...navigatorOptions}>
					<Stack.Screen name="Home">
						{() => <Home tabs={tabs} onChangeCurrentTab={onChangeCurrentTab} />}
					</Stack.Screen>
					<Stack.Screen name="InspectionsManager" component={InspectionsManager} />
					<Stack.Screen name="ChecklistsManager" component={ChecklistsManager} />
					<Stack.Screen name="EquipmentManager" component={EquipmentManager} />
					<Stack.Screen name="ReportsManager" component={ReportsManager} />
					<Stack.Screen name="UsersManager" component={UsersManager} />
					<Stack.Screen name="GroupsManager" component={GroupsManager} />
					<Stack.Screen name="Util" component={Util} />
					<Stack.Screen name="Logout" component={Logout} />
				</Stack.Navigator>
			</Row>;
}

export default withPermissions(AppNavigator, true);

