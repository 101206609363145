import { useState, useEffect, } from 'react';
import {
	Text,
} from 'native-base';
import { Linking } from 'react-native';
import {
	useLinkTo,
} from '@react-navigation/native';
import Url from 'url-parse';
import Button from '@onehat/ui/src/Components/Buttons/Button';
import Loading from '@onehat/ui/src/Components/Messages/Loading';
import CenterBox from '@onehat/ui/src/Components/Layout/CenterBox';
import AppGlobals from '../AppGlobals';
import Inflector from 'inflector-js';
import _ from 'lodash';
	
const urlPrefix = '/' + AppGlobals.urlPrefix;

export default function NotFound() {
	const
		linkTo = useLinkTo(),
		[path, setPath] = useState(0),
		[isReady, setIsReady] = useState(false),
		onGoHome = () => {
			linkTo(urlPrefix + Inflector.dasherize(Inflector.underscore('Home')));
		};

	useEffect(() => {
		(async () => {
			const
				initialUrl = await Linking.getInitialURL(),
				url = new Url(initialUrl),
				path = url.pathname;

			setPath(path);
			setIsReady(true);
		})();
	}, []);

	if (!isReady) {
		return <Loading isScreen={true} />;
	}

	return <CenterBox>
				<Text fontSize={20}>Page not found.</Text>
				<Text>{path}</Text>
				<Button mt={4} onPress={onGoHome}>To Home</Button>
			</CenterBox>;

}
