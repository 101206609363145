/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import InspectionsSideGridEditor from './InspectionsSideGridEditor.js';

export default function InspectionsFilteredSideGridEditor(props) {
	return <InspectionsSideGridEditor
				reference="InspectionsFilteredSideGridEditor"
				useFilters={true}

				{...props}
			/>;
}