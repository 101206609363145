export default [
	    {
	        "id": "meter_readings__date",
	        "header": "Date",
	        "fieldName": "meter_readings__date",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200
	    },
	    {
	        "id": "meter_readings__value",
	        "header": "Value",
	        "fieldName": "meter_readings__value",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
		{
	        "id": "meter_readings__is_bad",
	        "header": "Bad?",
	        "fieldName": "meter_readings__is_bad",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "meter_readings__is_replacement",
	        "header": "Replacement?",
	        "fieldName": "meter_readings__is_replacement",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150 // MOD
	    },
		{ // added this column
			"id": "meter_sources__name",
			"header": "Source",
			"fieldName": "meter_sources__name",
			"isSortable": true,
			"isEditable": false,
			"isReorderable": true,
			"isResizable": true,
			"w": 200
		},
	];