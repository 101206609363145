/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import {
	SELECTION_MODE_SINGLE,
} from '@onehat/ui/src/Constants/Selection.js';
import { WindowedGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import QuestionGroupsEditorWindow from '../Window/QuestionGroupsEditorWindow.js';
import QuestionGroupsGridColumns from './Columns/QuestionGroupsGridColumns.js';

export default function QuestionGroupsGridEditor(props) {
	return <WindowedGridEditor
				reference="QuestionGroupsGridEditor"
				model="QuestionGroups"
				usePermissions={true}
				selectionMode={SELECTION_MODE_SINGLE}
				Editor={QuestionGroupsEditorWindow}
				columnsConfig={QuestionGroupsGridColumns}
				canRowsReorder={true}
				
				{...props}
			/>;
}