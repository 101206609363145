export default [
	    {
	        "id": "inspections__date",
	        "header": "Date",
	        "fieldName": "inspections__date",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200
	    },
	    {
	        "id": "inspections__checklist_id",
	        "header": "Checklist",
	        "fieldName": "checklists__name",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 250, // MOD
	        "editField": "inspections__checklist_id"
	    },
	    {
	        "id": "inspections__is_failure",
	        "header": "Failure?",
	        "fieldName": "inspections__is_failure",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "inspections__comments",
	        "header": "Comments",
	        "fieldName": "inspections__comments",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200
	    },
	    {
	        "id": "inspections__equipment_id",
	        "header": "Equipment",
	        "fieldName": "equipment__nickname",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200, // MOD
	        "editField": "inspections__equipment_id"
	    },
	    {
	        "id": "inspections__user_id",
	        "header": "User",
	        "fieldName": "users__username",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100,
	        "editField": "inspections__user_id"
	    },
	    {
	        "id": "inspections__reference",
	        "header": "Reference",
	        "fieldName": "inspections__reference",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200
	    },
	    {
	        "id": "inspections__has_attachments",
	        "header": "Has Attachments",
	        "fieldName": "inspections__has_attachments",
	        "isSortable": false,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150 // MOD
	    }
	];