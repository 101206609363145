/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */
import Editor from '@onehat/ui/src/Components/Editor/Editor.js';
import _ from 'lodash';

export default function ChecklistsEditor(props) {

	const
		items = [
		    {
		        "type": "Column",
		        "flex": 1,
		        "defaults": {},
		        "items": [
		            {
		                "type": "FieldSet",
		                "title": "Checklist Info",
		                "reference": "checklist_info",
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "checklists__name"
		                    },
		                    {
		                        "name": "checklists__checklist_type_id"
		                    },
		                    {
		                        "name": "checklists__response_options",
		                        "tooltip": "Comma-separated list of responses. e.g. 'Red,Blue,Green'. These are the default for the whole checklist."
		                    },
		                    {
		                        "name": "checklists__fail_options",
		                        "tooltip": "Comma-separated list of fail options. e.g. 'Blue,Green'.  These are the default for the whole checklist."
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "Settings",
		                "reference": "settings",
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "checklists__groups",
		                        "tooltip": "Which groups will this checklist be shown to? i.e. This checklist will only be shown to a user if that user is in one of these groups."
		                    },
		                    {
		                        "name": "checklists__can_save_for_later"
		                    },
		                    {
		                        "name": "checklists__use_multiple_screens"
		                    },
		                    {
		                        "name": "checklists__email_to",
		                        "tooltip": "Comma-separated list of email addresses. e.g. 'x@y.com,w@z.com'"
		                    }
		                ]
		            }
		        ]
		    }
		],
		ancillaryItems = [
		    // {
		    //     "title": "Inspections",
		    //     "type": "InspectionsGridEditor",
		    //     "selectorId": "inspections__checklist_id"
		    // },
		    {
		        "title": "Question Groups",
		        "type": "QuestionGroupsGridEditor",
		        "selectorId": "question_groups__checklist_id"
		    },
		    {
		        "title": "Questions",
		        "type": "QuestionsGridEditor",
		        "selectorId": "questions__checklist_id",
				h: 600, // MOD
		    },
		    {
		        "title": "Equipment",
		        "type": "ChecklistsEquipmentGridEditorSideB",
		        "selectorId": "checklists_equipment__checklist_id"
		    },
		],
		columnDefaults = { // defaults for each column defined in 'items', for use in Form amd Viewer
		};
	return <Editor
				reference="ChecklistsEditor"
				title="Checklists"
				items={items}
				ancillaryItems={ancillaryItems}
				columnDefaults={columnDefaults}
				{...props}
			/>;
}

