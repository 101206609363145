/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import EqEngineModelsSideGridEditorPanel from './EqEngineModelsSideGridEditor.js';


export default function EqEngineModelsFilteredSideGridEditorPanel(props) {
	return <EqEngineModelsSideGridEditorPanel
				reference="EqEngineModelsFilteredSideGridEditorPanel"
				useFilters={true}
				
				{...props}
			/>;
}