/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import EqManufacturersGridPanel from './EqManufacturersGrid.js';

export default function EqManufacturersFilteredGridPanel(props) {
	return <EqManufacturersGridPanel
				reference="EqManufacturersFilteredGridPanel"
				useFilters={true}

				{...props}
			/>;
}