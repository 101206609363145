import * as yup from 'yup'; // https://github.com/jquense/yup#string

const MeterSources = {
	
	name: 'MeterSources',
	
	model: {

		idProperty: 'meter_sources__id',
		displayProperty: 'meter_sources__name',
		sortProperty: 'meter_sources__name',
		
		sorters: null,

		validator: yup.object({
			meter_sources__name: yup.string().max(45).required()
		}),
		
		properties: [
			{ name: 'meter_sources__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'meter_sources__name', mapping: 'name', title: 'Name', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', }
		],

		associations: {
			hasMany: [
				'MeterReadings'
			],

		},

		ancillaryFilters: [],

		defaultFilters: [

		],

	},

	entity: {
		methods: {

			getMeterReadings: function() {
				const MeterReadings = this.getAssociatedRepository('MeterReadings');
				return MeterReadings.getBy((entity) => {
					return entity.meter_readings__meter_source_id === this.meter_sources__id;
				});
			},

		},
	},


	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": false,
	    "isAutoLoad": true,
	    "isPaginated": false
	},

};

export default MeterSources;
