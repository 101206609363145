/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import ChecklistTypesSideGridEditor from './ChecklistTypesSideGridEditor.js';

export default function ChecklistTypesFilteredSideGridEditor(props) {
	return <ChecklistTypesSideGridEditor
				reference="ChecklistTypesFilteredSideGridEditor"
				useFilters={true}

				{...props}
			/>;
}