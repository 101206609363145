export default [
	    {
	        "id": "question_groups__sort_order",
	        "header": "Sort",
	        "fieldName": "question_groups__sort_order",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "question_groups__name",
	        "header": "Name",
	        "fieldName": "question_groups__name",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 300 // MOD
	    },
	    // {
	    //     "id": "question_groups__checklist_id",
	    //     "header": "Checklist",
	    //     "fieldName": "checklists__name",
	    //     "isSortable": true,
	    //     "isEditable": true,
	    //     "isReorderable": true,
	    //     "isResizable": true,
	    //     "w": 100,
	    //     "editField": "question_groups__checklist_id"
	    // },
	    {
	        "id": "question_groups__is_skippable",
	        "header": "Skippable?",
	        "fieldName": "question_groups__is_skippable",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    }
	];