export default [
		{ // MOD - add this column
			"header": "Sort",
			"fieldName": "questions__sort_order",
			"isSortable": true,
			"isEditable": false,
			"isReorderable": true,
			"isResizable": true,
			"w": 100
		},
	    {
	        "id": "responses__question_id",
	        "header": "Question",
	        "fieldName": "questions__display_value",
	        "isSortable": false,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 250, // MOD
	        "editField": "responses__question_id"
	    },
	    {
	        "id": "responses__value",
	        "header": "Value",
	        "fieldName": "responses__value",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "responses__is_failure",
	        "header": "Failure?",
	        "fieldName": "responses__is_failure",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "responses__is_skipped",
	        "header": "Skipped?",
	        "fieldName": "responses__is_skipped",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "responses__comments",
	        "header": "Comments",
	        "fieldName": "responses__comments",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200
	    },
	    // {
	    //     "id": "responses__group",
	    //     "header": "Group",
	    //     "fieldName": "responses__group",
	    //     "isSortable": false,
	    //     "isEditable": false,
	    //     "isReorderable": true,
	    //     "isResizable": true,
	    //     "w": 200
	    // },
	    // {
	    //     "id": "responses__inspection_id",
	    //     "header": "Inspection",
	    //     "fieldName": "inspections__reference",
	    //     "isSortable": true,
	    //     "isEditable": false,
	    //     "isReorderable": true,
	    //     "isResizable": true,
	    //     "w": 100,
	    //     "editField": "responses__inspection_id"
	    // }
	];