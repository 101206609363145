/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import {
	SELECTION_MODE_SINGLE,
} from '@onehat/ui/src/Constants/Selection.js';
import { WindowedGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import EquipmentEditorWindow from '../Window/EquipmentEditorWindow.js';
import EquipmentGridColumns from './Columns/EquipmentGridColumns.js';
import EquipmentGridDefaultHiddenColumns from './Columns/EquipmentGridDefaultHiddenColumns.js';

export default function EquipmentGridEditor(props) {
	return <WindowedGridEditor
				reference="EquipmentGridEditor"
				model="Equipment"
				usePermissions={true}
				selectionMode={SELECTION_MODE_SINGLE}
				Editor={EquipmentEditorWindow}
				columnsConfig={EquipmentGridColumns}
				defaultHiddenColumns={EquipmentGridDefaultHiddenColumns}
				useRemoteDuplicate={true}
				
				
				{...props}
			/>;
}