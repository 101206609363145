/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import EqManufacturersGridColumns from '../../Grid/Columns/EqManufacturersGridColumns.js';

export default function EqManufacturersGridPanel(props) {
	return <GridPanel
				reference="EqManufacturersGridPanel"
				model="EqManufacturers"
				usePermissions={true}
				columnsConfig={EqManufacturersGridColumns}

				{...props}
			/>;
}