/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */
// import { useState, } from 'react';
// import {
// 	Modal,
// } from 'native-base';
import {
	commonApplicationMimeTypes,
	commonAudioMimeTypes,
	commonImageMimeTypes,
	commonTextMimeTypes,
	commonVideoMimeTypes,
} from '@onehat/ui/src/Constants/MimeTypes.js';
// import ManagePmTemplatesWindow from '../Window/ManagePmTemplatesWindow.js';
import Editor from '@onehat/ui/src/Components/Editor/Editor.js';
// import Plus from '@onehat/ui/src/Components/Icons/Plus.js';

import _ from 'lodash';

export default function EquipmentEditor(props) {

	if (_.isEmpty(props.selection) || props.selection[0]?.isDestroyed) {
		return null;
	}

	const
		{
			selection,
			parent,
		} = props,
		// [isModalShown, setIsModalShown] = useState(false),
		items = [
			{
				"type": "Column",
				"flex": 1,
				"defaults": {},
				"items": [
					{
						"type": "FieldSet",
						"title": "Identification",
						"reference": "identification",
						"defaults": {},
						"items": [
							{
								"name": "equipment__eq_status_id",
								"type": "EqStatusesSwitch"
							},
							{
								"name": "equipment__inactive_date",
								"isEditable": false,
								"isEditingEnabledInPlainEditor": true
							},
							{
								"name": "equipment__nickname"
							},
							{
								"name": "equipment__eq_manufacturer_id"
							},
							{
								"name": "equipment__model"
							},
							{
								"name": "equipment__serial"
							},
							{
								"name": "equipment__eq_type_id"
							},
							{
								"name": "equipment__year"
							},
						]
					},
					// {
					// 	"type": "FieldSet",
					// 	"title": "PM Setup",
					// 	"reference": "p_m_setup",
					// 	"defaults": {},
					// 	"items": [
					// 		{
					// 			type: 'Button',
					// 			text: 'Manage PM Templates',
					// 			isEditable: false,
					// 			isEditingEnabledInPlainEditor: true,
					// 			isHiddenInViewMode: true,
					// 			onPress: () => setIsModalShown(true),
					// 		},
					// 		{
					// 			"name": "equipment__is_pm_enable"
					// 		},
					// 		{
					// 			"name": "equipment__pm_schedules",
					// 			numberOfLines: 5,
					// 		},
					// 		{
					// 			"name": "equipment__expected_hrs_per_mo"
					// 		},
					// 		// {
					// 		// 	"name": "equipment__available_hrs_per_wk" // Gingerich doesn't use utilization, so hide this
					// 		// },
					// 		{
					// 			"name": "equipment__in_service_date"
					// 		},
					// 		{
					// 			"name": "equipment__in_service_meter"
					// 		},
					// 	]
					// },
				]
			},
			{
				"type": "Column",
				"flex": 1,
				"defaults": {},
				"items": [
					{
						"type": "FieldSet",
						"title": "Specs",
						"reference": "specs",
						"defaults": {},
						"items": [
							{
								"name": "equipment__eq_engine_model_id"
							},
							{
								"name": "equipment__engine_serial"
							},
							// {
							// 	"name": "equipment__parts",
							// 	totalLines: 6,
							// },
							{
								"name": "equipment__comments",
								totalLines: 6,
							}
						]
					},
					{
						"type": "FieldSet",
						"title": "Calculated",
						"reference": "calculated",
						"defaults": {},
						"items": [
							{
								"name": "equipment__is_stale_stats",
								"isEditable": false,
								"isEditingEnabledInPlainEditor": true
							},
							{
								"name": "equipment__current_meter",
								"isEditable": false,
								"isEditingEnabledInPlainEditor": true
							},
							// {
							// 	"name": "equipment__next_pm_due_data",
							// 	"isEditable": false,
							// 	"isEditingEnabledInPlainEditor": true
							// },
							{
								"name": "equipment__latest_inspection_date",
								"isEditable": false,
								"isEditingEnabledInPlainEditor": true
							},
							{
								"name": "equipment__latest_inspection_passed",
								"isEditable": false,
								"isEditingEnabledInPlainEditor": true
							},
							{
								"name": "equipment__modified_at",
								"isEditable": false,
								"isEditingEnabledInPlainEditor": true
							},
						]
					}
				]
			},
		],
		equipment_id = selection[0].id,
		// equipment_display = selection[0].displayValue,
		ancillaryItems = [
			{
				title: 'Photos',
				type: 'Attachments',
				accept: commonImageMimeTypes.join(','),
				confirmBeforeDelete: true,
				baseParams: {
					'conditions[attachments__is_annual_report]': 0,
				},
			},
			{
				title: 'Files',
				type: 'Attachments',
				accept: [
					...commonApplicationMimeTypes,
					...commonAudioMimeTypes,
					...commonTextMimeTypes,
					...commonVideoMimeTypes,
				].join(','),
				confirmBeforeDelete: true,
				useFileMosaic: false,
				baseParams: {
					'conditions[attachments__is_annual_report]': 0,
				},
			},
			{
				title: 'Annual Inspections',
				type: 'Attachments',
				accept: [
					...commonApplicationMimeTypes,
					...commonImageMimeTypes,
				].join(','),
				confirmBeforeDelete: true,
				useFileMosaic: false,
				baseParams: {
					'conditions[attachments__is_annual_report]': 1,
				},
				extraUploadData: {
					'additionalAttributes[is_annual_report]': 1,
				},
			},
			{
				title: 'Meter Readings',
				"type": "MeterReadingsFilteredGridEditor",
				baseParams: {
					'conditions[meter_readings__equipment_id]': equipment_id,
				},
				onChange: () => {
					if (!parent.selection[0].isPhantom) {
						parent.selection[0].reload();
					}
				},
				defaultValues: {
					meter_readings__equipment_id: equipment_id,
				},
			},
			{
				"title": "Checklists",
				description: 'This is where you assign checklists to this equipment',
				"type": "ChecklistsEquipmentFilteredGridEditorSideA",
				"selectorId": "checklists_equipment__equipment_id"
			},
			{
				"title": "Inspections",
				description: 'Historic list of inspections for this equipment',
				"type": "InspectionsFilteredGridEditor",
				"selectorId": "inspections__equipment_id"
			},
			// {
			// 	title: 'Pm Events',
			// 	"type": "PmEventsFilteredGridEditor",
			// 	baseParams: {
			// 		'conditions[pm_events__equipment_id]': equipment_id,
			// 	},
			// 	defaultValues: {
			// 		pm_events__equipment_id: equipment_id,
			// 	},
			// },
			// {
			// 	title: 'Work Orders',
			// 	"type": "WorkOrdersFilteredGridEditor",
			// 	baseParams: {
			// 		leftJoinWith: 'Equipment',
			// 		'conditions[equipment__id IN]': equipment_id,
			// 		'conditions[work_orders__is_pm_template]': false,
			// 	},
			// 	reference: 'workOrdersFilteredGridEditor',
			// 	canEditorViewOnly: false, // allow editing in Viewer
			// 	onChange: () => {
			// 		parent.children.editor.children.meterReadingsFilteredGridEditor?.repository.reload();
			// 	},
			// 	defaultValues: {
			// 		work_orders__is_pm_template: false,
			// 		work_orders__equipment: JSON.stringify([{
			// 			id: equipment_id,
			// 			text: equipment_display,
			// 		}]),
			// 	},
			// },
			// {
			// 	title: 'Parts',
			// 	type: 'EquipmentPartsFilteredGridEditorSideB',
			// 	reference: 'EquipmentPartsFilteredGridEditorSideB',
			// 	selectorId: 'equipment_parts__equipment_id',
			// 	customFilters: [
			// 		{
			// 			title: 'Type',
			// 			type: 'PartTypesCombo',
			// 			field: 'parts_part_types__part_type_id',
			// 			value: '',
			// 			getRepoFilters: (value) => {
			// 				return {
			// 					name: 'parts_part_types__part_type_id',
			// 					value,
			// 				};
			// 			},
			// 		},
			// 		{
			// 			title: 'Make',
			// 			type: 'PartManufacturersCombo',
			// 			field: 'parts__part_manufacturer_id',
			// 			value: '',
			// 			getRepoFilters: (value) => {
			// 				return {
			// 					name: 'parts__part_manufacturer_id',
			// 					value,
			// 				};
			// 			},
			// 		},
			// 		{
			// 			title: 'Source',
			// 			type: 'PartSourcesCombo',
			// 			field: 'parts__part_source_id',
			// 			value: '',
			// 			getRepoFilters: (value) => {
			// 				return {
			// 					name: 'parts__part_source_id',
			// 					value,
			// 				};
			// 			},
			// 		},
			// 	],
			// 	onEditorClose: () => {
			// 		const PartsGrid = parent.children.editor.children.form.children.EquipmentPartsFilteredGridEditorSideB;
			// 		PartsGrid.repository.reload();
			// 	},
			// },
		],
		additionalEditButtons = [
			// {
			// 	key: 'addWorkOrderBtn',
			// 	text: 'Add Work Order',
			// 	isDisabled: selection.length !== 1,
			// 	handler: function(e) {
			// 		const
			// 			self = props.parent.children[props.reference], // we have to do this bc 'self' isn't yet populated on props (Editor uses withComponent beneath this HOC)
			// 			workOrdersFilteredGridEditor = self.children.form.children.workOrdersFilteredGridEditor;
					
			// 		workOrdersFilteredGridEditor.ref.current.previousSibling.previousSibling.scrollIntoView(); // previousSibling.previousSibling gets the title, not just the grid
					
			// 		workOrdersFilteredGridEditor.add();
			// 	},
			// 	icon: Plus,
			// },
		],
		onModalClose = () => setIsModalShown(false);
		return <Editor
					reference="EquipmentEditor"
					title="Equipment"
					items={items}
					ancillaryItems={ancillaryItems}
					showPdfBtns={true}
					additionalEditButtons={additionalEditButtons}
					{...props}
				/>;
		// return <>
		// 			<Editor
		// 				reference="EquipmentEditor"
		// 				title="Equipment"
		// 				items={items}
		// 				ancillaryItems={ancillaryItems}
		// 				showPdfBtns={true}
		// 				additionalEditButtons={additionalEditButtons}
		// 				{...props}
		// 			/>
		// 			{isModalShown && 
		// 				<Modal
		// 					isOpen={true}
		// 					onClose={onModalClose}
		// 				>
		// 					<ManagePmTemplatesWindow
		// 						selection={props.selection}
		// 						equipment_id={equipment_id}
		// 						equipment_display={equipment_display}
		// 						onClose={onModalClose}
		// 					/>
		// 				</Modal>}
		// 		</>;
}

