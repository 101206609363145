/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import InspectionsGridColumns from '../../Grid/Columns/InspectionsGridColumns.js';
import {
	EDITOR_TYPE__INLINE,
} from '@onehat/ui/src/Constants/Editor.js';

export default function InspectionsInlineGridEditorPanel(props) {
	return <GridPanel
				reference="InspectionsInlineGridEditorPanel"
				model="Inspections"
				usePermissions={true}
				isEditor={true}
				editorType={EDITOR_TYPE__INLINE}
				columnsConfig={InspectionsGridColumns}
				
				
				{...props}
			/>;
}