export default [
	'equipment__pm_schedules',
	'equipment__expected_hrs_per_mo',
	'equipment__in_service_date',
	'equipment__in_service_meter',
	'equipment__parts',
	'equipment__available_hrs_per_wk',
	'equipment__is_stale_stats',
	'equipment__next_pm_due_data',
	'equipment__latest_inspection_date',
	'equipment__latest_inspection_passed',
];