import * as yup from 'yup'; // https://github.com/jquense/yup#string

const Locations = {
	
	name: 'Locations',
	
	model: {

		idProperty: 'locations__id',
		displayProperty: 'locations__name',
		sortProperty: 'locations__name',
		
		sorters: null,

		validator: yup.object({
			locations__name: yup.string().max(128).required()
		}),
		
		properties: [
			{ name: 'locations__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'locations__name', mapping: 'name', title: 'Name', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', }
		],

		associations: {
			hasMany: [
				'WorkOrders'
			],

		},

		ancillaryFilters: [],

		defaultFilters: [

		],

	},

	entity: {
		methods: {

			getWorkOrders: function() {
				const WorkOrders = this.getAssociatedRepository('WorkOrders');
				return WorkOrders.getBy((entity) => {
					return entity.work_orders__location_id === this.locations__id;
				});
			},

		},
	},


	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": false,
	    "isAutoLoad": false
	},

};

export default Locations;
