import { useState, } from 'react';
import {
	Column,
	Row,
	ScrollView,
	Text,
} from 'native-base';
import { useSelector } from 'react-redux';
import {
	selectCurrentScreen,
} from '../models/Slices/AppSlice';
import UiGlobals from '@onehat/ui/src/UiGlobals.js';
import Report2 from '../components/Reports/Report2.js';
import Report3 from '../components/Reports/Report3.js';

const CONTAINER_THRESHOLD = 1100;

export default function ReportsManager(props) {
	
	const
		currentScreen = useSelector(selectCurrentScreen),
		isActive = currentScreen === 'ReportsManager',
		styles = UiGlobals.styles,
		[containerWidth, setContainerWidth] = useState(),
		onLayout = (e) => {
			setContainerWidth(e.nativeEvent.layout.width);
		},
		reports = [
			<Report2 key="report2" />,
			<Report3 key="report3" />,
		];

	if (!isActive) {
		return null;
	}
	
	let reportElements = [];
	if (containerWidth) {
		if (containerWidth >= CONTAINER_THRESHOLD) {
			// two column layout
			const
				reportsPerColumn = Math.ceil(reports.length / 2),
				col1Reports = reports.slice(0, reportsPerColumn),
				col2Reports = reports.slice(reportsPerColumn);
			reportElements = <Row>
								<Column flex={1}>
									{col1Reports}
								</Column>
								<Column flex={1} pl={2}>
									{col2Reports}
								</Column>
							</Row>;
		} else {
			// one column layout
			reportElements = reports;
		}
	}

	const textProps = {};
	if (styles.MANAGER_SCREEN_TITLE) {
		textProps.style = {
			fontFamily: styles.MANAGER_SCREEN_TITLE,
		};
	}
	
	return <Column flex={1} w="100%">
				<Row
					h="80px"
					py={2}
					borderBottomWidth={2}
					borderBottomColor="#ccc"
				>
					<Text p={4} fontSize="26" fontWeight={700} {...textProps}>Reports</Text>
				</Row>
				<ScrollView w="100%">
					<Column w="100%" onLayout={onLayout} p={4}>
						{containerWidth && reportElements}
					</Column>
				</ScrollView>
			</Column>;
}