/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import QuestionGroupsGridPanel from './QuestionGroupsGrid.js';

export default function QuestionGroupsFilteredGridPanel(props) {
	return <QuestionGroupsGridPanel
				reference="QuestionGroupsFilteredGridPanel"
				useFilters={true}

				{...props}
			/>;
}