import Report from '@onehat/ui/src/Components/Report/Report.js';
import withComponent from '@onehat/ui/src/Components/Hoc/withComponent.js';
import Globals from '../../AppGlobals.js';

function ReportComponent(props) {
	const {
			self,
		} = props;
	return <Report
				title="Summary Inspection Results"
				description="Provides summary inspection results during the given time frame."
				reportId={3}
				_form={{
					items: [
						{
							"type": "Column",
							"flex": 1,
							"defaults": {},
							"items": [
								{
									name: 'startDate',
									type: 'Date',
									label: 'Start Date',
									allowBlank: true,
									tooltip: 'Earliest date to display',
								},
								{
									name: 'endDate',
									type: 'Date',
									label: 'End Date',
									allowBlank: true,
									tooltip: 'Latest date to display',
								},
								{
									name: 'equipment_id',
									type: 'EquipmentCombo',
									label: 'Equipment',
									allowBlank: true,
									tooltip: 'Which equipment to display results for'
								},
							]
						},
						{
							"type": "Column",
							"flex": 1,
							"defaults": {},
							"items": [
								{
									name: 'checklist_type_id',
									type: 'ChecklistTypesCombo',
									label: 'Type',
									allowBlank: true,
									tooltip: 'Which type of inspection to display results for',
								},
								{
									name: 'show_only_failures',
									type: 'Toggle',
									label: 'Show only failures?',
									allowBlank: false,
									tooltip: 'Show only failures (Yes) or all results (No)'
								},
							]
						},
					],
					startingValues: {
						startDate: Globals.dates.startOfThisMonth,
						endDate: Globals.dates.today,
						equipment_id: null,
						show_only_failures: false,
						checklist_type_id: 1,
					},
				}}
				parent={self}
			/>;
}


function withAdditionalProps(WrappedComponent) {
	return (props) => {
		return <WrappedComponent
					reference="report3"
					{...props}
				/>;
	};
}
export default withAdditionalProps(withComponent(ReportComponent));
