/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { TagEditor } from '@onehat/ui/src/Components/Form/Field/Tag/Tag.js';
import EqTypesEditorWindow from '../../../Window/EqTypesEditorWindow.js';

function EqTypesTagEditor(props) {
	return <TagEditor
				reference="EqTypesTagEditor"
				model="EqTypes"
				uniqueRepository={true}
				Editor={EqTypesEditorWindow}
				usePermissions={true}
				{...props}
			/>;
}

export default EqTypesTagEditor;