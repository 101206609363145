/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import ChecklistsEquipmentInlineGridEditor from './ChecklistsEquipmentInlineGridEditor.js';

export default function ChecklistsEquipmentFilteredInlineGridEditor(props) {
	return <ChecklistsEquipmentInlineGridEditor
				reference="ChecklistsEquipmentFilteredInlineGridEditor"
				useFilters={true}

				{...props}
			/>;
}