/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { ComboEditor } from '@onehat/ui/src/Components/Form/Field/Combo/Combo.js';
import ChecklistsGroupsEditorWindow from '../../../Window/ChecklistsGroupsEditorWindow.js';

function ChecklistsGroupsComboEditor(props) {
	return <ComboEditor
				reference="ChecklistsGroupsComboEditor"
				model="ChecklistsGroups"
				uniqueRepository={true}
				Editor={ChecklistsGroupsEditorWindow}
				usePermissions={true}
				{...props}
			/>;
}

export default ChecklistsGroupsComboEditor;