/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { SideGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import EqStatusesEditor from '../Editor/EqStatusesEditor.js';
import EqStatusesGridColumns from './Columns/EqStatusesGridColumns.js';

export default function EqStatusesSideGridEditor(props) {
	return <SideGridEditor
				reference="EqStatusesSideGridEditor"
				model="EqStatuses"
				usePermissions={true}
				isCollapsible={false}
				Editor={EqStatusesEditor}
				columnsConfig={EqStatusesGridColumns}
				
				disableAdd={true}
				disableEdit={true}
				disableDelete={true}
				{...props}
			/>;
}