/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import {
	EDITOR_TYPE__WINDOWED,
} from '@onehat/ui/src/Constants/Editor.js';
import ChecklistsGroupsEditorWindow from '../../Window/ChecklistsGroupsEditorWindow.js';
import ChecklistsGroupsGridColumns from '../../Grid/Columns/ChecklistsGroupsGridColumns.js';

export default function ChecklistsGroupsGridEditorPanel(props) {
	return <GridPanel
				reference="ChecklistsGroupsGridEditorPanel"
				model="ChecklistsGroups"
				usePermissions={true}
				isEditor={true}
				editorType={EDITOR_TYPE__WINDOWED}
				Editor={ChecklistsGroupsEditorWindow}
				columnsConfig={ChecklistsGroupsGridColumns}
				
				
				{...props}
			/>;
}