/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */
import Editor from '@onehat/ui/src/Components/Editor/Editor.js';
import _ from 'lodash';

export default function GroupsEditor(props) {

	const
		items = [
		    {
		        "type": "Column",
		        "flex": 1,
		        "defaults": {},
		        "items": [
		            {
		                "type": "FieldSet",
		                "title": "General",
		                "reference": "general",
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "groups__name"
		                    },
		                    {
		                        "name": "groups__description"
		                    }
		                ]
		            }
		        ]
		    }
		],
		ancillaryItems = [
		    // {
		    //     "title": "Users",
		    //     "type": "GroupsUsersGridEditorSideB",
		    //     "selectorId": "groups_users__group_id"
		    // },
		    // {
		    //     "title": "Groups",
		    //     "type": "ChecklistsGroupsGridEditorSideB",
		    //     "selectorId": "checklists_groups__group_id"
		    // },
		    // {
		    //     "title": "Checklists",
		    //     "type": "ChecklistsGroupsGridEditorSideA",
		    //     "selectorId": "checklists_groups__group_id"
		    // },
		    {
		        "title": "Permissions",
		        "type": "PermissionsGridEditor",
		        "selectorId": "permissions__group_id",
				description: 'Add a single row below to set permissions for this group.',
				h: 200,
		    }
		],
		columnDefaults = { // defaults for each column defined in 'items', for use in Form amd Viewer
		};
	return <Editor
				reference="GroupsEditor"
				title="Groups"
				items={items}
				ancillaryItems={ancillaryItems}
				columnDefaults={columnDefaults}
				{...props}
			/>;
}

