/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { ComboEditor } from '@onehat/ui/src/Components/Form/Field/Combo/Combo.js';
import ChecklistsEditorWindow from '../../../Window/ChecklistsEditorWindow.js';

function ChecklistsComboEditor(props) {
	return <ComboEditor
				reference="ChecklistsComboEditor"
				model="Checklists"
				uniqueRepository={true}
				Editor={ChecklistsEditorWindow}
				usePermissions={true}
				{...props}
			/>;
}

export default ChecklistsComboEditor;