import * as yup from 'yup'; // https://github.com/jquense/yup#string

const ChecklistsGroups = {
	
	name: 'ChecklistsGroups',
	
	model: {

		idProperty: 'checklists_groups__id',
		displayProperty: 'checklists_groups__id',
		sortProperty: 'checklists_groups__id',
		
		sorters: null,

		validator: yup.object({
			checklists_groups__checklist_id: yup.number().integer().required(),
			checklists_groups__group_id: yup.number().integer().required()
		}),
		
		properties: [
			{ name: 'checklists_groups__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'checklists_groups__checklist_id', mapping: 'checklist_id', title: 'Checklist', type: 'int', isFk: true, fkIdField: 'checklists__id', fkDisplayField: 'checklists__name', filterType: {"type":"ChecklistsCombo","loadAfterRender":!1}, editorType: {"type":"ChecklistsCombo","loadAfterRender":!1}, fieldGroup: 'General', },
			{ name: 'checklists_groups__group_id', mapping: 'group_id', title: 'Group', type: 'int', isFk: true, fkIdField: 'groups__id', fkDisplayField: 'groups__name', filterType: {"type":"GroupsCombo","loadAfterRender":!1}, editorType: {"type":"GroupsComboEditor","loadAfterRender":!1}, fieldGroup: 'General', },
			{ name: 'checklists__id', mapping: 'checklist.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'checklists__name', mapping: 'checklist.name', title: 'Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'checklists__checklist_type_id', mapping: 'checklist.checklist_type_id', title: 'Checklist Type', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'checklists__response_options', mapping: 'checklist.response_options', title: 'Response Options', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'checklists__fail_options', mapping: 'checklist.fail_options', title: 'Fail Options', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'checklists__groups', mapping: 'checklist.groups', title: 'Groups', type: 'tag', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'checklists__can_save_for_later', mapping: 'checklist.can_save_for_later', title: 'Can Save For Later', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'checklists__use_multiple_screens', mapping: 'checklist.use_multiple_screens', title: 'Use Multiple Screens', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'checklists__email_to', mapping: 'checklist.email_to', title: 'Email To', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'groups__id', mapping: 'group.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'groups__name', mapping: 'group.name', title: 'Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'groups__description', mapping: 'group.description', title: 'Description', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, }
		],

		associations: {
			belongsTo: [
				'Checklists',
				'Groups'
			],

		},

		ancillaryFilters: [],

		defaultFilters: [
			// 'checklists_groups__checklist_id',
			// 'checklists_groups__group_id'
		],

	},

	entity: {
		methods: {

			getChecklist: async function() {
				const Checklists = this.getAssociatedRepository('Checklists');
				let entity = Checklists.getById(this.checklists_groups__checklist_id);
				if (!entity) {
					entity = await Checklists.getSingleEntityFromServer(this.checklists_groups__checklist_id);
				}
				return entity;
			},

			getGroup: async function() {
				const Groups = this.getAssociatedRepository('Groups');
				let entity = Groups.getById(this.checklists_groups__group_id);
				if (!entity) {
					entity = await Groups.getSingleEntityFromServer(this.checklists_groups__group_id);
				}
				return entity;
			},

		},
	},


	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": false,
	    "isAutoLoad": false
	},

};

export default ChecklistsGroups;
