/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import ChecklistsEquipmentGridEditorPanel from './ChecklistsEquipmentGridEditor.js';

export default function ChecklistsEquipmentFilteredGridEditorPanel(props) {
	return <ChecklistsEquipmentGridEditorPanel
				reference="ChecklistsEquipmentFilteredGridEditorPanel"
				useFilters={true}
				
				{...props}
			/>;
}