/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import {
	SELECTION_MODE_SINGLE,
} from '@onehat/ui/src/Constants/Selection.js';
import { WindowedGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import MeterReadingsEditorWindow from '../Window/MeterReadingsEditorWindow.js';
import MeterReadingsGridColumns from './Columns/MeterReadingsGridColumns.js';
import { METER_SOURCES__MANUAL_ENTRY } from '../../constants/MeterReadings';
import _ from 'lodash';

export default function MeterReadingsGridEditor(props) {
	const canCrud = (selection) => {
		if (_.isEmpty(selection) || selection.length > 1) {
			return false;
		}
		return selection[0].meter_readings__meter_source_id === METER_SOURCES__MANUAL_ENTRY;
	};
	return <WindowedGridEditor
				reference="MeterReadingsGridEditor"
				model="MeterReadings"
				usePermissions={true}
				selectionMode={SELECTION_MODE_SINGLE}
				Editor={MeterReadingsEditorWindow}
				columnsConfig={MeterReadingsGridColumns}
				canRecordBeEdited={canCrud}
				canRecordBeDeleted={canCrud}
				canRecordBeDuplicated={canCrud}

				{...props}
			/>;
}