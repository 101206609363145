/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import Grid from '@onehat/ui/src/Components/Grid/Grid.js';
import EqManufacturersGridColumns from './Columns/EqManufacturersGridColumns.js';

export default function EqManufacturersGrid(props) {
	return <Grid
				reference="EqManufacturersGrid"
				model="EqManufacturers"
				usePermissions={true}
				columnsConfig={EqManufacturersGridColumns}

				{...props}
			/>;
}