/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import Grid from '@onehat/ui/src/Components/Grid/Grid.js';
import EquipmentGridColumns from './Columns/EquipmentGridColumns.js';
import EquipmentGridDefaultHiddenColumns from './Columns/EquipmentGridDefaultHiddenColumns.js';

export default function EquipmentGrid(props) {
	return <Grid
				reference="EquipmentGrid"
				model="Equipment"
				usePermissions={true}
				columnsConfig={EquipmentGridColumns}
				defaultHiddenColumns={EquipmentGridDefaultHiddenColumns}

				{...props}
			/>;
}