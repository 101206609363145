/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import EqManufacturersInlineGridEditor from './EqManufacturersInlineGridEditor.js';

export default function EqManufacturersFilteredInlineGridEditor(props) {
	return <EqManufacturersInlineGridEditor
				reference="EqManufacturersFilteredInlineGridEditor"
				useFilters={true}

				{...props}
			/>;
}