/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { SideGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import ChecklistTypesEditor from '../Editor/ChecklistTypesEditor.js';
import ChecklistTypesGridColumns from './Columns/ChecklistTypesGridColumns.js';

export default function ChecklistTypesSideGridEditor(props) {
	return <SideGridEditor
				reference="ChecklistTypesSideGridEditor"
				model="ChecklistTypes"
				usePermissions={true}
				isCollapsible={false}
				Editor={ChecklistTypesEditor}
				columnsConfig={ChecklistTypesGridColumns}
				
				disableAdd={true}
				disableEdit={true}
				disableDelete={true}
				{...props}
			/>;
}