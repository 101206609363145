/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import Grid from '@onehat/ui/src/Components/Grid/Grid.js';
import ChecklistsEquipmentGridColumnsSideB from './Columns/ChecklistsEquipmentGridColumnsSideB.js';

export default function ChecklistsEquipmentGridSideB(props) {
	return <Grid
				reference="ChecklistsEquipmentGridSideB"
				model="ChecklistsEquipment"
				usePermissions={true}
				columnsConfig={ChecklistsEquipmentGridColumnsSideB}
				canColumnsReorder={false}
				canColumnsResize={false}

				{...props}
			/>;
}