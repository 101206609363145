/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import {
	EDITOR_TYPE__SIDE,
} from '@onehat/ui/src/Constants/Editor.js';
import EqTypesEditor from '../../Editor/EqTypesEditor.js';
import EqTypesGridColumns from '../../Grid/Columns/EqTypesGridColumns.js';

export default function EqTypesSideGridEditorPanel(props) {
	return <GridPanel
				reference="EqTypesSideGridEditorPanel"
				model="EqTypes"
				usePermissions={true}
				isCollapsible={false}
				isEditor={true}
				editorType={EDITOR_TYPE__SIDE}
				Editor={EqTypesEditor}
				columnsConfig={EqTypesGridColumns}
				
				
				{...props}
			/>;
}