/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import ChecklistsEquipmentGridPanel from './ChecklistsEquipmentGrid.js';

export default function ChecklistsEquipmentFilteredGridPanel(props) {
	return <ChecklistsEquipmentGridPanel
				reference="ChecklistsEquipmentFilteredGridPanel"
				useFilters={true}

				{...props}
			/>;
}