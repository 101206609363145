import * as yup from 'yup'; // https://github.com/jquense/yup#string

const Permissions = {
	
	name: 'Permissions',
	
	model: {

		idProperty: 'permissions__id',
		displayProperty: 'permissions__id',
		sortProperty: 'permissions__id',
		
		sorters: null,

		validator: yup.object({
			permissions__view_reports: yup.boolean().nullable(),
			permissions__view_dashboard: yup.boolean().nullable(),
			permissions__email_inspections: yup.boolean().nullable(),
			permissions__do_inspections: yup.boolean().nullable(),
			permissions__view_attachments: yup.boolean().nullable(),
			permissions__add_attachments: yup.boolean().nullable(),
			permissions__edit_attachments: yup.boolean().nullable(),
			permissions__delete_attachments: yup.boolean().nullable(),
			permissions__view_checklists: yup.boolean().nullable(),
			permissions__add_checklists: yup.boolean().nullable(),
			permissions__edit_checklists: yup.boolean().nullable(),
			permissions__delete_checklists: yup.boolean().nullable(),
			permissions__view_checklists_equipment: yup.boolean().nullable(),
			permissions__add_checklists_equipment: yup.boolean().nullable(),
			permissions__edit_checklists_equipment: yup.boolean().nullable(),
			permissions__delete_checklists_equipment: yup.boolean().nullable(),
			permissions__view_checklists_groups: yup.boolean().nullable(),
			permissions__add_checklists_groups: yup.boolean().nullable(),
			permissions__edit_checklists_groups: yup.boolean().nullable(),
			permissions__delete_checklists_groups: yup.boolean().nullable(),
			permissions__view_checklist_types: yup.boolean().nullable(),
			permissions__add_checklist_types: yup.boolean().nullable(),
			permissions__edit_checklist_types: yup.boolean().nullable(),
			permissions__delete_checklist_types: yup.boolean().nullable(),
			permissions__view_customers: yup.boolean().nullable(),
			permissions__add_customers: yup.boolean().nullable(),
			permissions__edit_customers: yup.boolean().nullable(),
			permissions__delete_customers: yup.boolean().nullable(),
			permissions__view_equipment: yup.boolean().nullable(),
			permissions__add_equipment: yup.boolean().nullable(),
			permissions__edit_equipment: yup.boolean().nullable(),
			permissions__delete_equipment: yup.boolean().nullable(),
			permissions__view_equipment_parts: yup.boolean().nullable(),
			permissions__add_equipment_parts: yup.boolean().nullable(),
			permissions__edit_equipment_parts: yup.boolean().nullable(),
			permissions__delete_equipment_parts: yup.boolean().nullable(),
			permissions__view_equipment_pm_schedules: yup.boolean().nullable(),
			permissions__add_equipment_pm_schedules: yup.boolean().nullable(),
			permissions__edit_equipment_pm_schedules: yup.boolean().nullable(),
			permissions__delete_equipment_pm_schedules: yup.boolean().nullable(),
			permissions__view_equipment_work_orders: yup.boolean().nullable(),
			permissions__add_equipment_work_orders: yup.boolean().nullable(),
			permissions__edit_equipment_work_orders: yup.boolean().nullable(),
			permissions__delete_equipment_work_orders: yup.boolean().nullable(),
			permissions__view_eq_engine_models: yup.boolean().nullable(),
			permissions__add_eq_engine_models: yup.boolean().nullable(),
			permissions__edit_eq_engine_models: yup.boolean().nullable(),
			permissions__delete_eq_engine_models: yup.boolean().nullable(),
			permissions__view_eq_manufacturers: yup.boolean().nullable(),
			permissions__add_eq_manufacturers: yup.boolean().nullable(),
			permissions__edit_eq_manufacturers: yup.boolean().nullable(),
			permissions__delete_eq_manufacturers: yup.boolean().nullable(),
			permissions__view_eq_statuses: yup.boolean().nullable(),
			permissions__add_eq_statuses: yup.boolean().nullable(),
			permissions__edit_eq_statuses: yup.boolean().nullable(),
			permissions__delete_eq_statuses: yup.boolean().nullable(),
			permissions__view_eq_types: yup.boolean().nullable(),
			permissions__add_eq_types: yup.boolean().nullable(),
			permissions__edit_eq_types: yup.boolean().nullable(),
			permissions__delete_eq_types: yup.boolean().nullable(),
			permissions__view_groups: yup.boolean().nullable(),
			permissions__add_groups: yup.boolean().nullable(),
			permissions__edit_groups: yup.boolean().nullable(),
			permissions__delete_groups: yup.boolean().nullable(),
			permissions__view_groups_users: yup.boolean().nullable(),
			permissions__add_groups_users: yup.boolean().nullable(),
			permissions__edit_groups_users: yup.boolean().nullable(),
			permissions__delete_groups_users: yup.boolean().nullable(),
			permissions__view_inspections: yup.boolean().nullable(),
			permissions__add_inspections: yup.boolean().nullable(),
			permissions__edit_inspections: yup.boolean().nullable(),
			permissions__delete_inspections: yup.boolean().nullable(),
			permissions__view_parts_parts: yup.boolean().nullable(),
			permissions__add_parts_parts: yup.boolean().nullable(),
			permissions__edit_parts_parts: yup.boolean().nullable(),
			permissions__delete_parts_parts: yup.boolean().nullable(),
			permissions__view_labors: yup.boolean().nullable(),
			permissions__add_labors: yup.boolean().nullable(),
			permissions__edit_labors: yup.boolean().nullable(),
			permissions__delete_labors: yup.boolean().nullable(),
			permissions__view_labor_types: yup.boolean().nullable(),
			permissions__add_labor_types: yup.boolean().nullable(),
			permissions__edit_labor_types: yup.boolean().nullable(),
			permissions__delete_labor_types: yup.boolean().nullable(),
			permissions__view_locations: yup.boolean().nullable(),
			permissions__add_locations: yup.boolean().nullable(),
			permissions__edit_locations: yup.boolean().nullable(),
			permissions__delete_locations: yup.boolean().nullable(),
			permissions__view_meter_readings: yup.boolean().nullable(),
			permissions__add_meter_readings: yup.boolean().nullable(),
			permissions__edit_meter_readings: yup.boolean().nullable(),
			permissions__delete_meter_readings: yup.boolean().nullable(),
			permissions__view_meter_sources: yup.boolean().nullable(),
			permissions__add_meter_sources: yup.boolean().nullable(),
			permissions__edit_meter_sources: yup.boolean().nullable(),
			permissions__delete_meter_sources: yup.boolean().nullable(),
			permissions__view_other_charges: yup.boolean().nullable(),
			permissions__add_other_charges: yup.boolean().nullable(),
			permissions__edit_other_charges: yup.boolean().nullable(),
			permissions__delete_other_charges: yup.boolean().nullable(),
			permissions__view_parts: yup.boolean().nullable(),
			permissions__add_parts: yup.boolean().nullable(),
			permissions__edit_parts: yup.boolean().nullable(),
			permissions__delete_parts: yup.boolean().nullable(),
			permissions__view_parts_part_types: yup.boolean().nullable(),
			permissions__delete_parts_part_types: yup.boolean().nullable(),
			permissions__edit_parts_part_types: yup.boolean().nullable(),
			permissions__add_parts_part_types: yup.boolean().nullable(),
			permissions__view_parts_work_orders: yup.boolean().nullable(),
			permissions__add_parts_work_orders: yup.boolean().nullable(),
			permissions__edit_parts_work_orders: yup.boolean().nullable(),
			permissions__delete_parts_work_orders: yup.boolean().nullable(),
			permissions__view_part_manufacturers: yup.boolean().nullable(),
			permissions__add_part_manufacturers: yup.boolean().nullable(),
			permissions__edit_part_manufacturers: yup.boolean().nullable(),
			permissions__delete_part_manufacturers: yup.boolean().nullable(),
			permissions__view_part_sources: yup.boolean().nullable(),
			permissions__add_part_sources: yup.boolean().nullable(),
			permissions__edit_part_sources: yup.boolean().nullable(),
			permissions__delete_part_sources: yup.boolean().nullable(),
			permissions__view_part_types: yup.boolean().nullable(),
			permissions__add_part_types: yup.boolean().nullable(),
			permissions__edit_part_types: yup.boolean().nullable(),
			permissions__delete_part_types: yup.boolean().nullable(),
			permissions__view_permissions: yup.boolean().nullable(),
			permissions__add_permissions: yup.boolean().nullable(),
			permissions__edit_permissions: yup.boolean().nullable(),
			permissions__delete_permissions: yup.boolean().nullable(),
			permissions__view_pm_events: yup.boolean().nullable(),
			permissions__add_pm_events: yup.boolean().nullable(),
			permissions__edit_pm_events: yup.boolean().nullable(),
			permissions__delete_pm_events: yup.boolean().nullable(),
			permissions__view_pm_event_types: yup.boolean().nullable(),
			permissions__add_pm_event_types: yup.boolean().nullable(),
			permissions__edit_pm_event_types: yup.boolean().nullable(),
			permissions__delete_pm_event_types: yup.boolean().nullable(),
			permissions__view_pm_schedules: yup.boolean().nullable(),
			permissions__add_pm_schedules: yup.boolean().nullable(),
			permissions__edit_pm_schedules: yup.boolean().nullable(),
			permissions__delete_pm_schedules: yup.boolean().nullable(),
			permissions__view_pm_schedule_modes: yup.boolean().nullable(),
			permissions__add_pm_schedule_modes: yup.boolean().nullable(),
			permissions__edit_pm_schedule_modes: yup.boolean().nullable(),
			permissions__delete_pm_schedule_modes: yup.boolean().nullable(),
			permissions__view_pm_statuses: yup.boolean().nullable(),
			permissions__add_pm_statuses: yup.boolean().nullable(),
			permissions__edit_pm_statuses: yup.boolean().nullable(),
			permissions__delete_pm_statuses: yup.boolean().nullable(),
			permissions__view_pm_technicians: yup.boolean().nullable(),
			permissions__add_pm_technicians: yup.boolean().nullable(),
			permissions__edit_pm_technicians: yup.boolean().nullable(),
			permissions__delete_pm_technicians: yup.boolean().nullable(),
			permissions__view_questions: yup.boolean().nullable(),
			permissions__add_questions: yup.boolean().nullable(),
			permissions__edit_questions: yup.boolean().nullable(),
			permissions__delete_questions: yup.boolean().nullable(),
			permissions__view_question_groups: yup.boolean().nullable(),
			permissions__add_question_groups: yup.boolean().nullable(),
			permissions__edit_question_groups: yup.boolean().nullable(),
			permissions__delete_question_groups: yup.boolean().nullable(),
			permissions__view_responses: yup.boolean().nullable(),
			permissions__add_responses: yup.boolean().nullable(),
			permissions__edit_responses: yup.boolean().nullable(),
			permissions__delete_responses: yup.boolean().nullable(),
			permissions__view_users: yup.boolean().nullable(),
			permissions__add_users: yup.boolean().nullable(),
			permissions__edit_users: yup.boolean().nullable(),
			permissions__delete_users: yup.boolean().nullable(),
			permissions__view_work_orders: yup.boolean().nullable(),
			permissions__add_work_orders: yup.boolean().nullable(),
			permissions__edit_work_orders: yup.boolean().nullable(),
			permissions__delete_work_orders: yup.boolean().nullable(),
			permissions__view_wo_classes: yup.boolean().nullable(),
			permissions__add_wo_classes: yup.boolean().nullable(),
			permissions__edit_wo_classes: yup.boolean().nullable(),
			permissions__delete_wo_classes: yup.boolean().nullable(),
			permissions__view_wo_priorities: yup.boolean().nullable(),
			permissions__add_wo_priorities: yup.boolean().nullable(),
			permissions__edit_wo_priorities: yup.boolean().nullable(),
			permissions__delete_wo_priorities: yup.boolean().nullable()
		}),
		
		properties: [
			{ name: 'permissions__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'permissions__user_id', mapping: 'user_id', title: 'User', type: 'int', isFk: true, fkIdField: 'users__id', fkDisplayField: 'users__username', filterType: {"type":"UsersCombo","loadAfterRender":!1}, editorType: {"type":"UsersCombo","loadAfterRender":!1}, fieldGroup: 'General', },
			{ name: 'permissions__group_id', mapping: 'group_id', title: 'Group', type: 'int', isFk: true, fkIdField: 'groups__id', fkDisplayField: 'groups__name', filterType: {"type":"GroupsCombo","loadAfterRender":!1}, editorType: {"type":"GroupsComboEditor","loadAfterRender":!1}, fieldGroup: 'General', },
			{ name: 'permissions__view_reports', mapping: 'view_reports', title: 'View Reports', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '7. Custom Permissions', },
			{ name: 'permissions__view_dashboard', mapping: 'view_dashboard', title: 'View Dashboard', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '7. Custom Permissions', },
			{ name: 'permissions__email_inspections', mapping: 'email_inspections', title: 'Email Inspections', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '7. Custom Permissions', },
			{ name: 'permissions__do_inspections', mapping: 'do_inspections', title: 'Do Inspections', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '7. Custom Permissions', },
			{ name: 'permissions__view_attachments', mapping: 'view_attachments', title: 'View Attachments', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '1. Attachments', },
			{ name: 'permissions__add_attachments', mapping: 'add_attachments', title: 'Add Attachments', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '1. Attachments', },
			{ name: 'permissions__edit_attachments', mapping: 'edit_attachments', title: 'Edit Attachments', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '1. Attachments', },
			{ name: 'permissions__delete_attachments', mapping: 'delete_attachments', title: 'Delete Attachments', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '1. Attachments', },
			{ name: 'permissions__view_checklists', mapping: 'view_checklists', title: 'View Checklists', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '2. Checklists', },
			{ name: 'permissions__add_checklists', mapping: 'add_checklists', title: 'Add Checklists', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '2. Checklists', },
			{ name: 'permissions__edit_checklists', mapping: 'edit_checklists', title: 'Edit Checklists', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '2. Checklists', },
			{ name: 'permissions__delete_checklists', mapping: 'delete_checklists', title: 'Delete Checklists', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '2. Checklists', },
			{ name: 'permissions__view_checklists_equipment', mapping: 'view_checklists_equipment', title: 'View Checklists Equipment', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '3. Checklists to Equipment', },
			{ name: 'permissions__add_checklists_equipment', mapping: 'add_checklists_equipment', title: 'Add Checklists Equipment', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '3. Checklists to Equipment', },
			{ name: 'permissions__edit_checklists_equipment', mapping: 'edit_checklists_equipment', title: 'Edit Checklists Equipment', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '3. Checklists to Equipment', },
			{ name: 'permissions__delete_checklists_equipment', mapping: 'delete_checklists_equipment', title: 'Delete Checklists Equipment', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '3. Checklists to Equipment', },
			{ name: 'permissions__view_checklists_groups', mapping: 'view_checklists_groups', title: 'View Checklists Groups', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '4. Checklists to Groups', },
			{ name: 'permissions__add_checklists_groups', mapping: 'add_checklists_groups', title: 'Add Checklists Groups', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '4. Checklists to Groups', },
			{ name: 'permissions__edit_checklists_groups', mapping: 'edit_checklists_groups', title: 'Edit Checklists Groups', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '4. Checklists to Groups', },
			{ name: 'permissions__delete_checklists_groups', mapping: 'delete_checklists_groups', title: 'Delete Checklists Groups', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '4. Checklists to Groups', },
			{ name: 'permissions__view_checklist_types', mapping: 'view_checklist_types', title: 'View Checklist Types', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '5. Checklist Types', },
			{ name: 'permissions__add_checklist_types', mapping: 'add_checklist_types', title: 'Add Checklist Types', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '5. Checklist Types', },
			{ name: 'permissions__edit_checklist_types', mapping: 'edit_checklist_types', title: 'Edit Checklist Types', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '5. Checklist Types', },
			{ name: 'permissions__delete_checklist_types', mapping: 'delete_checklist_types', title: 'Delete Checklist Types', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '5. Checklist Types', },
			{ name: 'permissions__view_customers', mapping: 'view_customers', title: 'View Customers', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '6. Customers', },
			{ name: 'permissions__add_customers', mapping: 'add_customers', title: 'Add Customers', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '6. Customers', },
			{ name: 'permissions__edit_customers', mapping: 'edit_customers', title: 'Edit Customers', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '6. Customers', },
			{ name: 'permissions__delete_customers', mapping: 'delete_customers', title: 'Delete Customers', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '6. Customers', },
			{ name: 'permissions__view_equipment', mapping: 'view_equipment', title: 'View Equipment', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '12. Equipment', },
			{ name: 'permissions__add_equipment', mapping: 'add_equipment', title: 'Add Equipment', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '12. Equipment', },
			{ name: 'permissions__edit_equipment', mapping: 'edit_equipment', title: 'Edit Equipment', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '12. Equipment', },
			{ name: 'permissions__delete_equipment', mapping: 'delete_equipment', title: 'Delete Equipment', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '12. Equipment', },
			{ name: 'permissions__view_equipment_parts', mapping: 'view_equipment_parts', title: 'View Equipment Parts', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '13. Equipment to Parts', },
			{ name: 'permissions__add_equipment_parts', mapping: 'add_equipment_parts', title: 'Add Equipment Parts', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '13. Equipment to Parts', },
			{ name: 'permissions__edit_equipment_parts', mapping: 'edit_equipment_parts', title: 'Edit Equipment Parts', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '13. Equipment to Parts', },
			{ name: 'permissions__delete_equipment_parts', mapping: 'delete_equipment_parts', title: 'Delete Equipment Parts', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '13. Equipment to Parts', },
			{ name: 'permissions__view_equipment_pm_schedules', mapping: 'view_equipment_pm_schedules', title: 'View Equipment Pm Schedules', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '14. Equipment to PM Schedules', },
			{ name: 'permissions__add_equipment_pm_schedules', mapping: 'add_equipment_pm_schedules', title: 'Add Equipment Pm Schedules', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '14. Equipment to PM Schedules', },
			{ name: 'permissions__edit_equipment_pm_schedules', mapping: 'edit_equipment_pm_schedules', title: 'Edit Equipment Pm Schedules', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '14. Equipment to PM Schedules', },
			{ name: 'permissions__delete_equipment_pm_schedules', mapping: 'delete_equipment_pm_schedules', title: 'Delete Equipment Pm Schedules', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '14. Equipment to PM Schedules', },
			{ name: 'permissions__view_equipment_work_orders', mapping: 'view_equipment_work_orders', title: 'View Equipment Work Orders', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '15. Equipment to Work Orders', },
			{ name: 'permissions__add_equipment_work_orders', mapping: 'add_equipment_work_orders', title: 'Add Equipment Work Orders', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '15. Equipment to Work Orders', },
			{ name: 'permissions__edit_equipment_work_orders', mapping: 'edit_equipment_work_orders', title: 'Edit Equipment Work Orders', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '15. Equipment to Work Orders', },
			{ name: 'permissions__delete_equipment_work_orders', mapping: 'delete_equipment_work_orders', title: 'Delete Equipment Work Orders', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '15. Equipment to Work Orders', },
			{ name: 'permissions__view_eq_engine_models', mapping: 'view_eq_engine_models', title: 'View Eq Engine Models', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '8. Eq Engine Models', },
			{ name: 'permissions__add_eq_engine_models', mapping: 'add_eq_engine_models', title: 'Add Eq Engine Models', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '8. Eq Engine Models', },
			{ name: 'permissions__edit_eq_engine_models', mapping: 'edit_eq_engine_models', title: 'Edit Eq Engine Models', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '8. Eq Engine Models', },
			{ name: 'permissions__delete_eq_engine_models', mapping: 'delete_eq_engine_models', title: 'Delete Eq Engine Models', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '8. Eq Engine Models', },
			{ name: 'permissions__view_eq_manufacturers', mapping: 'view_eq_manufacturers', title: 'View Eq Manufacturers', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '9. Eq Manufacturers', },
			{ name: 'permissions__add_eq_manufacturers', mapping: 'add_eq_manufacturers', title: 'Add Eq Manufacturers', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '9. Eq Manufacturers', },
			{ name: 'permissions__edit_eq_manufacturers', mapping: 'edit_eq_manufacturers', title: 'Edit Eq Manufacturers', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '9. Eq Manufacturers', },
			{ name: 'permissions__delete_eq_manufacturers', mapping: 'delete_eq_manufacturers', title: 'Delete Eq Manufacturers', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '9. Eq Manufacturers', },
			{ name: 'permissions__view_eq_statuses', mapping: 'view_eq_statuses', title: 'View Eq Statuses', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '10. Eq Statuses', },
			{ name: 'permissions__add_eq_statuses', mapping: 'add_eq_statuses', title: 'Add Eq Statuses', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '10. Eq Statuses', },
			{ name: 'permissions__edit_eq_statuses', mapping: 'edit_eq_statuses', title: 'Edit Eq Statuses', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '10. Eq Statuses', },
			{ name: 'permissions__delete_eq_statuses', mapping: 'delete_eq_statuses', title: 'Delete Eq Statuses', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '10. Eq Statuses', },
			{ name: 'permissions__view_eq_types', mapping: 'view_eq_types', title: 'View Eq Types', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '11. Eq Types', },
			{ name: 'permissions__add_eq_types', mapping: 'add_eq_types', title: 'Add Eq Types', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '11. Eq Types', },
			{ name: 'permissions__edit_eq_types', mapping: 'edit_eq_types', title: 'Edit Eq Types', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '11. Eq Types', },
			{ name: 'permissions__delete_eq_types', mapping: 'delete_eq_types', title: 'Delete Eq Types', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '11. Eq Types', },
			{ name: 'permissions__view_groups', mapping: 'view_groups', title: 'View Groups', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '16. Groups', },
			{ name: 'permissions__add_groups', mapping: 'add_groups', title: 'Add Groups', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '16. Groups', },
			{ name: 'permissions__edit_groups', mapping: 'edit_groups', title: 'Edit Groups', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '16. Groups', },
			{ name: 'permissions__delete_groups', mapping: 'delete_groups', title: 'Delete Groups', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '16. Groups', },
			{ name: 'permissions__view_groups_users', mapping: 'view_groups_users', title: 'View Groups Users', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '17. Groups to Users Links', },
			{ name: 'permissions__add_groups_users', mapping: 'add_groups_users', title: 'Add Groups Users', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '17. Groups to Users Links', },
			{ name: 'permissions__edit_groups_users', mapping: 'edit_groups_users', title: 'Edit Groups Users', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '17. Groups to Users Links', },
			{ name: 'permissions__delete_groups_users', mapping: 'delete_groups_users', title: 'Delete Groups Users', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '17. Groups to Users Links', },
			{ name: 'permissions__view_inspections', mapping: 'view_inspections', title: 'View Inspections', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '18. Inspections', },
			{ name: 'permissions__add_inspections', mapping: 'add_inspections', title: 'Add Inspections', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '18. Inspections', },
			{ name: 'permissions__edit_inspections', mapping: 'edit_inspections', title: 'Edit Inspections', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '18. Inspections', },
			{ name: 'permissions__delete_inspections', mapping: 'delete_inspections', title: 'Delete Inspections', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '18. Inspections', },
			{ name: 'permissions__view_parts_parts', mapping: 'view_parts_parts', title: 'View Interchangeable Parts', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '30. Parts to Interchangeables', },
			{ name: 'permissions__add_parts_parts', mapping: 'add_parts_parts', title: 'Add Interchangeable Parts', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '30. Parts to Interchangeables', },
			{ name: 'permissions__edit_parts_parts', mapping: 'edit_parts_parts', title: 'Edit Interchangeable Parts', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '30. Parts to Interchangeables', },
			{ name: 'permissions__delete_parts_parts', mapping: 'delete_parts_parts', title: 'Delete Interchangeable Parts', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '30. Parts to Interchangeables', },
			{ name: 'permissions__view_labors', mapping: 'view_labors', title: 'View Labors', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '19. Labors', },
			{ name: 'permissions__add_labors', mapping: 'add_labors', title: 'Add Labors', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '19. Labors', },
			{ name: 'permissions__edit_labors', mapping: 'edit_labors', title: 'Edit Labors', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '19. Labors', },
			{ name: 'permissions__delete_labors', mapping: 'delete_labors', title: 'Delete Labors', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '19. Labors', },
			{ name: 'permissions__view_labor_types', mapping: 'view_labor_types', title: 'View Labor Types', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '20. Labor Types', },
			{ name: 'permissions__add_labor_types', mapping: 'add_labor_types', title: 'Add Labor Types', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '20. Labor Types', },
			{ name: 'permissions__edit_labor_types', mapping: 'edit_labor_types', title: 'Edit Labor Types', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '20. Labor Types', },
			{ name: 'permissions__delete_labor_types', mapping: 'delete_labor_types', title: 'Delete Labor Types', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '20. Labor Types', },
			{ name: 'permissions__view_locations', mapping: 'view_locations', title: 'View Locations', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '22. Locations', },
			{ name: 'permissions__add_locations', mapping: 'add_locations', title: 'Add Locations', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '22. Locations', },
			{ name: 'permissions__edit_locations', mapping: 'edit_locations', title: 'Edit Locations', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '22. Locations', },
			{ name: 'permissions__delete_locations', mapping: 'delete_locations', title: 'Delete Locations', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '22. Locations', },
			{ name: 'permissions__view_meter_readings', mapping: 'view_meter_readings', title: 'View Meter Readings', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '23. Meter Readings', },
			{ name: 'permissions__add_meter_readings', mapping: 'add_meter_readings', title: 'Add Meter Readings', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '23. Meter Readings', },
			{ name: 'permissions__edit_meter_readings', mapping: 'edit_meter_readings', title: 'Edit Meter Readings', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '23. Meter Readings', },
			{ name: 'permissions__delete_meter_readings', mapping: 'delete_meter_readings', title: 'Delete Meter Readings', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '23. Meter Readings', },
			{ name: 'permissions__view_meter_sources', mapping: 'view_meter_sources', title: 'View Meter Sources', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '24. Meter Sources', },
			{ name: 'permissions__add_meter_sources', mapping: 'add_meter_sources', title: 'Add Meter Sources', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '24. Meter Sources', },
			{ name: 'permissions__edit_meter_sources', mapping: 'edit_meter_sources', title: 'Edit Meter Sources', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '24. Meter Sources', },
			{ name: 'permissions__delete_meter_sources', mapping: 'delete_meter_sources', title: 'Delete Meter Sources', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '24. Meter Sources', },
			{ name: 'permissions__view_other_charges', mapping: 'view_other_charges', title: 'View Other Charges', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '25. OtherCharges', },
			{ name: 'permissions__add_other_charges', mapping: 'add_other_charges', title: 'Add Other Charges', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '25. OtherCharges', },
			{ name: 'permissions__edit_other_charges', mapping: 'edit_other_charges', title: 'Edit Other Charges', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '25. OtherCharges', },
			{ name: 'permissions__delete_other_charges', mapping: 'delete_other_charges', title: 'Delete Other Charges', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '25. OtherCharges', },
			{ name: 'permissions__view_parts', mapping: 'view_parts', title: 'View Parts', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '27. Parts', },
			{ name: 'permissions__add_parts', mapping: 'add_parts', title: 'Add Parts', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '27. Parts', },
			{ name: 'permissions__edit_parts', mapping: 'edit_parts', title: 'Edit Parts', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '27. Parts', },
			{ name: 'permissions__delete_parts', mapping: 'delete_parts', title: 'Delete Parts', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '27. Parts', },
			{ name: 'permissions__view_parts_part_types', mapping: 'view_parts_part_types', title: 'View Parts Part Types', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '31. Parts to Part Types', },
			{ name: 'permissions__delete_parts_part_types', mapping: 'delete_parts_part_types', title: 'Delete Parts Part Types', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '31. Parts to Part Types', },
			{ name: 'permissions__edit_parts_part_types', mapping: 'edit_parts_part_types', title: 'Edit Parts Part Types', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '31. Parts to Part Types', },
			{ name: 'permissions__add_parts_part_types', mapping: 'add_parts_part_types', title: 'Add Parts Part Types', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '31. Parts to Part Types', },
			{ name: 'permissions__view_parts_work_orders', mapping: 'view_parts_work_orders', title: 'View Parts Work Orders', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '32. Parts to Work Orders', },
			{ name: 'permissions__add_parts_work_orders', mapping: 'add_parts_work_orders', title: 'Add Parts Work Orders', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '32. Parts to Work Orders', },
			{ name: 'permissions__edit_parts_work_orders', mapping: 'edit_parts_work_orders', title: 'Edit Parts Work Orders', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '32. Parts to Work Orders', },
			{ name: 'permissions__delete_parts_work_orders', mapping: 'delete_parts_work_orders', title: 'Delete Parts Work Orders', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '32. Parts to Work Orders', },
			{ name: 'permissions__view_part_manufacturers', mapping: 'view_part_manufacturers', title: 'View Part Manufacturers', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '26. Part Manufacturers', },
			{ name: 'permissions__add_part_manufacturers', mapping: 'add_part_manufacturers', title: 'Add Part Manufacturers', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '26. Part Manufacturers', },
			{ name: 'permissions__edit_part_manufacturers', mapping: 'edit_part_manufacturers', title: 'Edit Part Manufacturers', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '26. Part Manufacturers', },
			{ name: 'permissions__delete_part_manufacturers', mapping: 'delete_part_manufacturers', title: 'Delete Part Manufacturers', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '26. Part Manufacturers', },
			{ name: 'permissions__view_part_sources', mapping: 'view_part_sources', title: 'View Part Sources', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '28. Part Sources', },
			{ name: 'permissions__add_part_sources', mapping: 'add_part_sources', title: 'Add Part Sources', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '28. Part Sources', },
			{ name: 'permissions__edit_part_sources', mapping: 'edit_part_sources', title: 'Edit Part Sources', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '28. Part Sources', },
			{ name: 'permissions__delete_part_sources', mapping: 'delete_part_sources', title: 'Delete Part Sources', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '28. Part Sources', },
			{ name: 'permissions__view_part_types', mapping: 'view_part_types', title: 'View Part Types', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '29. Part Types', },
			{ name: 'permissions__add_part_types', mapping: 'add_part_types', title: 'Add Part Types', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '29. Part Types', },
			{ name: 'permissions__edit_part_types', mapping: 'edit_part_types', title: 'Edit Part Types', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '29. Part Types', },
			{ name: 'permissions__delete_part_types', mapping: 'delete_part_types', title: 'Delete Part Types', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '29. Part Types', },
			{ name: 'permissions__view_permissions', mapping: 'view_permissions', title: 'View Permissions', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '33. Permissions', },
			{ name: 'permissions__add_permissions', mapping: 'add_permissions', title: 'Add Permissions', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '33. Permissions', },
			{ name: 'permissions__edit_permissions', mapping: 'edit_permissions', title: 'Edit Permissions', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '33. Permissions', },
			{ name: 'permissions__delete_permissions', mapping: 'delete_permissions', title: 'Delete Permissions', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '33. Permissions', },
			{ name: 'permissions__view_pm_events', mapping: 'view_pm_events', title: 'View Pm Events', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '34. PM Events', },
			{ name: 'permissions__add_pm_events', mapping: 'add_pm_events', title: 'Add Pm Events', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '34. PM Events', },
			{ name: 'permissions__edit_pm_events', mapping: 'edit_pm_events', title: 'Edit Pm Events', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '34. PM Events', },
			{ name: 'permissions__delete_pm_events', mapping: 'delete_pm_events', title: 'Delete Pm Events', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '34. PM Events', },
			{ name: 'permissions__view_pm_event_types', mapping: 'view_pm_event_types', title: 'View Pm Event Types', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '35. PM Event Types', },
			{ name: 'permissions__add_pm_event_types', mapping: 'add_pm_event_types', title: 'Add Pm Event Types', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '35. PM Event Types', },
			{ name: 'permissions__edit_pm_event_types', mapping: 'edit_pm_event_types', title: 'Edit Pm Event Types', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '35. PM Event Types', },
			{ name: 'permissions__delete_pm_event_types', mapping: 'delete_pm_event_types', title: 'Delete Pm Event Types', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '35. PM Event Types', },
			{ name: 'permissions__view_pm_schedules', mapping: 'view_pm_schedules', title: 'View Pm Schedules', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '37. PM Schedules', },
			{ name: 'permissions__add_pm_schedules', mapping: 'add_pm_schedules', title: 'Add Pm Schedules', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '37. PM Schedules', },
			{ name: 'permissions__edit_pm_schedules', mapping: 'edit_pm_schedules', title: 'Edit Pm Schedules', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '37. PM Schedules', },
			{ name: 'permissions__delete_pm_schedules', mapping: 'delete_pm_schedules', title: 'Delete Pm Schedules', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '37. PM Schedules', },
			{ name: 'permissions__view_pm_schedule_modes', mapping: 'view_pm_schedule_modes', title: 'View Pm Schedule Modes', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '36. PM Schedule Modes', },
			{ name: 'permissions__add_pm_schedule_modes', mapping: 'add_pm_schedule_modes', title: 'Add Pm Schedule Modes', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '36. PM Schedule Modes', },
			{ name: 'permissions__edit_pm_schedule_modes', mapping: 'edit_pm_schedule_modes', title: 'Edit Pm Schedule Modes', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '36. PM Schedule Modes', },
			{ name: 'permissions__delete_pm_schedule_modes', mapping: 'delete_pm_schedule_modes', title: 'Delete Pm Schedule Modes', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '36. PM Schedule Modes', },
			{ name: 'permissions__view_pm_statuses', mapping: 'view_pm_statuses', title: 'View Pm Statuses', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '38. PM Statuses', },
			{ name: 'permissions__add_pm_statuses', mapping: 'add_pm_statuses', title: 'Add Pm Statuses', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '38. PM Statuses', },
			{ name: 'permissions__edit_pm_statuses', mapping: 'edit_pm_statuses', title: 'Edit Pm Statuses', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '38. PM Statuses', },
			{ name: 'permissions__delete_pm_statuses', mapping: 'delete_pm_statuses', title: 'Delete Pm Statuses', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '38. PM Statuses', },
			{ name: 'permissions__view_pm_technicians', mapping: 'view_pm_technicians', title: 'View Pm Technicians', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '39. PM Technicians', },
			{ name: 'permissions__add_pm_technicians', mapping: 'add_pm_technicians', title: 'Add Pm Technicians', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '39. PM Technicians', },
			{ name: 'permissions__edit_pm_technicians', mapping: 'edit_pm_technicians', title: 'Edit Pm Technicians', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '39. PM Technicians', },
			{ name: 'permissions__delete_pm_technicians', mapping: 'delete_pm_technicians', title: 'Delete Pm Technicians', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '39. PM Technicians', },
			{ name: 'permissions__view_questions', mapping: 'view_questions', title: 'View Questions', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '40. Questions', },
			{ name: 'permissions__add_questions', mapping: 'add_questions', title: 'Add Questions', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '40. Questions', },
			{ name: 'permissions__edit_questions', mapping: 'edit_questions', title: 'Edit Questions', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '40. Questions', },
			{ name: 'permissions__delete_questions', mapping: 'delete_questions', title: 'Delete Questions', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '40. Questions', },
			{ name: 'permissions__view_question_groups', mapping: 'view_question_groups', title: 'View Question Groups', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '41. Question Groups', },
			{ name: 'permissions__add_question_groups', mapping: 'add_question_groups', title: 'Add Question Groups', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '41. Question Groups', },
			{ name: 'permissions__edit_question_groups', mapping: 'edit_question_groups', title: 'Edit Question Groups', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '41. Question Groups', },
			{ name: 'permissions__delete_question_groups', mapping: 'delete_question_groups', title: 'Delete Question Groups', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '41. Question Groups', },
			{ name: 'permissions__view_responses', mapping: 'view_responses', title: 'View Responses', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '42. Responses', },
			{ name: 'permissions__add_responses', mapping: 'add_responses', title: 'Add Responses', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '42. Responses', },
			{ name: 'permissions__edit_responses', mapping: 'edit_responses', title: 'Edit Responses', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '42. Responses', },
			{ name: 'permissions__delete_responses', mapping: 'delete_responses', title: 'Delete Responses', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '42. Responses', },
			{ name: 'permissions__view_users', mapping: 'view_users', title: 'View Users', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '47. Users', },
			{ name: 'permissions__add_users', mapping: 'add_users', title: 'Add Users', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '47. Users', },
			{ name: 'permissions__edit_users', mapping: 'edit_users', title: 'Edit Users', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '47. Users', },
			{ name: 'permissions__delete_users', mapping: 'delete_users', title: 'Delete Users', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '47. Users', },
			{ name: 'permissions__view_work_orders', mapping: 'view_work_orders', title: 'View Work Orders', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '46. WorkOrders', },
			{ name: 'permissions__add_work_orders', mapping: 'add_work_orders', title: 'Add Work Orders', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '46. WorkOrders', },
			{ name: 'permissions__edit_work_orders', mapping: 'edit_work_orders', title: 'Edit Work Orders', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '46. WorkOrders', },
			{ name: 'permissions__delete_work_orders', mapping: 'delete_work_orders', title: 'Delete Work Orders', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '46. WorkOrders', },
			{ name: 'permissions__view_wo_classes', mapping: 'view_wo_classes', title: 'View Wo Classes', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '43. WO Classes', },
			{ name: 'permissions__add_wo_classes', mapping: 'add_wo_classes', title: 'Add Wo Classes', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '43. WO Classes', },
			{ name: 'permissions__edit_wo_classes', mapping: 'edit_wo_classes', title: 'Edit Wo Classes', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '43. WO Classes', },
			{ name: 'permissions__delete_wo_classes', mapping: 'delete_wo_classes', title: 'Delete Wo Classes', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '43. WO Classes', },
			{ name: 'permissions__view_wo_priorities', mapping: 'view_wo_priorities', title: 'View Wo Priorities', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '44. WO Priorities', },
			{ name: 'permissions__add_wo_priorities', mapping: 'add_wo_priorities', title: 'Add Wo Priorities', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '44. WO Priorities', },
			{ name: 'permissions__edit_wo_priorities', mapping: 'edit_wo_priorities', title: 'Edit Wo Priorities', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '44. WO Priorities', },
			{ name: 'permissions__delete_wo_priorities', mapping: 'delete_wo_priorities', title: 'Delete Wo Priorities', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '44. WO Priorities', },
			{ name: 'users__id', mapping: 'user.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__username', mapping: 'user.username', title: 'Username', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__password', mapping: 'user.password', title: 'Password', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__full_name', mapping: 'user.full_name', title: 'Full Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__first_name', mapping: 'user.first_name', title: 'First Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__last_name', mapping: 'user.last_name', title: 'Last Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__email', mapping: 'user.email', title: 'Email', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__job_title', mapping: 'user.job_title', title: 'Job Title', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__groups', mapping: 'user.groups', title: 'Groups', type: 'tag', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__token', mapping: 'user.token', title: 'Token', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__token_generated', mapping: 'user.token_generated', title: 'Token Generated', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__expo_token', mapping: 'user.expo_token', title: 'Expo Token', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__login_count', mapping: 'user.login_count', title: 'Login Count', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__last_login', mapping: 'user.last_login', title: 'Last Login', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__password_reset', mapping: 'user.password_reset', title: 'Password Reset', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'groups__id', mapping: 'group.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'groups__name', mapping: 'group.name', title: 'Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'groups__description', mapping: 'group.description', title: 'Description', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, }
		],

		associations: {
			belongsTo: [
				'Users',
				'Groups'
			],

		},

		ancillaryFilters: [],

		defaultFilters: [
			// 'permissions__user_id',
			// 'permissions__group_id',
			// 'permissions__view_reports',
			// 'permissions__view_dashboard',
			// 'permissions__email_inspections',
			// 'permissions__do_inspections',
			// 'permissions__view_attachments',
			// 'permissions__add_attachments',
			// 'permissions__edit_attachments',
			// 'permissions__delete_attachments',
			// 'permissions__view_checklists',
			// 'permissions__add_checklists',
			// 'permissions__edit_checklists',
			// 'permissions__delete_checklists',
			// 'permissions__view_checklists_equipment',
			// 'permissions__add_checklists_equipment',
			// 'permissions__edit_checklists_equipment',
			// 'permissions__delete_checklists_equipment',
			// 'permissions__view_checklists_groups',
			// 'permissions__add_checklists_groups',
			// 'permissions__edit_checklists_groups',
			// 'permissions__delete_checklists_groups',
			// 'permissions__view_checklist_types',
			// 'permissions__add_checklist_types',
			// 'permissions__edit_checklist_types',
			// 'permissions__delete_checklist_types',
			// 'permissions__view_customers',
			// 'permissions__add_customers',
			// 'permissions__edit_customers',
			// 'permissions__delete_customers',
			// 'permissions__view_equipment',
			// 'permissions__add_equipment',
			// 'permissions__edit_equipment',
			// 'permissions__delete_equipment',
			// 'permissions__view_equipment_parts',
			// 'permissions__add_equipment_parts',
			// 'permissions__edit_equipment_parts',
			// 'permissions__delete_equipment_parts',
			// 'permissions__view_equipment_pm_schedules',
			// 'permissions__add_equipment_pm_schedules',
			// 'permissions__edit_equipment_pm_schedules',
			// 'permissions__delete_equipment_pm_schedules',
			// 'permissions__view_equipment_work_orders',
			// 'permissions__add_equipment_work_orders',
			// 'permissions__edit_equipment_work_orders',
			// 'permissions__delete_equipment_work_orders',
			// 'permissions__view_eq_engine_models',
			// 'permissions__add_eq_engine_models',
			// 'permissions__edit_eq_engine_models',
			// 'permissions__delete_eq_engine_models',
			// 'permissions__view_eq_manufacturers',
			// 'permissions__add_eq_manufacturers',
			// 'permissions__edit_eq_manufacturers',
			// 'permissions__delete_eq_manufacturers',
			// 'permissions__view_eq_statuses',
			// 'permissions__add_eq_statuses',
			// 'permissions__edit_eq_statuses',
			// 'permissions__delete_eq_statuses',
			// 'permissions__view_eq_types',
			// 'permissions__add_eq_types',
			// 'permissions__edit_eq_types',
			// 'permissions__delete_eq_types',
			// 'permissions__view_groups',
			// 'permissions__add_groups',
			// 'permissions__edit_groups',
			// 'permissions__delete_groups',
			// 'permissions__view_groups_users',
			// 'permissions__add_groups_users',
			// 'permissions__edit_groups_users',
			// 'permissions__delete_groups_users',
			// 'permissions__view_inspections',
			// 'permissions__add_inspections',
			// 'permissions__edit_inspections',
			// 'permissions__delete_inspections',
			// 'permissions__view_parts_parts',
			// 'permissions__add_parts_parts',
			// 'permissions__edit_parts_parts',
			// 'permissions__delete_parts_parts',
			// 'permissions__view_labors',
			// 'permissions__add_labors',
			// 'permissions__edit_labors',
			// 'permissions__delete_labors',
			// 'permissions__view_labor_types',
			// 'permissions__add_labor_types',
			// 'permissions__edit_labor_types',
			// 'permissions__delete_labor_types',
			// 'permissions__view_locations',
			// 'permissions__add_locations',
			// 'permissions__edit_locations',
			// 'permissions__delete_locations',
			// 'permissions__view_meter_readings',
			// 'permissions__add_meter_readings',
			// 'permissions__edit_meter_readings',
			// 'permissions__delete_meter_readings',
			// 'permissions__view_meter_sources',
			// 'permissions__add_meter_sources',
			// 'permissions__edit_meter_sources',
			// 'permissions__delete_meter_sources',
			// 'permissions__view_other_charges',
			// 'permissions__add_other_charges',
			// 'permissions__edit_other_charges',
			// 'permissions__delete_other_charges',
			// 'permissions__view_parts',
			// 'permissions__add_parts',
			// 'permissions__edit_parts',
			// 'permissions__delete_parts',
			// 'permissions__view_parts_part_types',
			// 'permissions__delete_parts_part_types',
			// 'permissions__edit_parts_part_types',
			// 'permissions__add_parts_part_types',
			// 'permissions__view_parts_work_orders',
			// 'permissions__add_parts_work_orders',
			// 'permissions__edit_parts_work_orders',
			// 'permissions__delete_parts_work_orders',
			// 'permissions__view_part_manufacturers',
			// 'permissions__add_part_manufacturers',
			// 'permissions__edit_part_manufacturers',
			// 'permissions__delete_part_manufacturers',
			// 'permissions__view_part_sources',
			// 'permissions__add_part_sources',
			// 'permissions__edit_part_sources',
			// 'permissions__delete_part_sources',
			// 'permissions__view_part_types',
			// 'permissions__add_part_types',
			// 'permissions__edit_part_types',
			// 'permissions__delete_part_types',
			// 'permissions__view_permissions',
			// 'permissions__add_permissions',
			// 'permissions__edit_permissions',
			// 'permissions__delete_permissions',
			// 'permissions__view_pm_events',
			// 'permissions__add_pm_events',
			// 'permissions__edit_pm_events',
			// 'permissions__delete_pm_events',
			// 'permissions__view_pm_event_types',
			// 'permissions__add_pm_event_types',
			// 'permissions__edit_pm_event_types',
			// 'permissions__delete_pm_event_types',
			// 'permissions__view_pm_schedules',
			// 'permissions__add_pm_schedules',
			// 'permissions__edit_pm_schedules',
			// 'permissions__delete_pm_schedules',
			// 'permissions__view_pm_schedule_modes',
			// 'permissions__add_pm_schedule_modes',
			// 'permissions__edit_pm_schedule_modes',
			// 'permissions__delete_pm_schedule_modes',
			// 'permissions__view_pm_statuses',
			// 'permissions__add_pm_statuses',
			// 'permissions__edit_pm_statuses',
			// 'permissions__delete_pm_statuses',
			// 'permissions__view_pm_technicians',
			// 'permissions__add_pm_technicians',
			// 'permissions__edit_pm_technicians',
			// 'permissions__delete_pm_technicians',
			// 'permissions__view_questions',
			// 'permissions__add_questions',
			// 'permissions__edit_questions',
			// 'permissions__delete_questions',
			// 'permissions__view_question_groups',
			// 'permissions__add_question_groups',
			// 'permissions__edit_question_groups',
			// 'permissions__delete_question_groups',
			// 'permissions__view_responses',
			// 'permissions__add_responses',
			// 'permissions__edit_responses',
			// 'permissions__delete_responses',
			// 'permissions__view_users',
			// 'permissions__add_users',
			// 'permissions__edit_users',
			// 'permissions__delete_users',
			// 'permissions__view_work_orders',
			// 'permissions__add_work_orders',
			// 'permissions__edit_work_orders',
			// 'permissions__delete_work_orders',
			// 'permissions__view_wo_classes',
			// 'permissions__add_wo_classes',
			// 'permissions__edit_wo_classes',
			// 'permissions__delete_wo_classes',
			// 'permissions__view_wo_priorities',
			// 'permissions__add_wo_priorities',
			// 'permissions__edit_wo_priorities',
			// 'permissions__delete_wo_priorities'
		],

	},

	entity: {
		methods: {

			getUser: async function() {
				const Users = this.getAssociatedRepository('Users');
				let entity = Users.getById(this.permissions__user_id);
				if (!entity) {
					entity = await Users.getSingleEntityFromServer(this.permissions__user_id);
				}
				return entity;
			},

			getGroup: async function() {
				const Groups = this.getAssociatedRepository('Groups');
				let entity = Groups.getById(this.permissions__group_id);
				if (!entity) {
					entity = await Groups.getSingleEntityFromServer(this.permissions__group_id);
				}
				return entity;
			},

		},
	},


	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": false,
	    "isAutoLoad": false
	},

};

export default Permissions;
