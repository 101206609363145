/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import Tag from '@onehat/ui/src/Components/Form/Field/Tag/Tag.js';
import MeterSourcesEditorWindow from '../../../Window/MeterSourcesEditorWindow.js';

function MeterSourcesTag(props) {
	return <Tag
				reference="MeterSourcesTag"
				model="MeterSources"
				uniqueRepository={true}
				Editor={MeterSourcesEditorWindow}
				usePermissions={true}
				{...props}
			/>;
}

export default MeterSourcesTag;