/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { TagEditor } from '@onehat/ui/src/Components/Form/Field/Tag/Tag.js';
import EqEngineModelsEditorWindow from '../../../Window/EqEngineModelsEditorWindow.js';

function EqEngineModelsTagEditor(props) {
	return <TagEditor
				reference="EqEngineModelsTagEditor"
				model="EqEngineModels"
				uniqueRepository={true}
				Editor={EqEngineModelsEditorWindow}
				usePermissions={true}
				{...props}
			/>;
}

export default EqEngineModelsTagEditor;