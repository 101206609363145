/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import EqEngineModelsSideGridEditor from './EqEngineModelsSideGridEditor.js';

export default function EqEngineModelsFilteredSideGridEditor(props) {
	return <EqEngineModelsSideGridEditor
				reference="EqEngineModelsFilteredSideGridEditor"
				useFilters={true}

				{...props}
			/>;
}