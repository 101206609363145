/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import {
	EDITOR_TYPE__SIDE,
} from '@onehat/ui/src/Constants/Editor.js';
import ResponsesEditor from '../../Editor/ResponsesEditor.js';
import ResponsesGridColumns from '../../Grid/Columns/ResponsesGridColumns.js';

export default function ResponsesSideGridEditorPanel(props) {
	return <GridPanel
				reference="ResponsesSideGridEditorPanel"
				model="Responses"
				usePermissions={true}
				isCollapsible={false}
				isEditor={true}
				editorType={EDITOR_TYPE__SIDE}
				Editor={ResponsesEditor}
				columnsConfig={ResponsesGridColumns}
				
				
				{...props}
			/>;
}