import * as yup from 'yup'; // https://github.com/jquense/yup#string

const Responses = {
	
	name: 'Responses',
	model: {

		idProperty: 'responses__id',
		displayProperty: 'responses__value',
		sortProperty: 'questions__sort_order', // MOD

		sorters: null,

		validator: yup.object({
			responses__question_id: yup.number().integer().required(),
			responses__value: yup.string().max(45).nullable(),
			responses__is_failure: yup.boolean().required(),
			responses__is_skipped: yup.boolean().required(),
			responses__comments: yup.string().nullable(),
			responses__inspection_id: yup.number().integer().required()
		}),
		
		properties: [
			{ name: 'responses__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'responses__question_id', mapping: 'question_id', title: 'Question', type: 'int', isFk: true, fkIdField: 'questions__id', fkDisplayField: 'questions__display_value', filterType: {"type":"QuestionsCombo","loadAfterRender":!1}, editorType: {"type":"QuestionsCombo","loadAfterRender":!1}, fieldGroup: 'General', },
			{ name: 'responses__value', mapping: 'value', title: 'Value', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'responses__is_failure', mapping: 'is_failure', title: 'Failure?', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: 'General', },
			{ name: 'responses__is_skipped', mapping: 'is_skipped', title: 'Skipped?', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: 'General', },
			{ name: 'responses__comments', mapping: 'comments', title: 'Comments', isFilteringDisabled: true, viewerType: {"type":"TextWithLinks","totalLines":6}, editorType: {"type":"TextArea","totalLines":6}, fieldGroup: 'General', },
			{ name: 'responses__group', mapping: 'group', title: 'Group', isVirtual: true, isFilteringDisabled: true, isEditingDisabled: true, fieldGroup: 'General', },
			{ name: 'responses__inspection_id', mapping: 'inspection_id', title: 'Inspection', type: 'int', isFk: true, fkIdField: 'inspections__id', fkDisplayField: 'inspections__reference', filterType: {"type":"InspectionsCombo","loadAfterRender":!1}, editorType: {"type":"InspectionsCombo","loadAfterRender":!1}, fieldGroup: 'General', },
			{ name: 'inspections__id', mapping: 'inspection.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'inspections__date', mapping: 'inspection.date', title: 'Date', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'inspections__checklist_id', mapping: 'inspection.checklist_id', title: 'Checklist', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'inspections__is_failure', mapping: 'inspection.is_failure', title: 'Failure?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'inspections__comments', mapping: 'inspection.comments', title: 'Comments', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'inspections__equipment_id', mapping: 'inspection.equipment_id', title: 'Equipment', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'inspections__user_id', mapping: 'inspection.user_id', title: 'User', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'inspections__reference', mapping: 'inspection.reference', title: 'Reference', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'inspections__has_attachments', mapping: 'inspection.has_attachments', title: 'Has Attachments', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'questions__display_value', mapping: 'question.display_value', title: 'Display Value', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'questions__id', mapping: 'question.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'questions__sort_order', mapping: 'question.sort_order', title: 'Sort', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'questions__name', mapping: 'question.name', title: 'Question', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'questions__description', mapping: 'question.description', title: 'Description', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'questions__response_options', mapping: 'question.response_options', title: 'Response Options', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'questions__fail_options', mapping: 'question.fail_options', title: 'Fail Options', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'questions__is_value_required', mapping: 'question.is_value_required', title: 'Required', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'questions__is_comment_required', mapping: 'question.is_comment_required', title: 'Comment Required', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'questions__checklist_id', mapping: 'question.checklist_id', title: 'Checklist', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'questions__question_group_id', mapping: 'question.question_group_id', title: 'Group', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'questions__calculated_response_options', mapping: 'question.calculated_response_options', title: 'Calculated Response Options', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'questions__calculated_fail_options', mapping: 'question.calculated_fail_options', title: 'Calculated Fail Options', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'questions__is_boolean', mapping: 'question.is_boolean', title: 'Is Boolean', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'questions__boolean_succeed_value', mapping: 'question.boolean_succeed_value', title: 'Boolean Succeed Value', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'questions__boolean_fail_value', mapping: 'question.boolean_fail_value', title: 'Boolean Fail Value', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, }
		],

		associations: {
			belongsTo: [
				'Inspections',
				'Questions'
			],

		},

		ancillaryFilters: [],

		defaultFilters: [
			// 'responses__question_id',
			// 'responses__is_failure',
			// 'responses__is_skipped',
			// 'responses__inspection_id'
		],

	},

	entity: {
		methods: {

			getInspection: async function() {
				const Inspections = this.getAssociatedRepository('Inspections');
				let entity = Inspections.getById(this.responses__inspection_id);
				if (!entity) {
					entity = await Inspections.getSingleEntityFromServer(this.responses__inspection_id);
				}
				return entity;
			},

			getQuestion: async function() {
				const Questions = this.getAssociatedRepository('Questions');
				let entity = Questions.getById(this.responses__question_id);
				if (!entity) {
					entity = await Questions.getSingleEntityFromServer(this.responses__question_id);
				}
				return entity;
			},

		},
	},


	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": false,
	    "isAutoLoad": false
	},

};

export default Responses;
