import { useSelector } from 'react-redux';
import {
	selectCurrentScreen,
} from '../models/Slices/AppSlice';
import ManagerScreen from '@onehat/ui/src/Components/Screens/Manager.js';
import InspectionsFilteredGridEditor from '../components/Grid/InspectionsFilteredGridEditor.js';
import InspectionsFilteredSideGridEditor from '../components/Grid/InspectionsFilteredSideGridEditor.js';

export default function InspectionsManager(props) {
	const
		currentScreen = useSelector(selectCurrentScreen),
		isActive = currentScreen === 'InspectionsManager';

	if (!isActive) {
		return null;
	}

	return <ManagerScreen
				title="Inspections"
				reference="InspectionsManager"
				fullModeComponent={<InspectionsFilteredGridEditor
										reference="InspectionsGridEditor"
										useRemoteDuplicate={true}
									/>}
				sideModeComponent={<InspectionsFilteredSideGridEditor
										reference="InspectionsGridEditor"
										useRemoteDuplicate={true}
									/>}
			/>;
}
