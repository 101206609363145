/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { SideGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import MeterSourcesEditor from '../Editor/MeterSourcesEditor.js';
import MeterSourcesGridColumns from './Columns/MeterSourcesGridColumns.js';

export default function MeterSourcesSideGridEditor(props) {
	return <SideGridEditor
				reference="MeterSourcesSideGridEditor"
				model="MeterSources"
				usePermissions={true}
				isCollapsible={false}
				Editor={MeterSourcesEditor}
				columnsConfig={MeterSourcesGridColumns}
				
				
				{...props}
			/>;
}