/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import MeterSourcesGridPanel from './MeterSourcesGrid.js';

export default function MeterSourcesFilteredGridPanel(props) {
	return <MeterSourcesGridPanel
				reference="MeterSourcesFilteredGridPanel"
				useFilters={true}

				{...props}
			/>;
}