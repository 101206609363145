/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import ChecklistsInlineGridEditorPanel from './ChecklistsInlineGridEditor.js';

export default function ChecklistsFilteredInlineGridEditorPanel(props) {
	return <ChecklistsInlineGridEditorPanel
				reference="ChecklistsFilteredInlineGridEditorPanel"
				useFilters={true}

				{...props}
			/>;
}