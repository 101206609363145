/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import Grid from '@onehat/ui/src/Components/Grid/Grid.js';
import ChecklistsGroupsGridColumnsSideB from './Columns/ChecklistsGroupsGridColumnsSideB.js';

export default function ChecklistsGroupsGridSideB(props) {
	return <Grid
				reference="ChecklistsGroupsGridSideB"
				model="ChecklistsGroups"
				usePermissions={true}
				columnsConfig={ChecklistsGroupsGridColumnsSideB}
				canColumnsReorder={false}
				canColumnsResize={false}

				{...props}
			/>;
}