/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import Attachments from './Attachments.js';
import Checklists from './Checklists.js';
import ChecklistsEquipment from './ChecklistsEquipment.js';
import ChecklistsGroups from './ChecklistsGroups.js';
import ChecklistTypes from './ChecklistTypes.js';
// import Customers from './Customers.js';
import Equipment from './Equipment.js';
// import EquipmentParts from './EquipmentParts.js';
// import EquipmentPmSchedules from './EquipmentPmSchedules.js';
// import EquipmentWorkOrders from './EquipmentWorkOrders.js';
import EqEngineModels from './EqEngineModels.js';
import EqManufacturers from './EqManufacturers.js';
import EqStatuses from './EqStatuses.js';
import EqTypes from './EqTypes.js';
import Groups from './Groups.js';
import GroupsUsers from './GroupsUsers.js';
import Inspections from './Inspections.js';
// import Interchangeables from './Interchangeables.js';
// import Labors from './Labors.js';
// import LaborTypes from './LaborTypes.js';
import Local from './Local.js';
import Locations from './Locations.js';
import MeterReadings from './MeterReadings.js';
import MeterSources from './MeterSources.js';
// import OtherCharges from './OtherCharges.js';
// import Parts from './Parts.js';
// import PartsParts from './PartsParts.js';
// import PartsPartTypes from './PartsPartTypes.js';
// import PartsWorkOrders from './PartsWorkOrders.js';
// import PartManufacturers from './PartManufacturers.js';
// import PartSources from './PartSources.js';
// import PartTypes from './PartTypes.js';
import Permissions from './Permissions.js';
// import PmEvents from './PmEvents.js';
// import PmEventTypes from './PmEventTypes.js';
// import PmSchedules from './PmSchedules.js';
// import PmScheduleModes from './PmScheduleModes.js';
// import PmStatuses from './PmStatuses.js';
// import PmTechnicians from './PmTechnicians.js';
import Prefs from './Prefs.js';
import Questions from './Questions.js';
import QuestionGroups from './QuestionGroups.js';
import Responses from './Responses.js';
import Secure from './Secure.js';
// import UpcomingPms from './UpcomingPms.js';
import Users from './Users.js';
// import WorkOrders from './WorkOrders.js';
// import WoClasses from './WoClasses.js';
// import WoPriorities from './WoPriorities.js';

const obj = {
	Attachments,
	Checklists,
	ChecklistsEquipment,
	ChecklistsGroups,
	ChecklistTypes,
	// Customers,
	Equipment,
	// EquipmentParts,
	// EquipmentPmSchedules,
	// EquipmentWorkOrders,
	EqEngineModels,
	EqManufacturers,
	EqStatuses,
	EqTypes,
	Groups,
	GroupsUsers,
	Inspections,
	// Interchangeables,
	// Labors,
	// LaborTypes,
	Local,
	Locations,
	MeterReadings,
	MeterSources,
	// OtherCharges,
	// Parts,
	// PartsParts,
	// PartsPartTypes,
	// PartsWorkOrders,
	// PartManufacturers,
	// PartSources,
	// PartTypes,
	Permissions,
	// PmEvents,
	// PmEventTypes,
	// PmSchedules,
	// PmScheduleModes,
	// PmStatuses,
	// PmTechnicians,
	Prefs,
	Questions,
	QuestionGroups,
	Responses,
	Secure,
	// UpcomingPms,
	Users,
	// WorkOrders,
	// WoClasses,
	// WoPriorities,
};
export default obj;