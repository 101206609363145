/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import {
	EQ_STATUSES__ACTIVE,
	EQ_STATUSES__INACTIVE,
} from '../../../../constants/EqStatuses.js';
import Combo from '@onehat/ui/src/Components/Form/Field/Combo/Combo.js';

function EquipmentCombo(props) {
	return <Combo
				reference="EquipmentCombo"
				model="Equipment"
				uniqueRepository={true}
				usePermissions={true}
				baseParams={{
					'conditions[eq_status_id IN]': [
						EQ_STATUSES__ACTIVE,
						EQ_STATUSES__INACTIVE,
					],
				}}
				{...props}
			/>;
}

export default EquipmentCombo;