/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import {
	SELECTION_MODE_SINGLE,
} from '@onehat/ui/src/Constants/Selection.js';
import { WindowedGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import ChecklistsEditorWindow from '../Window/ChecklistsEditorWindow.js';
import ChecklistsGridColumns from './Columns/ChecklistsGridColumns.js';

export default function ChecklistsGridEditor(props) {
	return <WindowedGridEditor
				reference="ChecklistsGridEditor"
				model="Checklists"
				usePermissions={true}
				selectionMode={SELECTION_MODE_SINGLE}
				Editor={ChecklistsEditorWindow}
				columnsConfig={ChecklistsGridColumns}
				
				
				{...props}
			/>;
}