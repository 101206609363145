/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import ChecklistsEquipmentSideGridEditorPanel from './ChecklistsEquipmentSideGridEditor.js';


export default function ChecklistsEquipmentFilteredSideGridEditorPanel(props) {
	return <ChecklistsEquipmentSideGridEditorPanel
				reference="ChecklistsEquipmentFilteredSideGridEditorPanel"
				useFilters={true}
				
				{...props}
			/>;
}