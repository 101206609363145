/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { SideGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import ChecklistsEditor from '../Editor/ChecklistsEditor.js';
import ChecklistsGridColumns from './Columns/ChecklistsGridColumns.js';

export default function ChecklistsSideGridEditor(props) {
	return <SideGridEditor
				reference="ChecklistsSideGridEditor"
				model="Checklists"
				usePermissions={true}
				isCollapsible={false}
				Editor={ChecklistsEditor}
				columnsConfig={ChecklistsGridColumns}
				
				
				{...props}
			/>;
}