import { useSelector } from 'react-redux';
import {
	selectCurrentScreen,
} from '../models/Slices/AppSlice';
import ManagerScreen from '@onehat/ui/src/Components/Screens/Manager.js';
import ChecklistsFilteredGridEditor from '../components/Grid/ChecklistsFilteredGridEditor.js';
import ChecklistsFilteredSideGridEditor from '../components/Grid/ChecklistsFilteredSideGridEditor.js';

export default function ChecklistsManager(props) {
	const
		currentScreen = useSelector(selectCurrentScreen),
		isActive = currentScreen === 'ChecklistsManager';

	if (!isActive) {
		return null;
	}

	return <ManagerScreen
				title="Checklists"
				reference="ChecklistsManager"
				fullModeComponent={<ChecklistsFilteredGridEditor
										reference="ChecklistsGridEditor"
										useRemoteDuplicate={true}
									/>}
				sideModeComponent={<ChecklistsFilteredSideGridEditor
										reference="ChecklistsGridEditor"
										useRemoteDuplicate={true}
									/>}
			/>;
}
