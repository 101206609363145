/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import {
	EDITOR_TYPE__SIDE,
} from '@onehat/ui/src/Constants/Editor.js';
import MeterSourcesEditor from '../../Editor/MeterSourcesEditor.js';
import MeterSourcesGridColumns from '../../Grid/Columns/MeterSourcesGridColumns.js';

export default function MeterSourcesSideGridEditorPanel(props) {
	return <GridPanel
				reference="MeterSourcesSideGridEditorPanel"
				model="MeterSources"
				usePermissions={true}
				isCollapsible={false}
				isEditor={true}
				editorType={EDITOR_TYPE__SIDE}
				Editor={MeterSourcesEditor}
				columnsConfig={MeterSourcesGridColumns}
				
				
				{...props}
			/>;
}