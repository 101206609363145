/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { SideGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import ResponsesEditor from '../Editor/ResponsesEditor.js';
import ResponsesGridColumns from './Columns/ResponsesGridColumns.js';

export default function ResponsesSideGridEditor(props) {
	return <SideGridEditor
				reference="ResponsesSideGridEditor"
				model="Responses"
				usePermissions={true}
				isCollapsible={false}
				Editor={ResponsesEditor}
				columnsConfig={ResponsesGridColumns}
				
				
				{...props}
			/>;
}