import {
	Column,
	Row,
	ScrollView,
	Text,
} from 'native-base';
import oneHatData from '@onehat/data';
import withAlert from '@onehat/ui/src/Components/Hoc/withAlert';
import Button from '@onehat/ui/src/Components/Buttons/Button.js';
import UiGlobals from '@onehat/ui/src/UiGlobals.js';

function UtilPage(props) {
	const {
			alert,
		} = props,
		styles = UiGlobals.styles,
		Local = oneHatData.getRepository('Local'),
		Secure = oneHatData.getRepository('Secure'),
		Prefs = oneHatData.getRepository('Prefs'),
		onClearLocal = async () => {
			await Local.clearAll();
			alert('Cleared Local.');
		},
		onClearSecure = async () => {
			await Secure.clearAll();
			alert('Cleared Secure.');
		},
		onClearPrefs = async () => {
			await Prefs.clearAll();
			alert('Cleared Prefs.');
		},
		buttonProps = {
			mr: 2
		},
		textProps = {};
	if (styles.MANAGER_SCREEN_TITLE) {
		textProps.style = {
			fontFamily: styles.MANAGER_SCREEN_TITLE,
		};
	}

	return <Column flex={1} w="100%">
				<Row
					h="80px"
					py={2}
					borderBottomWidth={2}
					borderBottomColor="#ccc"
				>
					<Text p={4} fontSize="26" fontWeight={700} {...textProps}>Utilities</Text>
				</Row>
				<ScrollView w="100%">
					<Column w="100%" p={4}>
						<Row>
							<Button
								text="Clear Local"
								onPress={onClearLocal}
								{...buttonProps}
							/>
							<Button
								text="Clear Secure"
								onPress={onClearSecure}
								{...buttonProps}
							/>
							<Button
								text="Clear Prefs"
								onPress={onClearPrefs}
								{...buttonProps}
							/>
						</Row>
					</Column>
				</ScrollView>
			</Column>;
}

export default withAlert(UtilPage);
