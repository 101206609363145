/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { ComboEditor } from '@onehat/ui/src/Components/Form/Field/Combo/Combo.js';
import EqTypesEditorWindow from '../../../Window/EqTypesEditorWindow.js';

function EqTypesComboEditor(props) {
	return <ComboEditor
				reference="EqTypesComboEditor"
				model="EqTypes"
				uniqueRepository={true}
				Editor={EqTypesEditorWindow}
				usePermissions={true}
				{...props}
			/>;
}

export default EqTypesComboEditor;