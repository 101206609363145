export default [
	    {
	        "id": "checklists_equipment__equipment_id",
	        "header": "Equipment",
	        "fieldName": "equipment__nickname",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "editField": "checklists_equipment__equipment_id",
	        "flex": 1,
	        "minWidth": 150
	    }
	];