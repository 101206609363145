/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import QuestionsGridEditor from './QuestionsGridEditor.js';

export default function QuestionsFilteredGridEditor(props) {
	return <QuestionsGridEditor
				reference="QuestionsFilteredGridEditor"
				useFilters={true}

				{...props}
			/>;
}