/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import {
	EDITOR_TYPE__SIDE,
} from '@onehat/ui/src/Constants/Editor.js';
import EquipmentEditor from '../../Editor/EquipmentEditor.js';
import EquipmentGridColumns from '../../Grid/Columns/EquipmentGridColumns.js';

export default function EquipmentSideGridEditorPanel(props) {
	return <GridPanel
				reference="EquipmentSideGridEditorPanel"
				model="Equipment"
				usePermissions={true}
				isCollapsible={false}
				isEditor={true}
				editorType={EDITOR_TYPE__SIDE}
				Editor={EquipmentEditor}
				columnsConfig={EquipmentGridColumns}
				
				
				{...props}
			/>;
}