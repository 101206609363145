export default [
	    {
	        "id": "checklists__name",
	        "header": "Name",
	        "fieldName": "checklists__name",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 250 // MOD
	    },
	    {
	        "id": "checklists__checklist_type_id",
	        "header": "Checklist Type",
	        "fieldName": "checklist_types__name",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200, // MOD
	        "editField": "checklists__checklist_type_id"
	    },
	    {
	        "id": "checklists__response_options",
	        "header": "Response Options",
	        "fieldName": "checklists__response_options",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200
	    },
	    {
	        "id": "checklists__fail_options",
	        "header": "Fail Options",
	        "fieldName": "checklists__fail_options",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200
	    },
	    {
	        "id": "checklists__groups",
	        "header": "Groups",
	        "fieldName": "checklists__groups",
	        "isSortable": false,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 300 // MOD
	    },
	    {
	        "id": "checklists__can_save_for_later",
	        "header": "Can Save For Later",
	        "fieldName": "checklists__can_save_for_later",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150 // MOD
	    },
	    {
	        "id": "checklists__use_multiple_screens",
	        "header": "Use Multiple Screens",
	        "fieldName": "checklists__use_multiple_screens",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 180 // MOD
	    },
	    {
	        "id": "checklists__email_to",
	        "header": "Email To",
	        "fieldName": "checklists__email_to",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 300 // MOD
	    }
	];