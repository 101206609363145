/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import ChecklistsGroupsGrid from './ChecklistsGroupsGrid.js';

export default function ChecklistsGroupsFilteredGrid(props) {
	return <ChecklistsGroupsGrid
				reference="ChecklistsGroupsFilteredGrid"
				useFilters={true}
				
				{...props}
			/>;
}