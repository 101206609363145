/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */
import Editor from '@onehat/ui/src/Components/Editor/Editor.js';
import _ from 'lodash';

export default function ResponsesEditor(props) {

	if (_.isEmpty(props.selection) || props.selection[0]?.isDestroyed) {
		return null;
	}

	const {
		selection,
		checklist_id,
	} = props;

	let entity;
	if (selection?.length === 1) {
		entity = selection[0];
	}

	const
		items = [
		    {
		        "type": "Column",
		        "flex": 1,
		        "defaults": {},
		        "items": [
		            {
		                "type": "FieldSet",
		                "title": "General",
		                "reference": "general",
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "responses__question_id",
								baseParams: {
									// limit potential Questions to what applies to this Checklist
									'conditions[questions__checklist_id]': checklist_id,
								},
		                    },
		                    {
		                        "name": "responses__value"
		                    },
		                    {
		                        "name": "responses__is_failure"
		                    },
		                    {
		                        "name": "responses__is_skipped"
		                    },
		                    {
		                        "name": "responses__comments"
		                    },
		                    // {
		                    //     "name": "responses__group"
		                    // },
		                    // {
		                    //     "name": "responses__inspection_id"
		                    // }
		                ]
		            }
		        ]
		    }
		],
		ancillaryItems = [],
		columnDefaults = { // defaults for each column defined in 'items', for use in Form amd Viewer
		};
	return <Editor
				reference="ResponsesEditor"
				title="Responses"
				items={items}
				ancillaryItems={ancillaryItems}
				columnDefaults={columnDefaults}
				{...props}
			/>;
}

