/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import ChecklistTypesInlineGridEditorPanel from './ChecklistTypesInlineGridEditor.js';

export default function ChecklistTypesFilteredInlineGridEditorPanel(props) {
	return <ChecklistTypesInlineGridEditorPanel
				reference="ChecklistTypesFilteredInlineGridEditorPanel"
				useFilters={true}

				{...props}
			/>;
}