/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { SideGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import InspectionsEditor from '../Editor/InspectionsEditor.js';
import InspectionsGridColumns from './Columns/InspectionsGridColumns.js';

export default function InspectionsSideGridEditor(props) {
	return <SideGridEditor
				reference="InspectionsSideGridEditor"
				model="Inspections"
				usePermissions={true}
				isCollapsible={false}
				Editor={InspectionsEditor}
				columnsConfig={InspectionsGridColumns}
				
				
				{...props}
			/>;
}