/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import Grid from '@onehat/ui/src/Components/Grid/Grid.js';
import QuestionsGridColumns from './Columns/QuestionsGridColumns.js';

export default function QuestionsGrid(props) {
	return <Grid
				reference="QuestionsGrid"
				model="Questions"
				usePermissions={true}
				columnsConfig={QuestionsGridColumns}
				canRowsReorder={true}
				{...props}
			/>;
}