export default [
	    {
	        "id": "checklists_equipment__checklist_id",
	        "header": "Checklist",
	        "fieldName": "checklists__name",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100,
	        "editField": "checklists_equipment__checklist_id"
	    },
	    {
	        "id": "checklists_equipment__equipment_id",
	        "header": "Equipment",
	        "fieldName": "equipment__nickname",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100,
	        "editField": "checklists_equipment__equipment_id"
	    }
	];