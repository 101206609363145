/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import Grid from '@onehat/ui/src/Components/Grid/Grid.js';
import QuestionGroupsGridColumns from './Columns/QuestionGroupsGridColumns.js';

export default function QuestionGroupsGrid(props) {
	return <Grid
				reference="QuestionGroupsGrid"
				model="QuestionGroups"
				usePermissions={true}
				columnsConfig={QuestionGroupsGridColumns}
				canRowsReorder={true}
				{...props}
			/>;
}