/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { TagEditor } from '@onehat/ui/src/Components/Form/Field/Tag/Tag.js';
import QuestionsEditorWindow from '../../../Window/QuestionsEditorWindow.js';

function QuestionsTagEditor(props) {
	return <TagEditor
				reference="QuestionsTagEditor"
				model="Questions"
				uniqueRepository={true}
				Editor={QuestionsEditorWindow}
				usePermissions={true}
				{...props}
			/>;
}

export default QuestionsTagEditor;