/*
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */
import env from '../env';
import {
	Platform,
} from 'react-native';
import Constants from 'expo-constants';
import {
	DATE_MOMENT_FORMAT,
	DATETIME_MOMENT_FORMAT,
} from './constants/Dates';
import moment from 'moment';

const
	manifest = Constants.manifest || Constants.manifest2,
	extra = manifest?.extra?.expoClient?.extra || manifest?.extra, 
	PROCESS_ENV = process.env,
	gitCommit = PROCESS_ENV?.GIT_COMMIT,
	// subdomain = PROCESS_ENV?.SUBDOMAIN || 'testing',
	experienceId = extra?.experienceId;

let subdomain = null;
if (location.hostname.match(/localhost|127|192/)) {
	subdomain = 'localhost';
} else if (location.hostname.match(/(.*)\.gingerichcrane\.com/)) {
	subdomain = location.hostname.match(/(.*)\.gingerichcrane\.com/)[1];
}

// TEMP
// subdomain = 'staging';

const Globals = {
	debugMode: false, //__DEV__, // Auto-sets to false for production
	showProgrammerErrorMessages: false,
	useDebugStack: false,
	baseURL: __DEV__ ? 'http://' + env.ip + '/Gingerich/' : 'https://' + subdomain + '.gingerichcrane.com/',
	// baseURL: 'http://' + env.ip + '/Gingerich/',
	// baseURL: 'https://' + subdomain + '.gingerichcrane.com/',
	loginIdField: 'username',
	urlPrefix: null,
	redux: null,
	appState: false,
	gitCommit,
	experienceId, // for expo-notifications
	retryInterval: (Platform.OS === 'ios' ? 2 * 60 : 20) * 1000, // ms
	clearAllData: false,
	clearDataBefore: '2024-09-02 19:00:00',

	disableSavedColumnsConfig: true, // temp
	editorStaysInEditModeOnChangeSelection: false, // BJ preference
	doubleClickingGridRowOpensEditorInViewMode: true, // BJ preference
	dates: {
		today: moment().format(DATE_MOMENT_FORMAT),
		todayDatetime: moment().format(DATETIME_MOMENT_FORMAT),
		yesterday: moment().subtract(1, 'days').format(DATE_MOMENT_FORMAT),
		tomorrow: moment().add(1, 'days').format(DATE_MOMENT_FORMAT),
		oneMonthAgo: moment().subtract(1, 'months').format(DATE_MOMENT_FORMAT),
		oneYearAgo: moment().subtract(1, 'years').format(DATE_MOMENT_FORMAT),
		startOfThisMonth: moment().startOf('months').format(DATE_MOMENT_FORMAT),
		endOfLastMonth: moment().subtract(1, 'months').endOf('month').format(DATE_MOMENT_FORMAT),
		twoMonthsAgo: moment().subtract(2, 'months').format(DATE_MOMENT_FORMAT),
		sixMonthsAgo: moment().subtract(6, 'months').format(DATE_MOMENT_FORMAT),
		oneMonthFromNow: moment().add(1, 'months').format(DATE_MOMENT_FORMAT),
	},
};

export default Globals;

