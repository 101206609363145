import React, { useState, } from 'react';
import {
	Row,
} from 'native-base';
import testProps from '@onehat/ui/src/Functions/testProps.js';
import withTooltip from '@onehat/ui/src/Components/Hoc/withTooltip.js';
import withValue from '@onehat/ui/src/Components/Hoc/withValue.js';
import SquareButton from '@onehat/ui/src/Components/Buttons/SquareButton.js';
import Pause from '@onehat/ui/src/Components/Icons/Pause.js';
import SackDollar from '../../Icon/SackDollar';
import TruckFast from '../../Icon/TruckFast';
import _ from 'lodash';


const
	EqStatusesSwitchElement = (props) => {
		const {
				value,
				setValue,
				isEditable = true,
			} = props,
			[isReady, setIsReady] = useState(false),
			[width, setWidth] = useState(0),
			onPress = (newValue) => {
				if (!isEditable) {
					return;
				}
				setValue(newValue);
			},
			onLayout = (e) => {
				const containerWidth = e.nativeEvent.layout.width;
				if (containerWidth !== width) {
					setWidth(containerWidth);
				}
				if (!isReady) {
					setIsReady(true);
				}
			};

		let convertedValue = value;
		if (_.isString(value)) {
			switch(value) {
				case 'Active':
					convertedValue = 1;
					break;
				case 'Paused':
				case 'Inactive':
					convertedValue = 2;
					break;
				case 'Sold':
					convertedValue = 3;
					break;
			}
		}

		const squareButtonProps = {};
		if (width <= 200) {
			squareButtonProps.showText = false;
		} else
		if (width > 200 && width <= 300) {
			squareButtonProps.w = '50px';
			squareButtonProps.fontSize = '15px';
		} else
		if (width > 300) {
			squareButtonProps.w = '70px';
			squareButtonProps.fontSize = '15px';
		}
		return <Row {...props} flex={1} alignItems="center" onLayout={onLayout}>
					{isReady && 
						<>
							<SquareButton
								{...testProps('activeBtn')}
								icon={TruckFast}
								text="Active"
								isActive={convertedValue === 1}
								activeColor="#00ae08"
								invertColorWhenActive={true}
								onPress={() => onPress(1)}
								disableInteractions={!isEditable}
								{...squareButtonProps}
							/>
							<SquareButton
								{...testProps('pausedBtn')}
								icon={Pause}
								text="Paused"
								isActive={convertedValue === 2}
								activeColor="#f00"
								invertColorWhenActive={true}
								onPress={() => onPress(2)}
								disableInteractions={!isEditable}
								{...squareButtonProps}
								ml={3}
							/>
							<SquareButton
								{...testProps('soldBtn')}
								icon={SackDollar}
								text="Sold"
								isActive={convertedValue === 3}
								activeColor="#fe9915"
								invertColorWhenActive={true}
								onPress={() => onPress(3)}
								disableInteractions={!isEditable}
								{...squareButtonProps}
								ml={3}
							/>
						</>}
				</Row>;
	},
	EqStatusesSwitchField = withValue(EqStatusesSwitchElement);

// Tooltip needs us to forwardRef
export default withTooltip(React.forwardRef((props, ref) => {
	return <EqStatusesSwitchField {...props} outerRef={ref} />;
}));
