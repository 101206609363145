/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */
import Editor from '@onehat/ui/src/Components/Editor/Editor.js';
import _ from 'lodash';

export default function QuestionsEditor(props) {

	if (_.isEmpty(props.selection) || props.selection[0]?.isDestroyed) {
		return null;
	}

	const {
			selection,
		} = props;

	let entity;
	if (selection?.length === 1) {
		entity = selection[0];
	}

	const
		items = [
		    {
		        "type": "Column",
		        "flex": 1,
		        "defaults": {},
		        "items": [
		            {
		                "type": "FieldSet",
		                "title": "General",
		                "reference": "general",
		                "defaults": {},
		                "items": [
		                    // {
		                    //     "name": "questions__display_value"
		                    // },
		                    {
		                        "name": "questions__name"
		                    },
		                    {
		                        "name": "questions__sort_order" // MOD the sort_order to second position
		                    },
		                    {
		                        "name": "questions__question_group_id",
								baseParams: {
									// limit potential QuestionGroups to what applies to this Checklist
									leftJoinWith: 'Checklists',
									'conditions[checklists__id]': entity?.checklists__id
								},
		                    },
		                    {
		                        "name": "questions__description"
		                    },
		                    {
		                        "name": "questions__response_options",
		                        "tooltip": "Comma-separated list of responses. e.g. 'Red,Blue,Green'\nThese override the main checklist settings"
		                    },
		                    {
		                        "name": "questions__fail_options",
		                        "tooltip": "Comma-separated list of fail options. e.g. 'Blue,Green'\nThese override the main checklist settings"
		                    },
		                    {
		                        "name": "questions__is_value_required"
		                    },
		                    {
		                        "name": "questions__is_comment_required"
		                    },
		                    // {
		                    //     "name": "questions__checklist_id"
		                    // },
		                    // {
		                    //     "name": "questions__calculated_response_options"
		                    // },
		                    // {
		                    //     "name": "questions__calculated_fail_options"
		                    // },
		                    // {
		                    //     "name": "questions__is_boolean"
		                    // },
		                    // {
		                    //     "name": "questions__boolean_succeed_value"
		                    // },
		                    // {
		                    //     "name": "questions__boolean_fail_value"
		                    // }
		                ]
		            }
		        ]
		    }
		],
		ancillaryItems = [
		    // {
		    //     "title": "Responses",
		    //     "type": "ResponsesGridEditor",
		    //     "selectorId": "responses__question_id"
		    // }
		],
		columnDefaults = { // defaults for each column defined in 'items', for use in Form amd Viewer
		};
	return <Editor
				reference="QuestionsEditor"
				title="Questions"
				items={items}
				ancillaryItems={ancillaryItems}
				columnDefaults={columnDefaults}
				{...props}
			/>;
}

