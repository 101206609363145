import moment from 'moment';

export const DATE_MOMENT_FORMAT = 'YYYY-MM-DD';
export const DATE_READABLE_MOMENT_FORMAT = 'MMMM Do YYYY';
export const DATETIME_MOMENT_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const DATETIME_READABLE_MOMENT_FORMAT = 'MMMM Do YYYY, h:mm:ss a';
export const TIME_MOMENT_FORMAT = 'HH:mm:ss';
export const TIME_READABLE_MOMENT_FORMAT = 'h:mm A';
export const START_OF_DAY_TIME = ' 00:00:00'
export const END_OF_DAY_TIME = ' 23:59:59'
export const TODAY = moment();
export const TODAY_START = TODAY.format(DATE_MOMENT_FORMAT) + START_OF_DAY_TIME;
export const TODAY_END = TODAY.format(DATE_MOMENT_FORMAT) + END_OF_DAY_TIME;
export const TOMORROW = moment().add(1, 'days');
export const YESTERDAY = moment().add(-1, 'days');
export const TWO_WEEKS_AGO = moment().add(-2, 'weeks');
export const ONE_MONTH_AGO = moment().add(-1, 'months');
export const TWO_MONTHS_AGO = moment().add(-2, 'months');
export const SIX_MONTHS_AGO = moment().add(-6, 'months');
export const ONE_MONTH_FROM_NOW = moment().add(1, 'months');
export const ONE_YEAR_AGO = moment().add(-1, 'years');
