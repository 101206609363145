import * as yup from 'yup'; // https://github.com/jquense/yup#string
import {
	DATE_MOMENT_FORMAT,
} from '../../constants/Dates.js';
import moment from 'moment';

const Equipment = {
	
	name: 'Equipment',
	model: {

		idProperty: 'equipment__id',
		displayProperty: 'equipment__nickname',
		sortProperty: 'equipment__nickname',
		
		sorters: null,

		validator: yup.object({
			equipment__eq_status_id: yup.number().integer().nullable(),
			equipment__nickname: yup.string().nullable(),
			equipment__eq_manufacturer_id: yup.number().integer().nullable(),
			equipment__model: yup.string().nullable(),
			equipment__serial: yup.string().nullable(),
			equipment__eq_type_id: yup.number().integer().nullable(),
			equipment__year: yup.number().integer().nullable(),
			equipment__eq_engine_model_id: yup.number().integer().nullable(),
			equipment__engine_serial: yup.string().max(45).nullable(),
			equipment__comments: yup.string().nullable(),
			equipment__is_pm_enable: yup.boolean().nullable(),
			equipment__pm_schedules: yup.string().nullable(),
			equipment__expected_hrs_per_mo: yup.number().integer().nullable(),
			equipment__in_service_date: yup.date().nullable(),
			equipment__in_service_meter: yup.number().integer().nullable(),
			equipment__parts: yup.string().nullable()
		}),
		
		properties: [
			{ name: 'equipment__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'equipment__eq_status_id', mapping: 'eq_status_id', title: 'Status', type: 'int', isFk: true, fkIdField: 'eq_statuses__id', fkDisplayField: 'eq_statuses__name', filterType: {"type":"EqStatusesCombo","loadAfterRender":!1}, viewerType: {"type":"EqStatusesSwitch"}, editorType: {"type":"EqStatusesSwitch"}, fieldGroup: '1. Identification', defaultValue: 1, },
			{ name: 'equipment__nickname', mapping: 'nickname', title: 'Nickname', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: '1. Identification', },
			{ name: 'equipment__eq_manufacturer_id', mapping: 'eq_manufacturer_id', title: 'Make', type: 'int', isFk: true, fkIdField: 'eq_manufacturers__id', fkDisplayField: 'eq_manufacturers__name', filterType: {"type":"EqManufacturersCombo","loadAfterRender":!1}, editorType: {"type":"EqManufacturersComboEditor","loadAfterRender":!1}, fieldGroup: '1. Identification', },
			{ name: 'equipment__model', mapping: 'model', title: 'Model', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: '1. Identification', },
			{ name: 'equipment__serial', mapping: 'serial', title: 'Serial', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: '1. Identification', },
			{ name: 'equipment__eq_type_id', mapping: 'eq_type_id', title: 'Eq Type', type: 'int', isFk: true, fkIdField: 'eq_types__id', fkDisplayField: 'eq_types__name', filterType: {"type":"EqTypesCombo","loadAfterRender":!1}, editorType: {"type":"EqTypesComboEditor","loadAfterRender":!1}, fieldGroup: '1. Identification', },
			{ name: 'equipment__year', mapping: 'year', title: 'Year', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '1. Identification', },
			{ name: 'equipment__eq_engine_model_id', mapping: 'eq_engine_model_id', title: 'Engine Model', type: 'int', isFk: true, fkIdField: 'eq_engine_models__id', fkDisplayField: 'eq_engine_models__name', filterType: {"type":"EqEngineModelsCombo","loadAfterRender":!1}, editorType: {"type":"EqEngineModelsComboEditor","loadAfterRender":!1}, fieldGroup: '3. Specs', },
			{ name: 'equipment__engine_serial', mapping: 'engine_serial', title: 'Engine Serial', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: '3. Specs', },
			{ name: 'equipment__comments', mapping: 'comments', title: 'Additional Info', isFilteringDisabled: true, viewerType: {"type":"TextWithLinks","totalLines":6}, editorType: {"type":"TextArea","totalLines":6}, fieldGroup: '3. Specs', },
			{ name: 'equipment__is_pm_enable', mapping: 'is_pm_enable', title: 'PM Enable?', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '2. PM Setup', defaultValue: 1, },
			{ name: 'equipment__pm_schedules', mapping: 'pm_schedules', title: 'PM Schedules', filterType: {"type":"PmSchedulesTag"}, viewerType: {"type":"PmSchedulesTag"}, editorType: {"type":"PmSchedulesTagEditor","loadAfterRender":!1}, fieldGroup: '2. PM Setup', },
			{ name: 'equipment__expected_hrs_per_mo', mapping: 'expected_hrs_per_mo', title: 'Expected Meter / Mo', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '2. PM Setup', },
			{ name: 'equipment__in_service_date', mapping: 'in_service_date', title: 'In Service Date', type: 'date', filterType: {"type":"DateRange","mode":"DATE"}, editorType: {"type":"Date","mode":"DATE"}, fieldGroup: '2. PM Setup', defaultValue: () => moment().format(DATE_MOMENT_FORMAT), },
			{ name: 'equipment__in_service_meter', mapping: 'in_service_meter', title: 'In Service Meter', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '2. PM Setup', },
			{ name: 'equipment__inactive_date', mapping: 'inactive_date', title: 'Inactive Date (calc)', type: 'date', filterType: {"type":"DateRange","mode":"DATE"}, isEditingDisabled: true, fieldGroup: '2. PM Setup', },
			{ name: 'equipment__available_hrs_per_wk', mapping: 'available_hrs_per_wk', title: 'Available Meter / Wk', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '2. PM Setup', },
			{ name: 'equipment__parts', mapping: 'parts', title: 'Parts', filterType: {"type":"PartsTag"}, viewerType: {"type":"PartsTag"}, editorType: {"type":"PartsTagEditor","loadAfterRender":!1}, fieldGroup: '3. Specs', },
			{ name: 'equipment__is_stale_stats', mapping: 'is_stale_stats', title: 'Stale Stats?', type: 'bool', filterType: {"type":"Toggle"}, isEditingDisabled: true, fieldGroup: '4. Calculated', },
			{ name: 'equipment__current_meter', mapping: 'current_meter', title: 'Current Meter (calc)', type: 'int', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '4. Calculated', },
			{ name: 'equipment__next_pm_due_data', mapping: 'next_pm_due_data', title: 'Next PM Due Dates', isFilteringDisabled: true, isEditingDisabled: true, fieldGroup: '4. Calculated', },
			{ name: 'equipment__latest_inspection_date', mapping: 'latest_inspection_date', title: 'Latest Inspection Date', type: 'date', filterType: {"type":"DateRange","mode":"DATE"}, isEditingDisabled: true, fieldGroup: '4. Calculated', },
			{ name: 'equipment__latest_inspection_passed', mapping: 'latest_inspection_passed', title: 'Latest Inspection Passed', type: 'bool', filterType: {"type":"Toggle"}, isEditingDisabled: true, fieldGroup: '4. Calculated', },
			{ name: 'equipment__modified_at', mapping: 'modified_at', title: 'Last Modified', type: 'datetime', filterType: {"type":"DateRange","mode":"DATETIME"}, isEditingDisabled: true, fieldGroup: '4. Calculated', },
			{ name: 'eq_manufacturers__id', mapping: 'eq_manufacturer.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_manufacturers__name', mapping: 'eq_manufacturer.name', title: 'Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_engine_models__id', mapping: 'eq_engine_model.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_engine_models__name', mapping: 'eq_engine_model.name', title: 'Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_statuses__id', mapping: 'eq_status.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_statuses__name', mapping: 'eq_status.name', title: 'Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_types__id', mapping: 'eq_type.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_types__name', mapping: 'eq_type.name', title: 'Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, }
		],

		associations: {
			hasMany: [
				'ChecklistsEquipment',
				'Inspections',
				'MeterReadings',
				'PmEvents',
				'EquipmentPmSchedules',
				'Attachments',
				'EquipmentParts'
			],
			belongsTo: [
				'EqManufacturers',
				'EqEngineModels',
				'EqStatuses',
				'EqTypes'
			],
			belongsToMany: [
				'Checklists',
				'Parts',
				'PmSchedules',
				'WorkOrders'
			],

		},

		ancillaryFilters: {
		    "checklists": "Checklist",
		    "work_orders": "WorkOrder"
		},

		defaultFilters: [
			// 'equipment__eq_status_id',
			// 'equipment__eq_manufacturer_id',
			// 'equipment__eq_type_id',
			// 'equipment__year',
			// 'equipment__eq_engine_model_id',
			// 'equipment__is_pm_enable',
			// 'equipment__pm_schedules',
			// 'equipment__expected_hrs_per_mo',
			// 'equipment__in_service_date',
			// 'equipment__in_service_meter',
			// 'equipment__inactive_date',
			// 'equipment__available_hrs_per_wk',
			// 'equipment__parts',
			// 'equipment__is_stale_stats',
			// 'equipment__current_meter',
			// 'equipment__latest_inspection_date',
			// 'equipment__latest_inspection_passed',
			// 'equipment__modified_at'
		],

	},

	entity: {
		methods: {

			getChecklistsEquipment: function() {
				const ChecklistsEquipment = this.getAssociatedRepository('ChecklistsEquipment');
				return ChecklistsEquipment.getBy((entity) => {
					return entity.checklists_equipment__equipment_id === this.equipment__id;
				});
			},

			getInspections: function() {
				const Inspections = this.getAssociatedRepository('Inspections');
				return Inspections.getBy((entity) => {
					return entity.inspections__equipment_id === this.equipment__id;
				});
			},

			getMeterReadings: function() {
				const MeterReadings = this.getAssociatedRepository('MeterReadings');
				return MeterReadings.getBy((entity) => {
					return entity.meter_readings__equipment_id === this.equipment__id;
				});
			},

			getPmEvents: function() {
				const PmEvents = this.getAssociatedRepository('PmEvents');
				return PmEvents.getBy((entity) => {
					return entity.pm_events__equipment_id === this.equipment__id;
				});
			},

			getEqManufacturer: async function() {
				const EqManufacturers = this.getAssociatedRepository('EqManufacturers');
				let entity = EqManufacturers.getById(this.equipment__eq_manufacturer_id);
				if (!entity) {
					entity = await EqManufacturers.getSingleEntityFromServer(this.equipment__eq_manufacturer_id);
				}
				return entity;
			},

			getEquipmentPmSchedules: function() {
				const EquipmentPmSchedules = this.getAssociatedRepository('EquipmentPmSchedules');
				return EquipmentPmSchedules.getBy((entity) => {
					return entity.equipment_pm_schedules__equipment_id === this.equipment__id;
				});
			},

			getEqEngineModel: async function() {
				const EqEngineModels = this.getAssociatedRepository('EqEngineModels');
				let entity = EqEngineModels.getById(this.equipment__eq_engine_model_id);
				if (!entity) {
					entity = await EqEngineModels.getSingleEntityFromServer(this.equipment__eq_engine_model_id);
				}
				return entity;
			},

			getEqStatus: async function() {
				const EqStatuses = this.getAssociatedRepository('EqStatuses');
				let entity = EqStatuses.getById(this.equipment__eq_status_id);
				if (!entity) {
					entity = await EqStatuses.getSingleEntityFromServer(this.equipment__eq_status_id);
				}
				return entity;
			},

			getAttachments: function() {
				const Attachments = this.getAssociatedRepository('Attachments');
				return Attachments.getBy((entity) => {
					return entity.attachments__modelid === this.equipment__id;
				});
			},

			getEquipmentParts: function() {
				const EquipmentParts = this.getAssociatedRepository('EquipmentParts');
				return EquipmentParts.getBy((entity) => {
					return entity.equipment_parts__equipment_id === this.equipment__id;
				});
			},

			getEqType: async function() {
				const EqTypes = this.getAssociatedRepository('EqTypes');
				let entity = EqTypes.getById(this.equipment__eq_type_id);
				if (!entity) {
					entity = await EqTypes.getSingleEntityFromServer(this.equipment__eq_type_id);
				}
				return entity;
			},

		},
	},


	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": true,
	    "isAutoLoad": false,
	    "pageSize": 30
	},

};

export default Equipment;
