import * as yup from 'yup'; // https://github.com/jquense/yup#string

const ChecklistTypes = {
	
	name: 'ChecklistTypes',
	
	model: {

		idProperty: 'checklist_types__id',
		displayProperty: 'checklist_types__name',
		sortProperty: 'checklist_types__name',
		
		sorters: null,

		validator: yup.object({
			checklist_types__name: yup.string().max(50).required()
		}),
		
		properties: [
			{ name: 'checklist_types__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'checklist_types__name', mapping: 'name', title: 'Name', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', }
		],

		associations: {
			hasMany: [
				'Checklists'
			],

		},

		ancillaryFilters: [],

		defaultFilters: [

		],

	},

	entity: {
		methods: {

			getChecklists: function() {
				const Checklists = this.getAssociatedRepository('Checklists');
				return Checklists.getBy((entity) => {
					return entity.checklists__checklist_type_id === this.checklist_types__id;
				});
			},

		},
	},


	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": false,
	    "isAutoLoad": true,
	    "isPaginated": false
	},

};

export default ChecklistTypes;
