/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import Tag from '@onehat/ui/src/Components/Form/Field/Tag/Tag.js';
import EquipmentEditorWindow from '../../../Window/EquipmentEditorWindow.js';

function EquipmentTag(props) {
	return <Tag
				reference="EquipmentTag"
				model="Equipment"
				uniqueRepository={true}
				Editor={EquipmentEditorWindow}
				usePermissions={true}
				{...props}
			/>;
}

export default EquipmentTag;