import * as React from "react"
import Svg, { G, Path } from "react-native-svg"
import { Icon } from 'native-base';

function SvgComponent(props) {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16.86 16.81"
      {...props}
    >
		<Path
			d="M12.13 14.63c0 1.2-.98 2.18-2.18 2.18H2.18c-1.2 0-2.18-.98-2.18-2.18 0-1.2.98-2.18 2.18-2.18h1.24v-.75H1.9a.86.86 0 01-.86-.86V6.07c0-.48.39-.86.86-.86h5.27c.26 0 .5.11.66.31l2.38 2.87c.13.15.2.35.2.55v1.9c0 .48-.39.86-.86.86h-.82v.75h1.22c1.2 0 2.18.98 2.18 2.18zm-10.06-.7c-.39 0-.7.32-.7.7s.32.7.7.7.7-.32.7-.7-.32-.7-.7-.7zm2.2 0c-.39 0-.7.32-.7.7s.32.7.7.7.7-.32.7-.7-.32-.7-.7-.7zm5.09.7c0 .39.32.7.7.7s.7-.32.7-.7-.32-.7-.7-.7-.7.32-.7.7zm-2.2 0c0 .39.32.7.7.7s.7-.32.7-.7-.32-.7-.7-.7-.7.32-.7.7zm.07-8.54a.317.317 0 00-.25-.12H5.61l1.01 3.55H9.7v-.34c0-.08-.03-.15-.08-.21L7.24 6.1zM15.56 11.15v-.12a.813.813 0 000-1.5V.69l.47-.69H13.8L9.54 4.35 8.51 5.41l.45.45.72-.73.87 1.86-.39.58.53.36 4.23-6.3v7.89a.813.813 0 000 1.5v.73h.32c.55 0 .99.44.99.99s-.44.99-.99.99-.99-.44-.99-.99h-.64a1.62 1.62 0 103.24 0c0-.79-.56-1.45-1.31-1.59zM14.06.64h.76l-.41.6-1 .06.65-.66zm-1.12 1.32l1.02-.06L13 3.33l-.06-1.37zm-.62.45l.07 1.61-1.8.16 1.74-1.77zm-2.09 2.44l1.85-.16-1.14 1.69-.71-1.52z"
		/>
    </Icon>
  )
}

export default SvgComponent
