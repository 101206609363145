/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import Grid from '@onehat/ui/src/Components/Grid/Grid.js';
import ChecklistTypesGridColumns from './Columns/ChecklistTypesGridColumns.js';

export default function ChecklistTypesGrid(props) {
	return <Grid
				reference="ChecklistTypesGrid"
				model="ChecklistTypes"
				usePermissions={true}
				columnsConfig={ChecklistTypesGridColumns}

				{...props}
			/>;
}