/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import ChecklistsGroupsInlineGridEditorPanel from './ChecklistsGroupsInlineGridEditor.js';

export default function ChecklistsGroupsFilteredInlineGridEditorPanel(props) {
	return <ChecklistsGroupsInlineGridEditorPanel
				reference="ChecklistsGroupsFilteredInlineGridEditorPanel"
				useFilters={true}

				{...props}
			/>;
}