import {
	Linking,
} from 'react-native';
import {
	Text,
} from 'native-base';
import {
	UI_MODE_WEB,
} from '@onehat/ui/src/Constants/UiModes.js';
import UiGlobals from '@onehat/ui/src/UiGlobals.js';
import withComponent from '@onehat/ui/src/Components/Hoc/withComponent.js';

function TextWithLinksElement(props) {
	const {
			value: text,
		} = props,
		styles = UiGlobals.styles,
		openLink = (url) => {
			Linking.openURL(url);
		},
		extractLinks = (text) => {

			if (_.isNil(text) || _.isEmpty(text)) {
				return [];
			}
			const
				regex = /\b(?:https?|ftp):\/\/\S+/g,
				links = text.match(regex) || [];
		
			return links.map((link, ix) => ({
				link,
				key: `link_${ix}`,
			}));
		},
		renderTextWithLinks = () => {
			const links = extractLinks(text);
			let modifiedText = text;

			if (_.isNil(modifiedText) || _.isEmpty(modifiedText)) {
				return null;
			}
	
			links.forEach(({ link, key }) => {
				modifiedText = modifiedText.replace(link, key);
			});

			const textSegments = modifiedText.split(/(link_\d+)/);
			if (textSegments.length === 1) {
				return modifiedText;
			}

	
			return textSegments.map((segment, ix) => {
				const foundLink = links.find(({ key }) => segment === key);
				let ret = <Text key={ix}>{segment}</Text>;
		
				if (foundLink) {
					ret = <Text key={foundLink.key} style={{ color: 'blue' }} onPress={() => openLink(foundLink.link)}>
								{foundLink.link}
							</Text>;
				}
				return ret;
			});
		};
  
	const elementProps = {};
	if (UiGlobals.mode === UI_MODE_WEB) {
		elementProps.textOverflow = 'ellipsis';
	}
	return <Text
				ellipsizeMode="head"
				fontSize={styles.FORM_TEXT_FONTSIZE}
				minHeight='40px'
				px={3}
				py={2}
				{...props}
			>{renderTextWithLinks()}</Text>;
};

export default withComponent(TextWithLinksElement);
