/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { TagEditor } from '@onehat/ui/src/Components/Form/Field/Tag/Tag.js';
import MeterReadingsEditorWindow from '../../../Window/MeterReadingsEditorWindow.js';

function MeterReadingsTagEditor(props) {
	return <TagEditor
				reference="MeterReadingsTagEditor"
				model="MeterReadings"
				uniqueRepository={true}
				Editor={MeterReadingsEditorWindow}
				usePermissions={true}
				{...props}
			/>;
}

export default MeterReadingsTagEditor;