import * as yup from 'yup'; // https://github.com/jquense/yup#string

const Questions = {
	
	name: 'Questions',
	
	model: {

		idProperty: 'questions__id',
		displayProperty: 'questions__display_value',
		sortProperty: 'questions__sort_order',
		
		sorters: null,

		validator: yup.object({
			questions__sort_order: yup.number().integer().required(),
			questions__name: yup.string().required(),
			questions__description: yup.string().nullable(),
			questions__response_options: yup.string().nullable(),
			questions__fail_options: yup.string().nullable(),
			questions__is_value_required: yup.boolean().required(),
			questions__is_comment_required: yup.boolean().required(),
			questions__checklist_id: yup.number().integer().required(),
			questions__question_group_id: yup.number().integer().nullable()
		}),
		
		properties: [
			{ name: 'questions__display_value', mapping: 'display_value', title: 'Display Value', isVirtual: true, isFilteringDisabled: true, isEditingDisabled: true, fieldGroup: 'General', },
			{ name: 'questions__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'questions__sort_order', mapping: 'sort_order', title: 'Sort', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', defaultValue: 100000, },
			{ name: 'questions__name', mapping: 'name', title: 'Question', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'questions__description', mapping: 'description', title: 'Description', isFilteringDisabled: true, editorType: {"type":"TextArea"}, fieldGroup: 'General', },
			{ name: 'questions__response_options', mapping: 'response_options', title: 'Response Options', isFilteringDisabled: true, editorType: {"type":"TextArea"}, fieldGroup: 'General', },
			{ name: 'questions__fail_options', mapping: 'fail_options', title: 'Fail Options', isFilteringDisabled: true, editorType: {"type":"TextArea"}, fieldGroup: 'General', },
			{ name: 'questions__is_value_required', mapping: 'is_value_required', title: 'Required', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: 'General', },
			{ name: 'questions__is_comment_required', mapping: 'is_comment_required', title: 'Comment Required', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: 'General', },
			{ name: 'questions__checklist_id', mapping: 'checklist_id', title: 'Checklist', type: 'int', isFk: true, fkIdField: 'checklists__id', fkDisplayField: 'checklists__name', filterType: {"type":"ChecklistsCombo","loadAfterRender":!1}, editorType: {"type":"ChecklistsCombo","loadAfterRender":!1}, fieldGroup: 'General', },
			{ name: 'questions__question_group_id', mapping: 'question_group_id', title: 'Group', type: 'int', isFk: true, fkIdField: 'question_groups__id', fkDisplayField: 'question_groups__name', filterType: {"type":"QuestionGroupsCombo","loadAfterRender":!1}, editorType: {"type":"QuestionGroupsCombo","loadAfterRender":!1}, fieldGroup: 'General', },
			{ name: 'questions__calculated_response_options', mapping: 'calculated_response_options', title: 'Calculated Response Options', isVirtual: true, isFilteringDisabled: true, isEditingDisabled: true, fieldGroup: 'General', },
			{ name: 'questions__calculated_fail_options', mapping: 'calculated_fail_options', title: 'Calculated Fail Options', isVirtual: true, isFilteringDisabled: true, isEditingDisabled: true, fieldGroup: 'General', },
			{ name: 'questions__is_boolean', mapping: 'is_boolean', title: 'Is Boolean', type: 'bool', isVirtual: true, isFilteringDisabled: true, isEditingDisabled: true, fieldGroup: 'General', },
			{ name: 'questions__boolean_succeed_value', mapping: 'boolean_succeed_value', title: 'Boolean Succeed Value', isVirtual: true, isFilteringDisabled: true, isEditingDisabled: true, fieldGroup: 'General', },
			{ name: 'questions__boolean_fail_value', mapping: 'boolean_fail_value', title: 'Boolean Fail Value', isVirtual: true, isFilteringDisabled: true, isEditingDisabled: true, fieldGroup: 'General', },
			{ name: 'checklists__id', mapping: 'checklist.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'checklists__name', mapping: 'checklist.name', title: 'Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'checklists__checklist_type_id', mapping: 'checklist.checklist_type_id', title: 'Checklist Type', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'checklists__response_options', mapping: 'checklist.response_options', title: 'Response Options', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'checklists__fail_options', mapping: 'checklist.fail_options', title: 'Fail Options', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'checklists__groups', mapping: 'checklist.groups', title: 'Groups', type: 'tag', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'checklists__can_save_for_later', mapping: 'checklist.can_save_for_later', title: 'Can Save For Later', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'checklists__use_multiple_screens', mapping: 'checklist.use_multiple_screens', title: 'Use Multiple Screens', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'checklists__email_to', mapping: 'checklist.email_to', title: 'Email To', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'question_groups__id', mapping: 'question_group.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'question_groups__sort_order', mapping: 'question_group.sort_order', title: 'Sort', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'question_groups__name', mapping: 'question_group.name', title: 'Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'question_groups__checklist_id', mapping: 'question_group.checklist_id', title: 'Checklist', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'question_groups__is_skippable', mapping: 'question_group.is_skippable', title: 'Skippable?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, }
		],

		associations: {
			hasMany: [
				'Responses'
			],
			belongsTo: [
				'Checklists',
				'QuestionGroups'
			],

		},

		ancillaryFilters: [],

		defaultFilters: [
			// 'questions__sort_order',
			// 'questions__is_value_required',
			// 'questions__is_comment_required',
			// 'questions__checklist_id',
			// 'questions__question_group_id'
		],

	},

	entity: {
		methods: {

			getChecklist: async function() {
				const Checklists = this.getAssociatedRepository('Checklists');
				let entity = Checklists.getById(this.questions__checklist_id);
				if (!entity) {
					entity = await Checklists.getSingleEntityFromServer(this.questions__checklist_id);
				}
				return entity;
			},

			getResponses: function() {
				const Responses = this.getAssociatedRepository('Responses');
				return Responses.getBy((entity) => {
					return entity.responses__question_id === this.questions__id;
				});
			},

			getQuestionGroup: async function() {
				const QuestionGroups = this.getAssociatedRepository('QuestionGroups');
				let entity = QuestionGroups.getById(this.questions__question_group_id);
				if (!entity) {
					entity = await QuestionGroups.getSingleEntityFromServer(this.questions__question_group_id);
				}
				return entity;
			},

		},
	},


	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": true,
	    "isAutoLoad": false
	},

};

export default Questions;
