/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import QuestionGroupsSideGridEditor from './QuestionGroupsSideGridEditor.js';

export default function QuestionGroupsFilteredSideGridEditor(props) {
	return <QuestionGroupsSideGridEditor
				reference="QuestionGroupsFilteredSideGridEditor"
				useFilters={true}

				{...props}
			/>;
}