import * as yup from 'yup'; // https://github.com/jquense/yup#string

const QuestionGroups = {
	
	name: 'QuestionGroups',
	
	model: {

		idProperty: 'question_groups__id',
		displayProperty: 'question_groups__name',
		sortProperty: 'question_groups__sort_order',
		
		sorters: null,

		validator: yup.object({
			question_groups__sort_order: yup.number().integer().required(),
			question_groups__name: yup.string().required(),
			question_groups__checklist_id: yup.number().integer().required(),
			question_groups__is_skippable: yup.boolean().required()
		}),
		
		properties: [
			{ name: 'question_groups__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'question_groups__sort_order', mapping: 'sort_order', title: 'Sort', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', defaultValue: 100000, },
			{ name: 'question_groups__name', mapping: 'name', title: 'Name', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'question_groups__checklist_id', mapping: 'checklist_id', title: 'Checklist', type: 'int', isFk: true, fkIdField: 'checklists__id', fkDisplayField: 'checklists__name', filterType: {"type":"ChecklistsCombo","loadAfterRender":!1}, editorType: {"type":"ChecklistsCombo","loadAfterRender":!1}, fieldGroup: 'General', },
			{ name: 'question_groups__is_skippable', mapping: 'is_skippable', title: 'Skippable?', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: 'General', },
			{ name: 'checklists__id', mapping: 'checklist.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'checklists__name', mapping: 'checklist.name', title: 'Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'checklists__checklist_type_id', mapping: 'checklist.checklist_type_id', title: 'Checklist Type', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'checklists__response_options', mapping: 'checklist.response_options', title: 'Response Options', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'checklists__fail_options', mapping: 'checklist.fail_options', title: 'Fail Options', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'checklists__groups', mapping: 'checklist.groups', title: 'Groups', type: 'tag', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'checklists__can_save_for_later', mapping: 'checklist.can_save_for_later', title: 'Can Save For Later', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'checklists__use_multiple_screens', mapping: 'checklist.use_multiple_screens', title: 'Use Multiple Screens', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'checklists__email_to', mapping: 'checklist.email_to', title: 'Email To', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, }
		],

		associations: {
			hasMany: [
				'Questions'
			],
			belongsTo: [
				'Checklists'
			],

		},

		ancillaryFilters: [],

		defaultFilters: [
			// 'question_groups__sort_order',
			// 'question_groups__checklist_id',
			// 'question_groups__is_skippable'
		],

	},

	entity: {
		methods: {

			getChecklist: async function() {
				const Checklists = this.getAssociatedRepository('Checklists');
				let entity = Checklists.getById(this.question_groups__checklist_id);
				if (!entity) {
					entity = await Checklists.getSingleEntityFromServer(this.question_groups__checklist_id);
				}
				return entity;
			},

			getQuestions: function() {
				const Questions = this.getAssociatedRepository('Questions');
				return Questions.getBy((entity) => {
					return entity.questions__question_group_id === this.question_groups__id;
				});
			},

		},
	},


	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": false,
	    "isAutoLoad": false
	},

};

export default QuestionGroups;
