/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import {
	SELECTION_MODE_SINGLE,
} from '@onehat/ui/src/Constants/Selection.js';
import { WindowedGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import ChecklistsGroupsEditorWindowSideA from '../Window/ChecklistsGroupsEditorWindowSideA.js';
import ChecklistsGroupsGridColumnsSideA from './Columns/ChecklistsGroupsGridColumnsSideA.js';

export default function ChecklistsGroupsGridEditorSideA(props) {
	return <WindowedGridEditor
				reference="ChecklistsGroupsGridEditorSideA"
				model="ChecklistsGroups"
				usePermissions={true}
				selectionMode={SELECTION_MODE_SINGLE}
				Editor={ChecklistsGroupsEditorWindowSideA}
				columnsConfig={ChecklistsGroupsGridColumnsSideA}
				canColumnsReorder={false}
				canColumnsResize={false}
				
				
				{...props}
			/>;
}