/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import {
	EDITOR_TYPE__WINDOWED,
} from '@onehat/ui/src/Constants/Editor.js';
import ResponsesEditorWindow from '../../Window/ResponsesEditorWindow.js';
import ResponsesGridColumns from '../../Grid/Columns/ResponsesGridColumns.js';

export default function ResponsesGridEditorPanel(props) {
	return <GridPanel
				reference="ResponsesGridEditorPanel"
				model="Responses"
				usePermissions={true}
				isEditor={true}
				editorType={EDITOR_TYPE__WINDOWED}
				Editor={ResponsesEditorWindow}
				columnsConfig={ResponsesGridColumns}
				
				
				{...props}
			/>;
}