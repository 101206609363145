import { useSelector } from 'react-redux';
import {
	selectCurrentScreen,
} from '../models/Slices/AppSlice';
import ManagerScreen from '@onehat/ui/src/Components/Screens/Manager.js';
import UsersFilteredGridEditor from '../components/Grid/UsersFilteredGridEditor.js';
import UsersFilteredSideGridEditor from '../components/Grid/UsersFilteredSideGridEditor.js';

export default function UsersManager(props) {
	const
		currentScreen = useSelector(selectCurrentScreen),
		isActive = currentScreen === 'UsersManager';

	if (!isActive) {
		return null;
	}

	return <ManagerScreen
				title="Users"
				reference="UsersManager"
				fullModeComponent={<UsersFilteredGridEditor
										reference="UsersGridEditor"
										useRemoteDuplicate={true}
									/>}
				sideModeComponent={<UsersFilteredSideGridEditor
										reference="UsersGridEditor"
										useRemoteDuplicate={true}
									/>}
			/>;
}
