export default [
	    {
	        "id": "equipment__eq_status_id",
	        "header": "Status",
	        "fieldName": "eq_statuses__name",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100,
	        "editField": "equipment__eq_status_id"
	    },
	    {
	        "id": "equipment__nickname",
	        "header": "Nickname",
	        "fieldName": "equipment__nickname",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "equipment__eq_manufacturer_id",
	        "header": "Make",
	        "fieldName": "eq_manufacturers__name",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200, // MOD
	        "editField": "equipment__eq_manufacturer_id"
	    },
	    {
	        "id": "equipment__model",
	        "header": "Model",
	        "fieldName": "equipment__model",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "equipment__serial",
	        "header": "Serial",
	        "fieldName": "equipment__serial",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "equipment__eq_type_id",
	        "header": "Eq Type",
	        "fieldName": "eq_types__name",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100,
	        "editField": "equipment__eq_type_id"
	    },
	    {
	        "id": "equipment__year",
	        "header": "Year",
	        "fieldName": "equipment__year",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "equipment__eq_engine_model_id",
	        "header": "Engine Model",
	        "fieldName": "eq_engine_models__name",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100,
	        "editField": "equipment__eq_engine_model_id"
	    },
	    {
	        "id": "equipment__engine_serial",
	        "header": "Engine Serial",
	        "fieldName": "equipment__engine_serial",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "equipment__comments",
	        "header": "Additional Info",
	        "fieldName": "equipment__comments",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 300 // MOD
	    },
		// {
	    //     "id": "equipment__is_pm_enable",
	    //     "header": "PM Enable?",
	    //     "fieldName": "equipment__is_pm_enable",
	    //     "isSortable": true,
	    //     "isEditable": true,
	    //     "isReorderable": true,
	    //     "isResizable": true,
	    //     "w": 100
	    // },
	    // {
	    //     "id": "equipment__pm_schedules",
	    //     "header": "PM Schedules",
	    //     "fieldName": "equipment__pm_schedules",
	    //     "isSortable": false,
	    //     "isEditable": true,
	    //     "isReorderable": true,
	    //     "isResizable": true,
	    //     "w": 350 // MOD
	    // },
	    // {
	    //     "id": "equipment__expected_hrs_per_mo",
	    //     "header": "Expected Meter / Mo",
	    //     "fieldName": "equipment__expected_hrs_per_mo",
	    //     "isSortable": true,
	    //     "isEditable": true,
	    //     "isReorderable": true,
	    //     "isResizable": true,
	    //     "w": 150 // MOD
	    // },
	    {
	        "id": "equipment__in_service_date",
	        "header": "In Service Date",
	        "fieldName": "equipment__in_service_date",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "equipment__in_service_meter",
	        "header": "In Service Meter",
	        "fieldName": "equipment__in_service_meter",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150 // MOD
	    },
	    // {
	    //     "id": "equipment__parts",
	    //     "header": "Parts",
	    //     "fieldName": "equipment__parts",
	    //     "isSortable": false,
	    //     "isEditable": true,
	    //     "isReorderable": true,
	    //     "isResizable": true,
	    //     "w": 200
	    // },
	    {
	        "id": "equipment__inactive_date",
	        "header": "Inactive Date (calc)",
	        "fieldName": "equipment__inactive_date",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "equipment__available_hrs_per_wk",
	        "header": "Available Meter / Wk",
	        "fieldName": "equipment__available_hrs_per_wk",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150 // MOD
	    },
	    {
	        "id": "equipment__is_stale_stats",
	        "header": "Stale Stats?",
	        "fieldName": "equipment__is_stale_stats",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "equipment__current_meter",
	        "header": "Current Meter (calc)",
	        "fieldName": "equipment__current_meter",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    // {
	    //     "id": "equipment__next_pm_due_data",
	    //     "header": "Next PM Due Dates",
	    //     "fieldName": "equipment__next_pm_due_data",
	    //     "isSortable": true,
	    //     "isEditable": false,
	    //     "isReorderable": true,
	    //     "isResizable": true,
	    //     "w": 150
	    // },
	    {
	        "id": "equipment__latest_inspection_date",
	        "header": "Last Inspection Date",
	        "fieldName": "equipment__latest_inspection_date",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "equipment__latest_inspection_passed",
	        "header": "Last Inspection Passed",
	        "fieldName": "equipment__latest_inspection_passed",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    { // added this column
	        "id": "equipment__modified_at",
	        "header": "Last Modified",
	        "fieldName": "equipment__modified_at",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 230
	    },
	];