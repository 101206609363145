/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import {
	EDITOR_TYPE__WINDOWED,
} from '@onehat/ui/src/Constants/Editor.js';
import EquipmentEditorWindow from '../../Window/EquipmentEditorWindow.js';
import EquipmentGridColumns from '../../Grid/Columns/EquipmentGridColumns.js';

export default function EquipmentGridEditorPanel(props) {
	return <GridPanel
				reference="EquipmentGridEditorPanel"
				model="Equipment"
				usePermissions={true}
				isEditor={true}
				editorType={EDITOR_TYPE__WINDOWED}
				Editor={EquipmentEditorWindow}
				columnsConfig={EquipmentGridColumns}
				
				
				{...props}
			/>;
}