/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import {
	EDITOR_TYPE__WINDOWED,
} from '@onehat/ui/src/Constants/Editor.js';
import InspectionsEditorWindow from '../../Window/InspectionsEditorWindow.js';
import InspectionsGridColumns from '../../Grid/Columns/InspectionsGridColumns.js';

export default function InspectionsGridEditorPanel(props) {
	return <GridPanel
				reference="InspectionsGridEditorPanel"
				model="Inspections"
				usePermissions={true}
				isEditor={true}
				editorType={EDITOR_TYPE__WINDOWED}
				Editor={InspectionsEditorWindow}
				columnsConfig={InspectionsGridColumns}
				
				
				{...props}
			/>;
}