/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import {
	SELECTION_MODE_SINGLE,
} from '@onehat/ui/src/Constants/Selection.js';
import { WindowedGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import ChecklistsGroupsEditorWindow from '../Window/ChecklistsGroupsEditorWindow.js';
import ChecklistsGroupsGridColumns from './Columns/ChecklistsGroupsGridColumns.js';

export default function ChecklistsGroupsGridEditor(props) {
	return <WindowedGridEditor
				reference="ChecklistsGroupsGridEditor"
				model="ChecklistsGroups"
				usePermissions={true}
				selectionMode={SELECTION_MODE_SINGLE}
				Editor={ChecklistsGroupsEditorWindow}
				columnsConfig={ChecklistsGroupsGridColumns}
				
				
				{...props}
			/>;
}