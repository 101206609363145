/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import EquipmentInlineGridEditor from './EquipmentInlineGridEditor.js';

export default function EquipmentFilteredInlineGridEditor(props) {
	return <EquipmentInlineGridEditor
				reference="EquipmentFilteredInlineGridEditor"
				useFilters={true}

				{...props}
			/>;
}