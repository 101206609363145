/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import EqTypesGridPanel from './EqTypesGrid.js';

export default function EqTypesFilteredGridPanel(props) {
	return <EqTypesGridPanel
				reference="EqTypesFilteredGridPanel"
				useFilters={true}

				{...props}
			/>;
}