/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { SideGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import EqEngineModelsEditor from '../Editor/EqEngineModelsEditor.js';
import EqEngineModelsGridColumns from './Columns/EqEngineModelsGridColumns.js';

export default function EqEngineModelsSideGridEditor(props) {
	return <SideGridEditor
				reference="EqEngineModelsSideGridEditor"
				model="EqEngineModels"
				usePermissions={true}
				isCollapsible={false}
				Editor={EqEngineModelsEditor}
				columnsConfig={EqEngineModelsGridColumns}
				
				
				{...props}
			/>;
}