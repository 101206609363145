import * as yup from 'yup'; // https://github.com/jquense/yup#string
import {
	DATETIME_MOMENT_FORMAT,
} from '../../constants/Dates.js';
import moment from 'moment';

const MeterReadings = {
	
	name: 'MeterReadings',
	model: {

		idProperty: 'meter_readings__id',
		displayProperty: 'meter_readings__date',
		sortProperty: 'meter_readings__date',
		sortDir: 'DESC',
		
		sorters: null,

		validator: yup.object({
			meter_readings__date: yup.date().required(),
			meter_readings__value: yup.number().integer().required(),
			meter_readings__is_replacement: yup.boolean().nullable()
		}),
		
		properties: [
			{ name: 'meter_readings__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'meter_readings__date', mapping: 'date', title: 'Date', type: 'datetime', filterType: {"type":"DateRange","mode":"DATETIME"}, editorType: {"type":"Date","mode":"DATETIME"}, fieldGroup: 'General', defaultValue: () => moment().format(DATETIME_MOMENT_FORMAT), },
			{ name: 'meter_readings__value', mapping: 'value', title: 'Value', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'meter_readings__is_replacement', mapping: 'is_replacement', title: 'Replacement?', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: 'General', },
			{ name: 'meter_readings__meter_source_id', mapping: 'meter_source_id', title: 'Meter Source', type: 'int', isFk: true, fkIdField: 'meter_sources__id', fkDisplayField: 'meter_sources__name', filterType: {"type":"MeterSourcesCombo","loadAfterRender":!1}, isEditingDisabled: true, fieldGroup: 'General', defaultValue: 5, },
			{ name: 'meter_readings__delta', mapping: 'delta', title: 'Delta', type: 'int', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: 'General', },
			{ name: 'meter_readings__is_bad', mapping: 'is_bad', title: 'Bad?', type: 'bool', filterType: {"type":"Toggle"}, isEditingDisabled: true, fieldGroup: 'General', },
			{ name: 'meter_readings__is_pm_clock_reset', mapping: 'is_pm_clock_reset', title: 'Pm Clock Reset?', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: 'General', },
			{ name: 'meter_readings__equipment_id', mapping: 'equipment_id', title: 'Equipment', type: 'int', isFk: true, fkIdField: 'equipment__id', fkDisplayField: 'equipment__nickname', filterType: {"type":"EquipmentCombo","loadAfterRender":!1}, isEditingDisabled: true, fieldGroup: 'General', },
			{ name: 'meter_readings__work_order_id', mapping: 'work_order_id', title: 'Work Order', type: 'int', isFk: true, fkIdField: 'work_orders__id', fkDisplayField: 'work_orders__title', filterType: {"type":"WorkOrdersCombo","loadAfterRender":!1}, isEditingDisabled: true, fieldGroup: 'General', },
			{ name: 'meter_readings__pm_event_id', mapping: 'pm_event_id', title: 'Pm Event', type: 'int', isFk: true, fkIdField: 'pm_events__id', fkDisplayField: 'pm_events__date', filterType: {"type":"PmEventsCombo","loadAfterRender":!1}, isEditingDisabled: true, fieldGroup: 'General', },
			{ name: 'meter_sources__id', mapping: 'meter_source.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'meter_sources__name', mapping: 'meter_source.name', title: 'Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__id', mapping: 'equipment.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__eq_status_id', mapping: 'equipment.eq_status_id', title: 'Status', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__nickname', mapping: 'equipment.nickname', title: 'Nickname', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__eq_manufacturer_id', mapping: 'equipment.eq_manufacturer_id', title: 'Make', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__model', mapping: 'equipment.model', title: 'Model', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__serial', mapping: 'equipment.serial', title: 'Serial', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__eq_type_id', mapping: 'equipment.eq_type_id', title: 'Eq Type', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__year', mapping: 'equipment.year', title: 'Year', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__eq_engine_model_id', mapping: 'equipment.eq_engine_model_id', title: 'Engine Model', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__engine_serial', mapping: 'equipment.engine_serial', title: 'Engine Serial', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__comments', mapping: 'equipment.comments', title: 'Additional Info', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__is_pm_enable', mapping: 'equipment.is_pm_enable', title: 'PM Enable?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__pm_schedules', mapping: 'equipment.pm_schedules', title: 'PM Schedules', type: 'tag', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__expected_hrs_per_mo', mapping: 'equipment.expected_hrs_per_mo', title: 'Expected Meter / Mo', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__in_service_date', mapping: 'equipment.in_service_date', title: 'In Service Date', type: 'date', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__in_service_meter', mapping: 'equipment.in_service_meter', title: 'In Service Meter', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__inactive_date', mapping: 'equipment.inactive_date', title: 'Inactive Date (calc)', type: 'date', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__available_hrs_per_wk', mapping: 'equipment.available_hrs_per_wk', title: 'Available Meter / Wk', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__parts', mapping: 'equipment.parts', title: 'Parts', type: 'tag', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__is_stale_stats', mapping: 'equipment.is_stale_stats', title: 'Stale Stats?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__current_meter', mapping: 'equipment.current_meter', title: 'Current Meter (calc)', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__next_pm_due_data', mapping: 'equipment.next_pm_due_data', title: 'Next PM Due Dates', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__latest_inspection_date', mapping: 'equipment.latest_inspection_date', title: 'Latest Inspection Date', type: 'date', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__latest_inspection_passed', mapping: 'equipment.latest_inspection_passed', title: 'Latest Inspection Passed', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__modified_at', mapping: 'equipment.modified_at', title: 'Last Modified', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__id', mapping: 'work_order.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__wo_priority_id', mapping: 'work_order.wo_priority_id', title: 'Priority', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__title', mapping: 'work_order.title', title: 'Title', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__complaint', mapping: 'work_order.complaint', title: 'Complaint', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__cause', mapping: 'work_order.cause', title: 'Cause', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__correction', mapping: 'work_order.correction', title: 'Correction', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__reference', mapping: 'work_order.reference', title: 'Reference', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__open_date', mapping: 'work_order.open_date', title: 'Open Date', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__is_open_status', mapping: 'work_order.is_open_status', title: 'Is Open?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__close_date', mapping: 'work_order.close_date', title: 'Close Date', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__wo_class_id', mapping: 'work_order.wo_class_id', title: 'Class', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__equipment', mapping: 'work_order.equipment', title: 'Equipment', type: 'tag', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__meter_date', mapping: 'work_order.meter_date', title: 'Meter Date', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__meter_reading', mapping: 'work_order.meter_reading', title: 'Meter Reading', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__pm_schedule_id', mapping: 'work_order.pm_schedule_id', title: 'PM Schedule', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__inspection_id', mapping: 'work_order.inspection_id', title: 'Inspection', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__is_pm_template', mapping: 'work_order.is_pm_template', title: 'PM Template?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__customer_id', mapping: 'work_order.customer_id', title: 'Customer', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__customer_contact', mapping: 'work_order.customer_contact', title: 'Customer Contact', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__location_id', mapping: 'work_order.location_id', title: 'Location', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__users', mapping: 'work_order.users', title: 'Technicians', type: 'tag', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__total_parts', mapping: 'work_order.total_parts', title: 'Parts', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__total_labors', mapping: 'work_order.total_labors', title: 'Labors', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__total_others', mapping: 'work_order.total_others', title: 'Others', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__total', mapping: 'work_order.total', title: 'Grand Total', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__is_closing_email_sent', mapping: 'work_order.is_closing_email_sent', title: 'Closing Email Sent?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__reclosing_email_message', mapping: 'work_order.reclosing_email_message', title: 'Reclosing Email Message', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'pm_events__id', mapping: 'pm_event.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'pm_events__date', mapping: 'pm_event.date', title: 'Date', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'pm_events__equipment_id', mapping: 'pm_event.equipment_id', title: 'Equipment', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'pm_events__pm_schedule_id', mapping: 'pm_event.pm_schedule_id', title: 'Pm Schedule', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'pm_events__pm_event_type_id', mapping: 'pm_event.pm_event_type_id', title: 'Pm Event Type', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'pm_events__interval', mapping: 'pm_event.interval', title: 'Interval', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'pm_events__associated_date', mapping: 'pm_event.associated_date', title: 'When', type: 'date', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'pm_events__meter_reading', mapping: 'pm_event.meter_reading', title: 'Meter Reading', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'pm_events__work_order_id', mapping: 'pm_event.work_order_id', title: 'Work Order', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'pm_events__user_id', mapping: 'pm_event.user_id', title: 'Pm Technician', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'pm_events__comments', mapping: 'pm_event.comments', title: 'Comments', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, }
		],

		associations: {
			belongsTo: [
				'MeterSources',
				'Equipment',
				'WorkOrders',
				'PmEvents'
			],

		},

		ancillaryFilters: [],

		defaultFilters: [
			// 'meter_readings__date',
			// 'meter_readings__value',
			// 'meter_readings__is_replacement',
			// 'meter_readings__meter_source_id',
			// 'meter_readings__delta',
			// 'meter_readings__is_bad',
			// 'meter_readings__is_pm_clock_reset',
			// 'meter_readings__equipment_id',
			// 'meter_readings__work_order_id',
			// 'meter_readings__pm_event_id'
		],

	},

	entity: {
		methods: {

			getMeterSource: async function() {
				const MeterSources = this.getAssociatedRepository('MeterSources');
				let entity = MeterSources.getById(this.meter_readings__meter_source_id);
				if (!entity) {
					entity = await MeterSources.getSingleEntityFromServer(this.meter_readings__meter_source_id);
				}
				return entity;
			},

			getEquipment: async function() {
				const Equipment = this.getAssociatedRepository('Equipment');
				let entity = Equipment.getById(this.meter_readings__equipment_id);
				if (!entity) {
					entity = await Equipment.getSingleEntityFromServer(this.meter_readings__equipment_id);
				}
				return entity;
			},

			getWorkOrder: async function() {
				const WorkOrders = this.getAssociatedRepository('WorkOrders');
				let entity = WorkOrders.getById(this.meter_readings__work_order_id);
				if (!entity) {
					entity = await WorkOrders.getSingleEntityFromServer(this.meter_readings__work_order_id);
				}
				return entity;
			},

			getPmEvent: async function() {
				const PmEvents = this.getAssociatedRepository('PmEvents');
				let entity = PmEvents.getById(this.meter_readings__pm_event_id);
				if (!entity) {
					entity = await PmEvents.getSingleEntityFromServer(this.meter_readings__pm_event_id);
				}
				return entity;
			},

		},
	},


	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": false,
	    "isAutoLoad": false
	},

};

export default MeterReadings;
