export default [
	    {
	        "id": "permissions__view_reports",
	        "header": "View Reports",
	        "fieldName": "permissions__view_reports",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__view_dashboard",
	        "header": "View Dashboard",
	        "fieldName": "permissions__view_dashboard",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__email_inspections",
	        "header": "Email Inspections",
	        "fieldName": "permissions__email_inspections",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__do_inspections",
	        "header": "Do Inspections",
	        "fieldName": "permissions__do_inspections",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__view_attachments",
	        "header": "View Attachments",
	        "fieldName": "permissions__view_attachments",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__add_attachments",
	        "header": "Add Attachments",
	        "fieldName": "permissions__add_attachments",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__edit_attachments",
	        "header": "Edit Attachments",
	        "fieldName": "permissions__edit_attachments",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__delete_attachments",
	        "header": "Delete Attachments",
	        "fieldName": "permissions__delete_attachments",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__view_checklists",
	        "header": "View Checklists",
	        "fieldName": "permissions__view_checklists",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__add_checklists",
	        "header": "Add Checklists",
	        "fieldName": "permissions__add_checklists",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__edit_checklists",
	        "header": "Edit Checklists",
	        "fieldName": "permissions__edit_checklists",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__delete_checklists",
	        "header": "Delete Checklists",
	        "fieldName": "permissions__delete_checklists",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__view_checklists_equipment",
	        "header": "View Checklists Equipment",
	        "fieldName": "permissions__view_checklists_equipment",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__add_checklists_equipment",
	        "header": "Add Checklists Equipment",
	        "fieldName": "permissions__add_checklists_equipment",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__edit_checklists_equipment",
	        "header": "Edit Checklists Equipment",
	        "fieldName": "permissions__edit_checklists_equipment",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__delete_checklists_equipment",
	        "header": "Delete Checklists Equipment",
	        "fieldName": "permissions__delete_checklists_equipment",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__view_checklists_groups",
	        "header": "View Checklists Groups",
	        "fieldName": "permissions__view_checklists_groups",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__add_checklists_groups",
	        "header": "Add Checklists Groups",
	        "fieldName": "permissions__add_checklists_groups",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__edit_checklists_groups",
	        "header": "Edit Checklists Groups",
	        "fieldName": "permissions__edit_checklists_groups",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__delete_checklists_groups",
	        "header": "Delete Checklists Groups",
	        "fieldName": "permissions__delete_checklists_groups",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__view_checklist_types",
	        "header": "View Checklist Types",
	        "fieldName": "permissions__view_checklist_types",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__add_checklist_types",
	        "header": "Add Checklist Types",
	        "fieldName": "permissions__add_checklist_types",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__edit_checklist_types",
	        "header": "Edit Checklist Types",
	        "fieldName": "permissions__edit_checklist_types",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__delete_checklist_types",
	        "header": "Delete Checklist Types",
	        "fieldName": "permissions__delete_checklist_types",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__view_customers",
	        "header": "View Customers",
	        "fieldName": "permissions__view_customers",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__add_customers",
	        "header": "Add Customers",
	        "fieldName": "permissions__add_customers",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__edit_customers",
	        "header": "Edit Customers",
	        "fieldName": "permissions__edit_customers",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__delete_customers",
	        "header": "Delete Customers",
	        "fieldName": "permissions__delete_customers",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__view_equipment",
	        "header": "View Equipment",
	        "fieldName": "permissions__view_equipment",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__add_equipment",
	        "header": "Add Equipment",
	        "fieldName": "permissions__add_equipment",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__edit_equipment",
	        "header": "Edit Equipment",
	        "fieldName": "permissions__edit_equipment",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__delete_equipment",
	        "header": "Delete Equipment",
	        "fieldName": "permissions__delete_equipment",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__view_equipment_parts",
	        "header": "View Equipment Parts",
	        "fieldName": "permissions__view_equipment_parts",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__add_equipment_parts",
	        "header": "Add Equipment Parts",
	        "fieldName": "permissions__add_equipment_parts",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__edit_equipment_parts",
	        "header": "Edit Equipment Parts",
	        "fieldName": "permissions__edit_equipment_parts",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__delete_equipment_parts",
	        "header": "Delete Equipment Parts",
	        "fieldName": "permissions__delete_equipment_parts",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__view_equipment_pm_schedules",
	        "header": "View Equipment Pm Schedules",
	        "fieldName": "permissions__view_equipment_pm_schedules",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__add_equipment_pm_schedules",
	        "header": "Add Equipment Pm Schedules",
	        "fieldName": "permissions__add_equipment_pm_schedules",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__edit_equipment_pm_schedules",
	        "header": "Edit Equipment Pm Schedules",
	        "fieldName": "permissions__edit_equipment_pm_schedules",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__delete_equipment_pm_schedules",
	        "header": "Delete Equipment Pm Schedules",
	        "fieldName": "permissions__delete_equipment_pm_schedules",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__view_equipment_work_orders",
	        "header": "View Equipment Work Orders",
	        "fieldName": "permissions__view_equipment_work_orders",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__add_equipment_work_orders",
	        "header": "Add Equipment Work Orders",
	        "fieldName": "permissions__add_equipment_work_orders",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__edit_equipment_work_orders",
	        "header": "Edit Equipment Work Orders",
	        "fieldName": "permissions__edit_equipment_work_orders",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__delete_equipment_work_orders",
	        "header": "Delete Equipment Work Orders",
	        "fieldName": "permissions__delete_equipment_work_orders",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__view_eq_engine_models",
	        "header": "View Eq Engine Models",
	        "fieldName": "permissions__view_eq_engine_models",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__add_eq_engine_models",
	        "header": "Add Eq Engine Models",
	        "fieldName": "permissions__add_eq_engine_models",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__edit_eq_engine_models",
	        "header": "Edit Eq Engine Models",
	        "fieldName": "permissions__edit_eq_engine_models",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__delete_eq_engine_models",
	        "header": "Delete Eq Engine Models",
	        "fieldName": "permissions__delete_eq_engine_models",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__view_eq_manufacturers",
	        "header": "View Eq Manufacturers",
	        "fieldName": "permissions__view_eq_manufacturers",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__add_eq_manufacturers",
	        "header": "Add Eq Manufacturers",
	        "fieldName": "permissions__add_eq_manufacturers",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__edit_eq_manufacturers",
	        "header": "Edit Eq Manufacturers",
	        "fieldName": "permissions__edit_eq_manufacturers",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__delete_eq_manufacturers",
	        "header": "Delete Eq Manufacturers",
	        "fieldName": "permissions__delete_eq_manufacturers",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__view_eq_statuses",
	        "header": "View Eq Statuses",
	        "fieldName": "permissions__view_eq_statuses",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__add_eq_statuses",
	        "header": "Add Eq Statuses",
	        "fieldName": "permissions__add_eq_statuses",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__edit_eq_statuses",
	        "header": "Edit Eq Statuses",
	        "fieldName": "permissions__edit_eq_statuses",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__delete_eq_statuses",
	        "header": "Delete Eq Statuses",
	        "fieldName": "permissions__delete_eq_statuses",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__view_eq_types",
	        "header": "View Eq Types",
	        "fieldName": "permissions__view_eq_types",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__add_eq_types",
	        "header": "Add Eq Types",
	        "fieldName": "permissions__add_eq_types",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__edit_eq_types",
	        "header": "Edit Eq Types",
	        "fieldName": "permissions__edit_eq_types",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__delete_eq_types",
	        "header": "Delete Eq Types",
	        "fieldName": "permissions__delete_eq_types",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__view_groups",
	        "header": "View Groups",
	        "fieldName": "permissions__view_groups",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__add_groups",
	        "header": "Add Groups",
	        "fieldName": "permissions__add_groups",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__edit_groups",
	        "header": "Edit Groups",
	        "fieldName": "permissions__edit_groups",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__delete_groups",
	        "header": "Delete Groups",
	        "fieldName": "permissions__delete_groups",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__view_groups_users",
	        "header": "View Groups Users",
	        "fieldName": "permissions__view_groups_users",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__add_groups_users",
	        "header": "Add Groups Users",
	        "fieldName": "permissions__add_groups_users",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__edit_groups_users",
	        "header": "Edit Groups Users",
	        "fieldName": "permissions__edit_groups_users",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__delete_groups_users",
	        "header": "Delete Groups Users",
	        "fieldName": "permissions__delete_groups_users",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__view_inspections",
	        "header": "View Inspections",
	        "fieldName": "permissions__view_inspections",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__add_inspections",
	        "header": "Add Inspections",
	        "fieldName": "permissions__add_inspections",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__edit_inspections",
	        "header": "Edit Inspections",
	        "fieldName": "permissions__edit_inspections",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__delete_inspections",
	        "header": "Delete Inspections",
	        "fieldName": "permissions__delete_inspections",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__view_parts_parts",
	        "header": "View Interchangeable Parts",
	        "fieldName": "permissions__view_parts_parts",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__add_parts_parts",
	        "header": "Add Interchangeable Parts",
	        "fieldName": "permissions__add_parts_parts",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__edit_parts_parts",
	        "header": "Edit Interchangeable Parts",
	        "fieldName": "permissions__edit_parts_parts",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__delete_parts_parts",
	        "header": "Delete Interchangeable Parts",
	        "fieldName": "permissions__delete_parts_parts",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__view_labors",
	        "header": "View Labors",
	        "fieldName": "permissions__view_labors",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__add_labors",
	        "header": "Add Labors",
	        "fieldName": "permissions__add_labors",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__edit_labors",
	        "header": "Edit Labors",
	        "fieldName": "permissions__edit_labors",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__delete_labors",
	        "header": "Delete Labors",
	        "fieldName": "permissions__delete_labors",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__view_labor_types",
	        "header": "View Labor Types",
	        "fieldName": "permissions__view_labor_types",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__add_labor_types",
	        "header": "Add Labor Types",
	        "fieldName": "permissions__add_labor_types",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__edit_labor_types",
	        "header": "Edit Labor Types",
	        "fieldName": "permissions__edit_labor_types",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__delete_labor_types",
	        "header": "Delete Labor Types",
	        "fieldName": "permissions__delete_labor_types",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__view_locations",
	        "header": "View Locations",
	        "fieldName": "permissions__view_locations",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__add_locations",
	        "header": "Add Locations",
	        "fieldName": "permissions__add_locations",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__edit_locations",
	        "header": "Edit Locations",
	        "fieldName": "permissions__edit_locations",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__delete_locations",
	        "header": "Delete Locations",
	        "fieldName": "permissions__delete_locations",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__view_meter_readings",
	        "header": "View Meter Readings",
	        "fieldName": "permissions__view_meter_readings",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__add_meter_readings",
	        "header": "Add Meter Readings",
	        "fieldName": "permissions__add_meter_readings",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__edit_meter_readings",
	        "header": "Edit Meter Readings",
	        "fieldName": "permissions__edit_meter_readings",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__delete_meter_readings",
	        "header": "Delete Meter Readings",
	        "fieldName": "permissions__delete_meter_readings",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__view_meter_sources",
	        "header": "View Meter Sources",
	        "fieldName": "permissions__view_meter_sources",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__add_meter_sources",
	        "header": "Add Meter Sources",
	        "fieldName": "permissions__add_meter_sources",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__edit_meter_sources",
	        "header": "Edit Meter Sources",
	        "fieldName": "permissions__edit_meter_sources",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__delete_meter_sources",
	        "header": "Delete Meter Sources",
	        "fieldName": "permissions__delete_meter_sources",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__view_other_charges",
	        "header": "View Other Charges",
	        "fieldName": "permissions__view_other_charges",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__add_other_charges",
	        "header": "Add Other Charges",
	        "fieldName": "permissions__add_other_charges",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__edit_other_charges",
	        "header": "Edit Other Charges",
	        "fieldName": "permissions__edit_other_charges",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__delete_other_charges",
	        "header": "Delete Other Charges",
	        "fieldName": "permissions__delete_other_charges",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__view_parts",
	        "header": "View Parts",
	        "fieldName": "permissions__view_parts",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__add_parts",
	        "header": "Add Parts",
	        "fieldName": "permissions__add_parts",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__edit_parts",
	        "header": "Edit Parts",
	        "fieldName": "permissions__edit_parts",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__delete_parts",
	        "header": "Delete Parts",
	        "fieldName": "permissions__delete_parts",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__view_parts_part_types",
	        "header": "View Parts Part Types",
	        "fieldName": "permissions__view_parts_part_types",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__delete_parts_part_types",
	        "header": "Delete Parts Part Types",
	        "fieldName": "permissions__delete_parts_part_types",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__edit_parts_part_types",
	        "header": "Edit Parts Part Types",
	        "fieldName": "permissions__edit_parts_part_types",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__add_parts_part_types",
	        "header": "Add Parts Part Types",
	        "fieldName": "permissions__add_parts_part_types",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__view_parts_work_orders",
	        "header": "View Parts Work Orders",
	        "fieldName": "permissions__view_parts_work_orders",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__add_parts_work_orders",
	        "header": "Add Parts Work Orders",
	        "fieldName": "permissions__add_parts_work_orders",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__edit_parts_work_orders",
	        "header": "Edit Parts Work Orders",
	        "fieldName": "permissions__edit_parts_work_orders",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__delete_parts_work_orders",
	        "header": "Delete Parts Work Orders",
	        "fieldName": "permissions__delete_parts_work_orders",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__view_part_manufacturers",
	        "header": "View Part Manufacturers",
	        "fieldName": "permissions__view_part_manufacturers",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__add_part_manufacturers",
	        "header": "Add Part Manufacturers",
	        "fieldName": "permissions__add_part_manufacturers",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__edit_part_manufacturers",
	        "header": "Edit Part Manufacturers",
	        "fieldName": "permissions__edit_part_manufacturers",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__delete_part_manufacturers",
	        "header": "Delete Part Manufacturers",
	        "fieldName": "permissions__delete_part_manufacturers",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__view_part_sources",
	        "header": "View Part Sources",
	        "fieldName": "permissions__view_part_sources",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__add_part_sources",
	        "header": "Add Part Sources",
	        "fieldName": "permissions__add_part_sources",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__edit_part_sources",
	        "header": "Edit Part Sources",
	        "fieldName": "permissions__edit_part_sources",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__delete_part_sources",
	        "header": "Delete Part Sources",
	        "fieldName": "permissions__delete_part_sources",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__view_part_types",
	        "header": "View Part Types",
	        "fieldName": "permissions__view_part_types",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__add_part_types",
	        "header": "Add Part Types",
	        "fieldName": "permissions__add_part_types",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__edit_part_types",
	        "header": "Edit Part Types",
	        "fieldName": "permissions__edit_part_types",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__delete_part_types",
	        "header": "Delete Part Types",
	        "fieldName": "permissions__delete_part_types",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__view_permissions",
	        "header": "View Permissions",
	        "fieldName": "permissions__view_permissions",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__add_permissions",
	        "header": "Add Permissions",
	        "fieldName": "permissions__add_permissions",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__edit_permissions",
	        "header": "Edit Permissions",
	        "fieldName": "permissions__edit_permissions",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__delete_permissions",
	        "header": "Delete Permissions",
	        "fieldName": "permissions__delete_permissions",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__view_pm_events",
	        "header": "View Pm Events",
	        "fieldName": "permissions__view_pm_events",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__add_pm_events",
	        "header": "Add Pm Events",
	        "fieldName": "permissions__add_pm_events",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__edit_pm_events",
	        "header": "Edit Pm Events",
	        "fieldName": "permissions__edit_pm_events",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__delete_pm_events",
	        "header": "Delete Pm Events",
	        "fieldName": "permissions__delete_pm_events",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__view_pm_event_types",
	        "header": "View Pm Event Types",
	        "fieldName": "permissions__view_pm_event_types",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__add_pm_event_types",
	        "header": "Add Pm Event Types",
	        "fieldName": "permissions__add_pm_event_types",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__edit_pm_event_types",
	        "header": "Edit Pm Event Types",
	        "fieldName": "permissions__edit_pm_event_types",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__delete_pm_event_types",
	        "header": "Delete Pm Event Types",
	        "fieldName": "permissions__delete_pm_event_types",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__view_pm_schedules",
	        "header": "View Pm Schedules",
	        "fieldName": "permissions__view_pm_schedules",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__add_pm_schedules",
	        "header": "Add Pm Schedules",
	        "fieldName": "permissions__add_pm_schedules",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__edit_pm_schedules",
	        "header": "Edit Pm Schedules",
	        "fieldName": "permissions__edit_pm_schedules",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__delete_pm_schedules",
	        "header": "Delete Pm Schedules",
	        "fieldName": "permissions__delete_pm_schedules",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__view_pm_schedule_modes",
	        "header": "View Pm Schedule Modes",
	        "fieldName": "permissions__view_pm_schedule_modes",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__add_pm_schedule_modes",
	        "header": "Add Pm Schedule Modes",
	        "fieldName": "permissions__add_pm_schedule_modes",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__edit_pm_schedule_modes",
	        "header": "Edit Pm Schedule Modes",
	        "fieldName": "permissions__edit_pm_schedule_modes",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__delete_pm_schedule_modes",
	        "header": "Delete Pm Schedule Modes",
	        "fieldName": "permissions__delete_pm_schedule_modes",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__view_pm_statuses",
	        "header": "View Pm Statuses",
	        "fieldName": "permissions__view_pm_statuses",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__add_pm_statuses",
	        "header": "Add Pm Statuses",
	        "fieldName": "permissions__add_pm_statuses",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__edit_pm_statuses",
	        "header": "Edit Pm Statuses",
	        "fieldName": "permissions__edit_pm_statuses",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__delete_pm_statuses",
	        "header": "Delete Pm Statuses",
	        "fieldName": "permissions__delete_pm_statuses",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__view_pm_technicians",
	        "header": "View Pm Technicians",
	        "fieldName": "permissions__view_pm_technicians",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__add_pm_technicians",
	        "header": "Add Pm Technicians",
	        "fieldName": "permissions__add_pm_technicians",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__edit_pm_technicians",
	        "header": "Edit Pm Technicians",
	        "fieldName": "permissions__edit_pm_technicians",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__delete_pm_technicians",
	        "header": "Delete Pm Technicians",
	        "fieldName": "permissions__delete_pm_technicians",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__view_questions",
	        "header": "View Questions",
	        "fieldName": "permissions__view_questions",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__add_questions",
	        "header": "Add Questions",
	        "fieldName": "permissions__add_questions",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__edit_questions",
	        "header": "Edit Questions",
	        "fieldName": "permissions__edit_questions",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__delete_questions",
	        "header": "Delete Questions",
	        "fieldName": "permissions__delete_questions",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__view_question_groups",
	        "header": "View Question Groups",
	        "fieldName": "permissions__view_question_groups",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__add_question_groups",
	        "header": "Add Question Groups",
	        "fieldName": "permissions__add_question_groups",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__edit_question_groups",
	        "header": "Edit Question Groups",
	        "fieldName": "permissions__edit_question_groups",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__delete_question_groups",
	        "header": "Delete Question Groups",
	        "fieldName": "permissions__delete_question_groups",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__view_responses",
	        "header": "View Responses",
	        "fieldName": "permissions__view_responses",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__add_responses",
	        "header": "Add Responses",
	        "fieldName": "permissions__add_responses",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__edit_responses",
	        "header": "Edit Responses",
	        "fieldName": "permissions__edit_responses",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__delete_responses",
	        "header": "Delete Responses",
	        "fieldName": "permissions__delete_responses",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__view_users",
	        "header": "View Users",
	        "fieldName": "permissions__view_users",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__add_users",
	        "header": "Add Users",
	        "fieldName": "permissions__add_users",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__edit_users",
	        "header": "Edit Users",
	        "fieldName": "permissions__edit_users",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__delete_users",
	        "header": "Delete Users",
	        "fieldName": "permissions__delete_users",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__view_work_orders",
	        "header": "View Work Orders",
	        "fieldName": "permissions__view_work_orders",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__add_work_orders",
	        "header": "Add Work Orders",
	        "fieldName": "permissions__add_work_orders",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__edit_work_orders",
	        "header": "Edit Work Orders",
	        "fieldName": "permissions__edit_work_orders",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__delete_work_orders",
	        "header": "Delete Work Orders",
	        "fieldName": "permissions__delete_work_orders",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__view_wo_classes",
	        "header": "View Wo Classes",
	        "fieldName": "permissions__view_wo_classes",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__add_wo_classes",
	        "header": "Add Wo Classes",
	        "fieldName": "permissions__add_wo_classes",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__edit_wo_classes",
	        "header": "Edit Wo Classes",
	        "fieldName": "permissions__edit_wo_classes",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "permissions__delete_wo_classes",
	        "header": "Delete Wo Classes",
	        "fieldName": "permissions__delete_wo_classes",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    }
	];