/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import ChecklistTypesGridColumns from '../../Grid/Columns/ChecklistTypesGridColumns.js';

export default function ChecklistTypesGridPanel(props) {
	return <GridPanel
				reference="ChecklistTypesGridPanel"
				model="ChecklistTypes"
				usePermissions={true}
				columnsConfig={ChecklistTypesGridColumns}

				{...props}
			/>;
}