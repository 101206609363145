/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import MeterReadingsGridColumns from '../../Grid/Columns/MeterReadingsGridColumns.js';

export default function MeterReadingsGridPanel(props) {
	return <GridPanel
				reference="MeterReadingsGridPanel"
				model="MeterReadings"
				usePermissions={true}
				columnsConfig={MeterReadingsGridColumns}

				{...props}
			/>;
}