/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import ResponsesSideGridEditorPanel from './ResponsesSideGridEditor.js';


export default function ResponsesFilteredSideGridEditorPanel(props) {
	return <ResponsesSideGridEditorPanel
				reference="ResponsesFilteredSideGridEditorPanel"
				useFilters={true}
				
				{...props}
			/>;
}