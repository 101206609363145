import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
	setDebugMessage,
	setAlertMessage,
	setInfoMessage,
	setIsWaitModalShown,
} from './DebugSlice.js';
import { logout, replace, } from '../../RootNavigation.js';
import oneHatData from '@onehat/data';
import getTokenHeaders from '@onehat/ui/src/Functions/getTokenHeaders.js';
import setSecure from '@onehat/ui/src/Functions/setSecure';
import getSecure from '@onehat/ui/src/Functions/getSecure';
import deleteSecure from '@onehat/ui/src/Functions/deleteSecure';
import AppGlobals from '../../AppGlobals.js';
import moment from 'moment';
import _ from 'lodash';

const USER_CREDS = 'USER_CREDS-';

// async thunks
export const loginThunk = createAsyncThunk(
	'auth/login',
	async (args, { dispatch }) => {

		try {
			const {
					loginId,
					password,
					isValid,
				} = args;
			
			if (!isValid) {
				dispatch(setAlertMessage('Please completely fill out the form first.'));
				return;
			}

			dispatch(setIsWaitModalShown(true));

			let loginResult,
				savedCreds,
				isError = false;

			setSecure('loginId', loginId);
			const
				Users = oneHatData.getRepository('Users'),
				secureCredsKey = USER_CREDS + loginId;

			// Login user
			const params = {};
			params[AppGlobals.loginIdField] = loginId;
			params.password = password;
			loginResult = await Users.login(params)
				.catch(function (error) {
					dispatch(setAlertMessage(error.message));
					isError = true;
				});
			if (isError || !loginResult) {
				dispatch(setIsWaitModalShown(false));
				return;
			}

			// Login successful (otherwise would have thrown an Error)

			const {
					user,
					groups,
					permissions,
				} = loginResult;

			// Save user creds to secure store
			const creds = JSON.stringify({
				loginId,
				password,
				user,
				groups,
				permissions,
			});
			savedCreds = await setSecure(secureCredsKey, creds);

			const userEntity = await Users.createStandaloneEntity(user);

			await dispatch(setUserThunk(userEntity));
			await dispatch(setGroupsThunk(groups));
			await dispatch(setPermissionsThunk(permissions));
			
			dispatch(setAlertMessage(null));

			replace('AppNavigator'); // navigation

		} catch(error) {
			dispatch(setAlertMessage(error?.message));
		}
		dispatch(setIsWaitModalShown(false));

	}
);
export const setUserThunk = createAsyncThunk(
	'auth/setUser',
	async (user, { dispatch }) => {
		dispatch(setUser(user?.getOriginalData()));
		
		let headers;
		if (user) {
			await setSecure('user', user);
			headers = getTokenHeaders();
		} else {
			await deleteSecure('user');
			headers = getTokenHeaders(true); // true for clearAll
		}
		if (!_.isEmpty(headers)) {
			oneHatData.setOptionsOnAllRepositories({ headers }); // already created
			oneHatData.setRepositoryGlobals({ headers }); // not yet created
		}
	}
);
export const setGroupsThunk = createAsyncThunk(
	'auth/setGroups',
	async (groups, { dispatch }) => {

		dispatch(setGroups(groups));

		if (!_.isEmpty(groups)) {
			await setSecure('groups', groups);
		} else {
			await deleteSecure('groups');
		}
		
	}
);
export const setPermissionsThunk = createAsyncThunk(
	'auth/setPermissions',
	async (permissions, { dispatch }) => {

		if (_.isPlainObject(permissions)) {
			// login returns permissions as an object from the server,
			// so we need to convert it to an array
			permissions = _.map(permissions, (value, key) => {
				if (!parseInt(value)) {
					return null;
				}
				return key;
			});
		}
		dispatch(setPermissions(permissions));

		if (!_.isEmpty(permissions)) {
			await setSecure('permissions', permissions);
		} else {
			await deleteSecure('permissions');
		}
		
	}
);
export const logoutThunk = createAsyncThunk(
	'auth/logout',
	async (arg, { dispatch }) => {

		dispatch(setDebugMessage(null));
		dispatch(setAlertMessage(null));
		dispatch(setInfoMessage(null));
		await dispatch(setUserThunk(null));

		const Users = oneHatData.getRepository('Users');
		await Users.logout();
		
		// router.replace('/');
		logout();
	}
);


// slice
export const appSlice = createSlice({
	name: 'app',
	initialState: {
		appState: 'active',
		user: null,
		groups: null,
		permissions: null,
		currentScreen: null,
		currentTab: 'App',
	},
	reducers: {
		setAppState: (state, action) => {
			state.appState = action.payload
		},
		setUser: (state, action) => {
			state.user = action.payload
		},
		setGroups: (state, action) => {
			state.groups = action.payload
		},
		setPermissions: (state, action) => {
			state.permissions = action.payload
		},
		setCurrentScreen: (state, action) => {
			state.currentScreen = action.payload
		},
		setCurrentTab: (state, action) => {
			state.currentTab = action.payload;
		},
	}
});


// action definitions
export const {
	setAppState,
	setUser,
	setGroups,
	setPermissions,
	setCurrentScreen,
	setCurrentTab,
} = appSlice.actions;


// selectors
export const selectAppState = state => state.app.appState;
export const selectUser = state => state.app.user;
export const selectGroups = state => state.app.groups;
export const selectPermissions = state => state.app.permissions;
export const selectCurrentScreen = state => state.app.currentScreen;
export const selectCurrentTab = state => state.app.currentTab;

export default appSlice.reducer;